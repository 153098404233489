import React, { useEffect } from 'react';
import ListIcon from '@material-ui/icons/List';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { crudTemplateActions } from '../../actions/crudTemplate.actions';
import { TemplateNewTitle } from './TemplateNewTitle';

export function TemplateTextArea({ fields }) {
  const dispatch = useDispatch();
  const { body } = useSelector(state => state.crudTemplate);

  useEffect(() => {
    getDefaultValue();
  },[])

  function getDefaultValue() {
    fields.map(({ rows }) => {
      rows.forEach(({ field }) => {
        dispatch(crudTemplateActions.setItem({ name: field, value: body[field] || '' }));
      });
    });
  };

  function handleSetItems({ name, value }) {
    dispatch(crudTemplateActions.setItem({ name, value }));
  };

  return (
    fields.map(({ title, rows }, index) => (
      <>
        <TemplateNewTitle
          icon={<ListIcon color="primary" />}
          title={title}
          key={index}
        />
        {
          rows.map(({ label, field, isRequired=false, rows }, index) => <TextField
            name={field}
            label={label}
            variant="filled"
            key={index}
            multiline
            fullWidth
            required={isRequired}
            defaultValue={body[field]}
            rows={rows && rows}
            style={{ marginBottom: 30 }}
            onBlur={({ target: { name, value } }) => handleSetItems({ name, value })}
          />)
        }
      </>
    ))
  );
}
import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { BackNav, Contents } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function ContentsPage() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title="conteúdos" pathname="/" />
      <Contents />
    </Container>
  );
}

export { ContentsPage };

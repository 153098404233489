import React from 'react';

import { 
  Grid,
  Container,
  makeStyles,
} from '@material-ui/core';

import { BackNavTemplate } from '../../components/Shared';
import { SidebarTemplateCrud } from '../../components/Sidebars';
import { TemplateNewItem } from '../../components/TemplateCrud';
import { QuestionEditPage } from '../QuestionEditPage';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  }
}));

export function TemplateCrudNewPage({ data, title }) {
  const classes = useStyles();
  const { type } = useSelector(state => state.crudTemplate);
  
  function children(type) {
    switch(type) {
      case 'faq':
        return (<QuestionEditPage />);

      default:
        return (<></>);
    };
  };

  return (
    <>
      <BackNavTemplate title={title}/>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <SidebarTemplateCrud data={data} />
          </Grid>
          <Grid item xs={9}>
            <TemplateNewItem children={children(type)} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
import React, { useState } from 'react';
import {
  DialogActions,
  Button,
  makeStyles,
  TextField,
  Hidden,
  withStyles,
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import config from '../../config';
import { dialogCss } from './dialogCss';
import { MentionsUsers } from '../Shared';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogAttachment } from './DialogAttachment';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateDialogsActions, ideaCreateActions, multiAreaCascadeActions } from '../../actions';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  dialogActions: {
    justifyContent: 'space-between',
  },
  dialogContent: {
    borderBottom: 'none',
  },
  message: {
    height: 19,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
  },
  characters: {
    position: 'absolute',
    right: 10,
  },
}));

const DialogContent = withStyles({
  root: {
    overflowY: 'visible',
  },
})(MuiDialogContent);
function DialogTitleDescription() {
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const idea = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription, fromHistory } = useSelector(
    (state) => state.ideaCreateDialogs
  );
  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
  });
  const [width] = useWindowSize();

  const [value, setValue] = useState('');

  function handleCharacters(e) {
    setValue(e.target.value)
  }

  function createDraft(data) {
    if (!data.name && !data.descri) {
      return;
    }
    const values = getValues();
    dispatch(ideaCreateActions.setTitleDescription(values));
    dispatch(ideaCreateActions.createDraft(idea, t('ideia_salva_em_rascunhos')));
  }

  function onSubmit(data) {
    if (!data.name && !data.descri) {
      return;
    }

    if (fromHistory[fromHistory.length - 1] === 'sendIdea') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }
    dispatch(ideaCreateActions.setTitleDescription(data));
    config.SKIP_BUSINESS_UNIT ? dispatch(ideaCreateDialogsActions.openDialogChallenge('DIALOG_TITLE_DESCRIPTION')) 
    : dispatch(ideaCreateDialogsActions.openDialogMultiArea('DIALOG_TITLE_DESCRIPTION'));
  }

  function setValues() {
    const values = getValues();
    const data = {
      name: values.name,
      description: values.description,
    };
    dispatch(ideaCreateActions.setTitleDescription(data));
  }

  function handleMentions(mentioned) {
    dispatch(ideaCreateActions.setContributors(mentioned));
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }
  function handleClose() {
    setValue('')
    close()
  }
  return (
    <DialogCreateIdea open={dialogTitleDescription}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('qual_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <div>
          <TextField
            onChange={handleCharacters}
            error={!!errors.name}
            label={t('titulo').toUpperCase()}
            style={{ margin: 8 }}
            placeholder={t('de_um_titulo_para_sua_ideia')}
            fullWidth
            margin="normal"
            InputProps={{ disableUnderline: true }}
            onBlur={setValues}
            name="name"
            inputProps={{ maxLength: 70 }}
            inputRef={register({
              required: t('o_titulo_da_ideia_e_obrigatorio'),
              maxLength: 70,
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={classes.message}>
                {(errors.name && errors.name.message)}
                {value ? (
                  <div className={classes.characters}>
                    <span>{value.length}/70 caracteres</span>
                  </div>
                ) : null}
              </div>
            }
            defaultValue={idea.name}
          />
          <TextField
            error={!!errors.description}
            label={t('descricao').toUpperCase()}
            style={{ margin: 8 }}
            placeholder={t('em_qual_ideia_voce_esta_pensando_descreva') + '...'}
            fullWidth
            margin="normal"
            InputProps={{ disableUnderline: true }}
            onBlur={setValues}
            multiline
            rows={8}
            name="description"
            inputRef={register({
              required: t('a_descricao_da_ideia_e_obrigatoria'),
              style:({fontSize: '0.75rem',})
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={classes.message}>
                {errors.description && errors.description.message}
              </div>
            }
            defaultValue={idea.description}
          />
          <MentionsUsers onAddMentions={handleMentions} />
        </div>
      </DialogContent>
      <DialogActions
        className={clsx(classes.dialogActions, dialogClasses.containerAction)}
      >
        <DialogAttachment from="DIALOG_TITLE_DESCRIPTION" />
        <div>
          <Hidden smDown>
            <Button
              className={dialogClasses.button}
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              onClick={handleSubmit(createDraft)}
            >
              {t('salvar_rascunho')}
            </Button>
          </Hidden>
          <Button
            className={dialogClasses.button}
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<ArrowForwardIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {t('avancar')}
          </Button>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogTitleDescription };

import React, { useEffect, useState } from 'react'
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import EditIcon from '@material-ui/icons/Edit';
import {
  BackNavAdmin,
  PortifolioDetailsMenu,
  PortifolioDetailsTask,
  GeneralData
} from '../../components';
import { useDispatch, useSelector } from 'react-redux'
import { portfolioActions, curationActions } from '../../actions';
import { portfolioDetailsActions } from "./../../actions/portfolio/portfolio-details.actions"
import { portfolioDialogProjectActions  } from "./../../actions/portfolio/portfolio-dialog-project.actions"
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next'
import { DialogCreateProject } from '../../components/Portfolio';

export const PortfolioDetailsPage = () => {
  const dispatch = useDispatch();
  const [openEditDialogProject, setOpenEditDialogProject] = useState(false);
  const [tabChosed, setTabChosed] = useState(0);
  const { id } = useParams();
  const { ideas, filters } = useSelector((state) => state.curation);
  const { loading, project } = useSelector(state => state.portfolioDetails);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(portfolioDetailsActions.getProject(id));
    dispatch(portfolioDetailsActions.getTeam(id));
    dispatch(portfolioDetailsActions.getOneFinancialPlanning(id));
    dispatch(portfolioDetailsActions.getAllResponsibles())
    dispatch(curationActions.postPortfolioLinkedIdea({ ...filters, page: -1, statusCode:['EXECUTION_QUEUE'] }));
  }, [dispatch, id, filters]);

  useEffect(() => {
    if (ideas) {
      dispatch(portfolioDialogProjectActions.getLinkedIdeas(ideas.map(item => ({
        id: item._id,
        label: item.name
      }))))
    }
  }, [ideas, dispatch]);

  function handleOpenDialogProject() {
    setOpenEditDialogProject(!openEditDialogProject)
  }

  return (
    <>
      <Grid item xs={12}>
        <BackNavAdmin title={loading ? t('carregando') : project?.name}>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleOpenDialogProject}
              disabled={loading}
            >
              {t('editar_projeto')}
            </Button>
            { openEditDialogProject && <DialogCreateProject open={openEditDialogProject} onClose={handleOpenDialogProject} title={t('editar_projeto')} project={project}/> } 
          </Box>
        </BackNavAdmin>
      </Grid>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} >
            <PortifolioDetailsMenu tabChosed={tabChosed} handleChangeTab={setTabChosed} />
          </Grid>
          {tabChosed === 0 ? (
            <GeneralData />
          ) : (
            <PortifolioDetailsTask />
          )}
        </Grid>
      </Container >
    </>)
};

import React, { useEffect } from 'react'
import { makeStyles, Container, Grid, Button, Box, Typography, CircularProgress } from '@material-ui/core';
import {
  BackNavAdmin,
  ListClassificationPersonalized
} from '../../components';


import config from '../../config';
import { useDispatch, useSelector } from 'react-redux';

import AddCircle from '@material-ui/icons/AddCircle';
import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import EmptyStateIdeaClassification from '../../assets/imgs/imagine-illustration_empty-state-idea-classification.svg';
import { personalizedClassificationActions } from '../../actions';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filters: {
    width: '100%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    float: "right"
  },
  card: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32
  },
  title: {
    font: ' normal normal 600 14px/22px Poppins'
  },
  normalText: {
    font: 'normal normal normal 14px/22px Poppins;'
  },
  emptyBox: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center"
  },
  image: {
    marginBottom: '16px'
  }
}));

export const PersonalizedClassificationListPage = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { t } = useTranslation();
  const { personalizedClassification: {
    personalizedClassification,
    loading
  } } = useSelector(state => state);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch]);

  function handleCondition(a, b) {
    return personalizedClassification[0].name !== '' && personalizedClassification[0].description !== '' ? b : a;
  };

  function handleClick() {
    history.push('/preferences/personalized-classification/edit');
  };

  return (
    <>
      <BackNavAdmin title={t('classificacao_das_ideias')}>
        <Button
          className={classes.button}
          variant={handleCondition('contained', 'outlined')}
          color="primary"
          onClick={handleClick}
          disabled={loading}
          startIcon={handleCondition(<AddCircle />, <EditIcon />)}
        >
          {handleCondition(t('nova_classificacao'), t('editar_classificacao'))}
        </Button>
      </BackNavAdmin>
      <form id="personalizedClassicationForm">
        <Container maxWidth="lg" className={classes.root}>
          {personalizedClassification[0].name !== '' && personalizedClassification[0].description !== '' ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box className={classes.card}>
                  <ListClassificationPersonalized />
                </Box>
              </Grid>
            </Grid>
          )
            :
            (
              <Box
                className={classes.emptyBox}>
                {loading ? (<CircularProgress />) : (<><Box
                  className={classes.image}
                  style={{ height: 96 }}>
                  <img
                    style={{ height: 96 }}
                    src={EmptyStateIdeaClassification}
                    alt="photoSvg"
                  />
                </Box>
                  <Box
                    maxWidth='500px'
                  >
                    <Typography
                      align='center'
                      className={classes.title}>
                      {t('ainda_nao_ha_classificacao_personalizada_de_ideias')}

                    </Typography>
                    <Typography
                      align='center'
                      className={classes.normalText}>
                      {t('que_tal_adicionar_uma_nova_classificacao_para_incrementar_processo_de_entendimento_das_ideias')}
                    </Typography>
                  </Box></>)}
              </Box>
            )
          }
        </Container>
      </form>
    </>
  )
}

import { handleResponse, requestHeaders } from '../helpers';

import axios from 'axios';
import config from '../config';

let filters = {};
export const dashboardService = {
  getInicators,
  getTopAreas,
  getTopAreasGenerateIdeas,
  getTopUsers,
  getTopManagers,
  getIdeasStatus,
  getIdeasMonth,
  getIdeasList,
  getDashboardReportsCsv,
  setFilters,
  getFilters
};

function getInicators(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/indicators`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopAreas(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-areas`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopAreasGenerateIdeas(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-origin-areas`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopUsers(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-users`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopManagers(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-managers`, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasStatus(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-status`, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasMonth(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-month`, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasList(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-list`, filter, requestHeaders())
    .then(handleResponse);
}

function getDashboardReportsCsv(data) {
  return axios.post(`${config.URL_BASE}/dashboard/${data.reportType}/csv`, data, requestHeaders());
}
function setFilters(f) {
  filters = f;
}
function getFilters() {
  return filters;
}
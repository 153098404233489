import { portfolioConstants } from '../constants';
import { formatReal } from "./../helpers"



const initialStateTeams = {
  name: '',
  value: ''
}



const initialState = {
  homePage: {
    loading: false,
    projects: {
      executionQueue: [],
      executing: [],
      conclused: []
    }
  },
  detailsPage: {
    loading: false,
    project: {},
    teams: {
      loading: false,
      details: []
    },
    
  },
  
  status: [],

  loading: false,
 
};

export function portfolio(state = initialState, action) {
  switch (action.type) {

    case portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_FAILURE:
      return state;



   

    case portfolioConstants.PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_SUCCESS:
      return state;

    case portfolioConstants.PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_FAILURE:
      return state;
    
   
   

    case portfolioConstants.PORTFOLIO_GET_ALL_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case portfolioConstants.PORTFOLIO_GET_ALL_STATUS_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.status,
          status: action.payload
        },
        loading: false
      }

    case portfolioConstants.PORTFOLIO_GET_ALL_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      }

    
    case portfolioConstants.CLEAR_PORTFOLIO_ERRORS: {
      return {
        ...state,
        errors: false
      }
    }

    case portfolioConstants.CLEAR_ALL_PORTFOLIO: {
      return initialState;
    }

    

    

   
    

    



    default:
      return state;
  }
}

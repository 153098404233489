import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { styled } from '@material-ui/core/styles';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';

import { portfolioHomeActions } from '../../actions/portfolio/portfolio-home.actions';
import DataCard from '../../components/Portfolio/DataCard/DataCard';
import EmptyCard from '../../components/Portfolio/EmptyCard/EmptyCard';
import { SkeletonPortfolioHome } from '../../components/Portfolio';

const TitleColumn = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
}));

const GridContainer = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
}));

const Title = styled(Typography)(() => ({
    wordBreak: 'break-all',
    fontSize: 14,
    fontWeight: 600
}));

function PortfolioHome() {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loading, first_status_items, second_status_items, third_status_items } = useSelector(state => state.portfolioHome);
  const { portfolioStatus } = useSelector(state => state.domains)

  function objectCompare( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

  function getArrOfObjects(order) {
    if (order === 1) return first_status_items
    if (order === 2) return second_status_items
    if (order === 3) return third_status_items
  }

  useEffect(() => {
    if (portfolioStatus) {
      dispatch(portfolioHomeActions.getAllProjects(portfolioStatus))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioStatus]);

  function Column({ type, data }) {
    return (
      <Grid item container spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <TitleColumn>
            <Title>{type}</Title>
            <span style={{ color: theme.palette.neutrals.low.light }}>{data.length}</span>
          </TitleColumn>
        </Grid>
        {!!data.length ? data.map(item => (<DataCard key={item._id} project={item} />)) : (<EmptyCard />)}
      </Grid>
    );
  };

  return (
    loading && portfolioStatus ? (
      <SkeletonPortfolioHome /> 
    ) : (
      <GridContainer container spacing={3}>
        { portfolioStatus?.sort(objectCompare).map(item => (
          <Column type={item.name} data={getArrOfObjects(item.order)} key={item._id} />
        ))}
      </GridContainer>
    )
  );
}

export { PortfolioHome }
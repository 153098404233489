import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ImagineGrid } from '../../components';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    width: '70%',
    height: theme.spacing(4),
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  pieChart: {
    width: theme.spacing(26),
    height: theme.spacing(26),
    marginTop: theme.spacing(3),
    backgroundColor: "#C0C0C0",
  },
}));

export function SkeletonPieChart() {
  const classes = useStyles();

  return(
    <ImagineGrid container className={classes.skeletonAnimaton}>
      <ImagineGrid container spacing={1} cols={1} className={classes.wrapper}>
        <Skeleton variant="text" className={classes.title}/>
        <ImagineGrid item className={classes.flex}>
          <Skeleton variant="circle" className={classes.pieChart}/>
        </ImagineGrid>
      </ImagineGrid>
    </ImagineGrid>
  );
} 
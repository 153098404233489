import React from 'react';
import { 
  makeStyles, 
  Container,
  Box,
} from '@material-ui/core';
import { FooterTerms } from '../../components/Shared';
import { FaqTitle } from '../../components';  

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#585858',
    background: '#FAFAFA',
    margin:0,
    padding:0
  },
  containerContent: {
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
  },
  creationDate: {
    fontSize: 18,
    fontWeight: '600',
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    paddingBottom: 10,
  },
  info: {
    fontSize: 13,
    paddingBottom: 30,
    lineHeight: 1.8
  },
}));

function InvitePage() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
 
          <iframe src="https://imagine-files.imagineinovacao.com.br/omni/convite/index.html" style={{ boxSizing: "border-box",width:"calc(100vw)",height:"calc(100vh)",border:0, frameBorder:"0", scrolling:"no", overflow: "hidden" }} />

    </div>
  );
}

export { InvitePage };

function getFiles(idea) {
  // remove files of draft
  idea.images = idea.images.filter((image) => !image.url);
  idea.files = idea.files.filter((file) => !file.url);
  if (idea.video?.url) {
    delete idea.video;
  }

  // files fluxo normal
  let files = [...idea.images, ...idea.files];
  if (idea.video?.file) {
    files = [...files, idea.video];
  }

  return files;
}

export { getFiles };

export const dashboardConstants = {
  GET_DASHBOARD_CSV_REQUEST: "GET_DASHBOARD_CSV_REQUEST",
  GET_DASHBOARD_CSV_SUCCESS: "GET_DASHBOARD_CSV_SUCCESS",
  GET_DASHBOARD_CSV_FAILURE: "GET_DASHBOARD_CSV_FAILURE",

  CLEAR_DASHBOARD_CSV_DOWNLOAD: "CLEAR_DASHBOARD_CSV_DOWNLOAD",

  SUCCESS_GET_USERS: "SUCCESS_GET_USERS",
  REQUEST_GET_USERS: "REQUEST_GET_USERS",
  FAILURE_GET_USERS: "FAILURE_GET_USERS",

  SET_SORT_USERS: "SET_SORT_USERS"
}
import { Skeleton } from "@material-ui/lab"
import React from 'react'
import { Grid } from "@material-ui/core"


export const SkeletonUserEdit = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="circle" width={"56px"} height={"56px"} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px", width: "50%" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px", width: "50%" }} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px" }} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px" }} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" height={"70px"} style={{ borderRadius: "4px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={"140px"} style={{ borderRadius: "4px" }} />
      </Grid>
    </Grid>
  )
}

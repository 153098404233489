import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';
import { portfolioHomeActions } from "./portfolio-home.actions"
import { history } from './../../helpers';

export const portfolioDialogProjectActions = {
  postCreateProject,
  putUpdateProject,
  getLinkedIdeas,
  loadProject,
  changeField,
  clearCreateProject,
  deleteProject,
  clearFlags,
}

function postCreateProject(data, successMessage, portfolioStatus) {
  return (dispatch) => {
    dispatch({ type: portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_REQUEST });
    portfolioService.postCreateProject(data)
      .then((data) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_SUCCESS,
          payload: data.data
        });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioHomeActions.getAllProjects(portfolioStatus));
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_FAILURE,
        })
      })
  }
}


function putUpdateProject(data, successMessage, portfolioStatus) {
  return (dispatch) => {
    dispatch({ type: portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_REQUEST });
    portfolioService.updateProject(data)
      .then((data) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_SUCCESS,
          payload: data
        });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioHomeActions.getAllProjects(portfolioStatus));
      },
        (error) => {
          dispatch({
            type: portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_FAILURE,
            error
          });
        });
  }
}

function getLinkedIdeas(data) {
  return (dispatch) => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_LOAD_LINKED_IDEAS,
      payload: data
    })
  }
}

function loadProject(project) {
  return (dispatch) => {
    dispatch({
      type: portfolioConstants.LOAD_CREATE_PROJECT_DIALOG,
      payload: project
    });
  }
}

function changeField(name, value) {
  return (dispatch) => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_CHANGE_FIELD_PROJECT,
      payload: { name, value }
    })
  }
}

function clearCreateProject() {
  return (dispatch) => {
    dispatch({
      type: portfolioConstants.CLEAR_CREATE_PROJECT,
    })
  }
}

function deleteProject(id, successMessage, portfolioStatus, back=false) {
  return (dispatch) => {
    dispatch({ type: portfolioConstants.DELETE_PORTFOLIO_PROJECT_REQUEST });

    portfolioService.deleteProject(id)
      .then(() => {
        dispatch({ type: portfolioConstants.DELETE_PORTFOLIO_PROJECT_SUCCESS });
        dispatch(alertActions.success(successMessage));

        if (back) {
          history.push('/portfolio');
        } else { 
          dispatch(portfolioHomeActions.getAllProjects(portfolioStatus));
        }
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: portfolioConstants.DELETE_PORTFOLIO_PROJECT_FAILURE });
      })
  };
};

function clearFlags() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CLEAR_PORTFOLIO_DELETE_FLAGS
    })
  }
}

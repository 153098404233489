import React, { useState } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonBase,
  Button,
  Paper,
  makeStyles
} from "@material-ui/core"
import { MultiAreaCascade } from "./../Shared"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { userFunctionActions, multiAreaCascadeActions } from "./../../actions"
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    fontSize: 14,
  },
  label: {
    fontSize: 12,
    fontWeight: 600
  },
  container: {
    marginTop: 32
  },
  containerTitleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  titleIcon: {
    width: 40,
    marginRight: 16,
    height: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerTitle: {
    fontWeight: 600
  },
  addMoreIconWrapper: {
    width: 18,
    height: 18,
    marginRight: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  addButton: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  trashButtonWrapper: {
    display: "flex",
    alignItems: "center"
  },
  card: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "2rem",
    boxShadow: "none",
  }
}));

export const FormEditFunction = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    userType,
    selectedUser,
    selectedProfileType,
    selectedBusiness
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const [userId, setUserId] = useState(false);
  const { t } = useTranslation();

  function handleChangeProfileType(e) {
    dispatch(userFunctionActions.setUserType(e.target.value))
  }


  function handleAddMoreBusiness(imagineLocals) {
    dispatch(multiAreaCascadeActions.addField(imagineLocals))
  }


  function handleToggleDialogConfirmUser() {
    dispatch(userFunctionActions.toggleDialogConfirmUser())
  }

  function handleToggleDialogCancelUser() {
    dispatch(userFunctionActions.toggleDialogCancelUser())
  }

  return (
    <Paper className={classes.card}>
      <Grid container spacing={4}>
        <Grid item className={classes.containerTitleWrapper} xs={12}>
          <div className={classes.titleIcon}>
            <DescriptionIcon color={"primary"} />
          </div>
          <Typography variant="h5" component="h1" className={classes.containerTitle}>{t('funcao_do_usuario')}</Typography>
        </Grid>
        <Grid item xs={11}>
          <FormControl variant="filled" className={classes.select} disabled={selectedUser === false}>
            <InputLabel id="userFunction-label" className={classes.label}>{t('perfil_do_usuario')}</InputLabel>
            <Select
              className={classes.select}
              labelId="userFunction-label"
              id="userFunction"
              value={selectedProfileType || ""}
              onChange={ handleChangeProfileType }
            >
              { userType.map((item, key) => (
                <MenuItem value={item.type} key={key}>{item.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { selectedProfileType && selectedProfileType !== 'colaborator' && (
          <Grid item xs={12}>
            <MultiAreaCascade hasDeleteButton={true} loadBusinessUnitLocals={selectedBusiness} />
          </Grid>
        )}
        
        { 
          fields.filter(item => item.selectedBusinessUnit).length > 0 && (
            <Grid item xs={11}>
              <ButtonBase
                variant="contained"
                color="transparent"
                className={classes.addButton}
                onClick={() => handleAddMoreBusiness(imagineLocals)}
              >
                <div className={classes.addMoreIconWrapper}>
                  <AddIcon fontSize="small" style={{ color: "white" }} />
                </div>
                {t('incluir_area')}
              </ButtonBase>
            </Grid>
        )}

        { 
          selectedUser &&
          selectedProfileType &&
          (["colaborator", "admin"].includes(selectedProfileType) || (selectedProfileType === "manager" && fields.filter(item => item.selectedBusinessUnit).length > 0)) && (
            <Grid item xs={11}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 16 }}
                  onClick={handleToggleDialogCancelUser}
                >
                  {t('cancelar')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleDialogConfirmUser}
                >
                  { userId ? t('salvar') : t('atribuir_funcao') }
                </Button>
              </div>
            </Grid>
          )}
      </Grid>
    </Paper>
  )
}

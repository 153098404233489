import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Card,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FlagIcon from '@material-ui/icons/Flag';

import { SkeletonInfoDashboard } from '../../components/Skeletons';

const useStyles = makeStyles((theme) => ({
  cardSmall: {
    height: 156,
  },
  cardBig:{
    height: 131,
  },
  card: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(2)
  },
  cardLead: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 22,
  },
  info: {
    fontWeight: 'bold',
    fontSize: 32,
    marginTop: 18,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.neutrals.low.light,
  },
}));

 export function InfoDashboard({
  ideas,
  challenges,
  ups,
  comments,
  leadTime,
  leadTimeConclusion,
  indicatorsLoading,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {indicatorsLoading ? (
        <SkeletonInfoDashboard />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card className={clsx(classes.card, classes.cardSmall)} elevation={0}>
              <EmojiObjectsIcon  
                className={classes.icon} 
                style={{ color: theme.palette.primary.main }}
              />
              <div className={classes.info} style={{ color: theme.palette.primary.main }}>
                {ideas}
              </div>
              <div className={classes.label}>{t('ideias').toLowerCase()}</div>
            </Card>
          </Grid>
          
          <Grid item xs={3}>
            <Card className={clsx(classes.card, classes.cardSmall)} elevation={0}>
              <FlagIcon 
                className={classes.icon} 
                style={{ color: theme.palette.auxiliary.blue.main }} 
              />
              <div className={classes.info} style={{ color: theme.palette.auxiliary.blue.main }}>
                {challenges}
              </div>
              <div className={classes.label}>{t('desafios')}</div>
            </Card>
          </Grid>
          
          <Grid item xs={3}>
            <Card className={clsx(classes.card, classes.cardSmall)} elevation={0}>
              <ThumbUpAltIcon 
                className={classes.icon} 
                style={{ color: theme.palette.auxiliary.pink.main }} 
              />
              <div className={classes.info} style={{ color: theme.palette.auxiliary.pink.main }}>
                {ups}
              </div>
              <div className={classes.label}>{t('up')}s</div>
            </Card>
          </Grid>
          
          <Grid item xs={3}>
            <Card className={clsx(classes.card, classes.cardSmall)} elevation={0}>
              <ChatBubbleIcon
                className={classes.icon}
                style={{ color: theme.palette.auxiliary.orange.main }}
              />
              <div className={classes.info} style={{ color: theme.palette.auxiliary.orange.main }}>
                {comments}
              </div>
              <div className={classes.label}>{t('comentarios')}</div>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={clsx(classes.card, classes.cardBig)} elevation={0}>
              <WatchLaterIcon
                className={classes.icon}
                style={{ color: theme.palette.auxiliary.cyan.main }}
              />
              <Box className={classes.cardLead}>
                <div className={classes.info} style={{ color: theme.palette.auxiliary.cyan.main }}>
                  {leadTime} {leadTime > 1 ? t('dias') : t('dia') }
                </div>
                <div className={classes.label}>{t('lead_time_avaliacao')}</div>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={clsx(classes.card, classes.cardBig)} elevation={0}>
              <WorkOutlineOutlinedIcon
                className={classes.icon}
                style={{ color: theme.palette.auxiliary.yellow.main }}
              />
              <Box className={classes.cardLead}>
                <div className={classes.info} style={{ color: theme.palette.auxiliary.yellow.main }}>
                  {leadTimeConclusion} {leadTimeConclusion > 1 ? t('dia') : t('dias')}
                </div>
                <div className={classes.label}>{t('lead_time_implementaçao')}</div>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

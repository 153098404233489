import axios from 'axios';
import { type } from 'jquery';
import { forEach } from 'lodash';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const crudTemplateService = {
  getAll,
  create,
  destroy,
  update,
  search,
};

async function getAll({ endPoint, page, sort, limit }) {
  const url = `${config.URL_BASE}/${endPoint}?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;
   return axios.get(url, requestHeaders()).then(handleResponse);
};

function create({ body, endPoint }) {
    const url = `${config.URL_BASE}/${endPoint}`;
    return axios.post(url, body, requestHeaders()).then(handleResponse);
};

function destroy({ endPoint, id }) {
    const url = `${config.URL_BASE}/${endPoint}/${id}`;
    return axios.delete(url, requestHeaders()).then(handleResponse);
};

function update({ body, endPoint, id }) {
    const url = `${config.URL_BASE}/${endPoint}/${id}`;
    return axios.put(url, body, requestHeaders()).then(handleResponse);
};

function search({ query, endPoint, typeQuery }) {
    const url = `${config.URL_BASE}/${endPoint}/${typeQuery}/${query}`;
    return axios.get(url, requestHeaders()).then(handleResponse);
};



import { engagementConstants } from "./../constants"

const initialState = {
  tab: 0,
  loading: false,
  error: false,
  upItems: [],
  commentItems: [],
}

export const engagement = (state = initialState, action) => {
  switch(action.type) {
    case engagementConstants.SET_ENGAGEMENT_TAB:
      return {
        ...state,
        tab: action.payload
      }

    case engagementConstants.GET_ENGAGEMENT_ITEMS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case engagementConstants.GET_ENGAGEMENT_ITEMS_SUCCESS: {
      if (action.payload.type === "up") {
        return {
          ...state,
          loading: false, 
          upItems: action.payload.data.data.ups || []
        }
      }

      return {
        ...state,
        loading: false, 
        commentItems: action.payload.data?.data || []
      }
    }

    case engagementConstants.GET_ENGAGEMENT_ITEMS_FAILURE: {
      return {
        ...state,
        loading: false, 
        error: true
      }
    }

    case engagementConstants.CLEAR_ENGAGEMENT_ERRORS: 
      return {
        ...state,
        error: false,
      }
    
    default:
      return state;
  }
}
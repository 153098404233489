import React from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { feedbackActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  subTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: theme.palette.text.primary
  },
  text: {
    fontSize: 16,
    color: '#707070'
  },
  icon: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.text.primary,
  },
  actionsFlex: {
    padding: theme.spacing(1, 3, 3)
  },
}));

function DiscartFeedback(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, onClose } = props;

  function handleCloseFeedback() {
    dispatch(feedbackActions.clearFeedback());
    dispatch(feedbackActions.openFeedback(false));
  }
  
  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.title}>
          {t('descartar_feedback')}
        </Typography>
        <IconButton aria-label="close" className={classes.icon}>
          <CloseIcon onClick={onClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ borderBottom: 'none'}}>
        <DialogContentText className={classes.subTitle}>
          {t('deseja_descartar_seu_feedback')}
        </DialogContentText>
        <DialogContentText className={classes.text}>
          {t('ele_nao_sera_compartilhado_conosco')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsFlex}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t('continuar_feedback')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseFeedback}
        >
          {t('descartar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DiscartFeedback };
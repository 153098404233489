import { makeStyles } from '@material-ui/core';

export const dialogCss = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
    padding: theme.spacing(2, 0, 3, 0),
    lineHeight: 1.5,
  },
  containerAction: {
    padding: theme.spacing(3, 3, 3, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  label: {
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(1, 0),
  },
  button: {
    fontSize: 14,
    textTransform: 'lowercase',
  },
}));

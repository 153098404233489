import React from 'react'
import { makeStyles } from "@material-ui/core"
import { ImagineGrid } from ".."
import { Skeleton } from "@material-ui/lab"

const useStyle = makeStyles((theme) => ({
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d",
    padding: theme.spacing(4),
  },
  wrapper: {
    
  },
  title: {
    width: '50%',
    height: 26,
    borderRadius: 4,
    backgroundColor: "#C0C0C0",
  },
  content: {
    width: '100%',
    height: 20,
    borderRadius: 4,
    backgroundColor: "#E9E9E9"
  },  
}))

export const SkeletonDashboardTops = () => {
  const classes = useStyle();
  const arrayCells = Array.from(Array(10))

  return (
    <ImagineGrid container spacing={2} className={classes.skeletonAnimaton}>
      <ImagineGrid container cols={1} className={classes.wrapper}>
        <ImagineGrid item>
          <Skeleton variant="text" className={classes.title}/>
        </ImagineGrid>
      </ImagineGrid>
      { arrayCells.map(item => (
        <ImagineGrid item className={classes.cellWrapper}>
          <ImagineGrid container cols={1}>
            <ImagineGrid item>
              <Skeleton variant="text" className={classes.content}/>
            </ImagineGrid>
          </ImagineGrid>
        </ImagineGrid>
      ))}
    </ImagineGrid>
  )
}

import React from 'react'
import { makeStyles } from "@material-ui/core"
import { ImagineGrid } from "./../../components"
import { Skeleton } from "@material-ui/lab"

const useStyle = makeStyles((theme) => ({
  headerWrapper: {
    padding: 16,
  },
  itemHeader: {
    height: 16,
    backgroundColor: theme.palette.neutrals.low.lightest,
    borderRadius: 4,
  },
  cellWrapper: {
    backgroundColor: theme.palette.neutrals.high.medium,
    padding: 16,
  },
  cellItem: {
    backgroundColor: theme.palette.neutrals.low.lightest,
    borderRadius: 4,
  },
  actionsCell: {
    display: "flex",
    justifyContent: "flex-end",
  },
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  }
}))

export const SkeletonUserTable = () => {
  const classes = useStyle();
  const arrayCells = Array.from(Array(10))

  return (
    <ImagineGrid container spacing={2} className={classes.skeletonAnimaton}>
      <ImagineGrid container spacing={8} cols={12} className={classes.headerWrapper}>
        <ImagineGrid item xs={5}>
          <Skeleton variant="text" style={{ width: "10%"}} className={classes.itemHeader}/>
        </ImagineGrid>
        <ImagineGrid item xs={2}>
          <Skeleton variant="text" style={{ width: "70%"}} className={classes.itemHeader}/>
        </ImagineGrid>
        <ImagineGrid item xs={2}>
          <Skeleton variant="text" style={{ width: "70%"}} className={classes.itemHeader}/>
        </ImagineGrid>
      </ImagineGrid>
      { arrayCells.map(item => (
        <ImagineGrid item className={classes.cellWrapper}>
          <ImagineGrid container spacing={8} cols={12}>
            <ImagineGrid item xs={5}>
              <Skeleton variant="text" style={{ width: "60%"}} className={classes.itemHeader}/>
            </ImagineGrid>
            <ImagineGrid item xs={2}>
              <Skeleton variant="text" style={{ width: "40%"}} className={classes.itemHeader}/>
            </ImagineGrid>
            <ImagineGrid item xs={2}>
              <Skeleton variant="text" style={{ width: "40%"}} className={classes.itemHeader}/>
            </ImagineGrid>
            <ImagineGrid item xs={2} className={classes.actionsCell}>
              <Skeleton variant="text" style={{ width: "30%"}} className={classes.itemHeader}/>
            </ImagineGrid>
          </ImagineGrid>
        </ImagineGrid>
      ))}
    </ImagineGrid>
  )
}

import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Box, Grid, Button, IconButton, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { portfolioDialogTeamsActions } from '../../actions/portfolio/portfolio-dialog-teams.actions';
import { DialogPortifolio } from './DialogPortifolio';

const filterOptions = createFilterOptions({
  limit: 200,
});

const useStyles = makeStyles(() => ({
  btnFloat: {
    float: 'right',
  },
}));

export function DialogCreateTeam({ open, onClose }) {
  const classes = useStyles();
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { teams } = useSelector(state => state.portfolioDetails);
  const { newTeam, loading, success } = useSelector((state) => state.portfolioDialogTeams);
  const { responsibles } = useSelector(state => state.portfolioDetails);

  useEffect(() => {
    if (teams.details.length > 0) {
      dispatch(portfolioDialogTeamsActions.loadDialogTeams(teams.details)) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if(success) {
      handleCloseDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  function handleChangeParticipant(data, index) {
    if (data) {
      dispatch(portfolioDialogTeamsActions.changeFieldTeam(data.option, index));
    } else {
      dispatch(portfolioDialogTeamsActions.clearFieldTeam(index));
    }
  };

  function handleAddParticipant() {
    dispatch(portfolioDialogTeamsActions.addNewParticipant());
  };

  function handleRemoveParticipant(value) {
    return () => {
      dispatch(portfolioDialogTeamsActions.removeParticipant(value));
    }
  };

  function handleCloseDialog() {
    onClose();
    dispatch(portfolioDialogTeamsActions.clearDialogTeam());
  }

  function handleSubmit() {
    const data = newTeam.map(item => ({
      user: item.value,
      project: projectId
    }))

    dispatch(portfolioDialogTeamsActions.updateTeam(projectId, data, t('equipe_editada')));
  };

  return (
    <DialogPortifolio
      open={open}
      onClose={handleCloseDialog}
      title={teams.details.length > 0 ? t('editar_equipe') : t('nova_equipe')}
      content={
        <>
          {newTeam.map((item, index) => (
            <Fragment key={`${index}-${item.value}`}>
              <Grid item xs={11}>
                <Autocomplete
                  filterOptions={filterOptions}
                  loadingText={t('carregando')}
                  noOptionsText={t('sem_opcoes')}
                  options={responsibles}
                  loading={responsibles.length === 0}
                  onChange={(_e, _items, _options, details) => handleChangeParticipant(details, index)}
                  getOptionLabel={(input) => input.name}
                  defaultValue={{ name: item.name, value: item.value }}
                  renderInput={(params) => <TextField {...params} label={t('participante')} variant="filled" />}
                  renderTags={(option, state) => (<span>{`${option[0].name}`}</span>)}
                />
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <IconButton onClick={handleRemoveParticipant(item.value)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddParticipant}
            >
              {t('adicionar_participante')}
            </Button>
          </Grid>
        </>
      }
      action={
        <>
          {teams.details.length > 0 ? (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.btnFloat}
              onClick={handleSubmit}
              disabled={loading || newTeam.filter(item => item.value === "").length > 0}
            >
              {t('salvar_equipe')}
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnFloat}
              onClick={handleSubmit}
              disabled={loading}
            >
              {t('criar_equipe')}
            </Button>
          )}
        </>
      }
    />
  );
}
import React from 'react'
import { DialogConfirmUser } from "./DialogConfirmUser"
import { DialogCancelUser } from "./DialogCancelUser"
import { DialogDeleteUser } from "./DialogDeleteUser"

export const DialogAllUserFunctions = ({ toggleEdit }) => {
  return (
    <div>
      <DialogConfirmUser toggleEdit={toggleEdit} />
      <DialogCancelUser toggleEdit={toggleEdit} />
      <DialogDeleteUser />
    </div>
  )
}

import React, { useEffect } from 'react';
import {
  Card,
  CardActions,
  Button,
  Collapse,
  CardContent,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { challengeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { selectedLanguageDateInputFormat } from '../../helpers/input-date-format';
import { useTranslation } from 'react-i18next';
import formatUTCHours from '../../helpers/format-utc-hours';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  errorsDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 92,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.neutrals.low.medium,
    marginTop: 16,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 10),
  },
  errorSpan: {
    position: 'absolute',
    width: 550,
    right: -300,
  },
  spanEnd: {
    position: 'absolute',
    width: 550,
    right: 8,
  },
}));

function ChallengeDatesCard({ startDate, dueDate, resultDate, isEditPage=false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { register, errors, control } = useForm({
    mode: 'onBlur',
  });
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);

  startDate = formatUTCHours(startDate);
  dueDate = formatUTCHours(dueDate);
  resultDate = formatUTCHours(resultDate);

  function submit(data, input) {
    dispatch(challengeActions.setDate(data, input));
    if (startDate && dueDate && resultDate && isEditPage === false) {
      dispatch(challengeActions.setDates({ startDate, dueDate, resultDate }))
    }
  }

  function onSubmit(data) {
    if (!data.startDate || !data.dueDate || !data.resultDate) {
      return;
    }

    if (new Date(data.startDate) > new Date(data.dueDate)) {
      return;
    }

    if (new Date(data.dueDate) > new Date(data.resultDate)) {
      return;
    }
    if (data && isEditPage === false) {
      dispatch(challengeActions.setDates(data));
    }
  }

  useEffect(() => {onSubmit({startDate, dueDate, resultDate})}, [startDate, dueDate, resultDate]);
  return (
    <Card>
      <CardActions className={classes.actions}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.iconDiscription}>
            <AssignmentIcon color="primary" />
          </div>
          <div className={classes.description}>{t('datas')}</div>
        </div>
      </CardActions>
        <CardContent className={classes.content}>
            <div className={classes.dates}>
              <div style={{ marginRight: 20, fontSize: 14, fontWeight: 600 }}>
                {t('inscricoes')}
              </div>
              <div>
                <Controller
                  as={<KeyboardDatePicker />}
                  variant="inline"
                  inputVariant="filled"
                  format={selectedLanguageDateInputFormat(language)}
                  fullWidth
                  style={{ maxWidth: 320 }}
                  margin="normal"
                  id="startDate"
                  label={t('inicio')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputRef={register}
                  rules={{ required: true }}
                  invalidDateMessage={
                      <span className={classes.errorSpan}>
                        {t('data_de_inicio_invalida')}
                      </span>
                  }
                  error={!!errors.startDate}
                  control={control}
                  name="startDate"
                  defaultValue={startDate}
                  onAccept={(data) => submit(data, 'startDate')}
                  autoOk
                  maxDateMessage={
                    <span className={classes.errorSpan}>
                      {t('atencao')}!{' '}
                      {t(
                        'a_data_de_inicio_das_inscricoes_nao_pode_ser_maior_que_a_data_de_termino_e_de_cadastro_do_desafio'
                      )}
                    </span>
                  }
                />
              </div>

              <div
                style={{
                  fontSize: 14,
                  color: theme.palette.neutrals.low.medium,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                {t('ate')}
              </div>
              <div>
                <Controller
                  as={<KeyboardDatePicker />}
                  variant="inline"
                  inputVariant="filled"
                  format={selectedLanguageDateInputFormat(language)}
                  fullWidth
                  style={{ maxWidth: 320 }}
                  margin="normal"
                  id="dueDate"
                  label={t('termino')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputRef={register}
                  rules={{ required: true }}
                  invalidDateMessage={
                    <span className={classes.errorSpan}>
                      {t('data_de_termino_invalida')}
                    </span>
                  }
                  error={!!errors.dueDate}
                  control={control}
                  name="dueDate"
                  defaultValue={dueDate}
                  minDate={startDate}
                  autoOk
                  onAccept={(data) => submit(data, 'dueDate')}
                  minDateMessage={
                    <span className={classes.spanEnd}>
                      {t('atencao')}!{' '}
                      {t(
                        'a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio'
                      )}
                    </span>
                  }
                />
              </div>
            </div>
            <div className={classes.errorsDate}>
              <p className={classes.error}>
                {errors.startDate &&
                  errors.startDate.type === 'required' &&
                t('a_data_de_inicio_e_obrigatoria')}
              </p>
              <p className={classes.error}>
                {errors.dueDate &&
                  errors.dueDate.type === 'required' &&
                t('a_data_de_termino_e_obrigatoria')}
              </p>
            </div>

            <div className={classes.dates}>
              <div style={{ marginRight: 20, fontSize: 14, fontWeight: 600 }}>
                {t('resultado')}
              </div>
              <div>
                <Controller
                  as={<KeyboardDatePicker />}
                  variant="inline"
                  inputVariant="filled"
                  format={selectedLanguageDateInputFormat(language)}
                  fullWidth
                  style={{ maxWidth: 320 }}
                  margin="normal"
                  id="resultDate"
                  label={t('data')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputRef={register}
                  rules={{ required: true }}
                  invalidDateMessage={
                    <span className={classes.errorSpan}>
                      {t('data_de_resultado_invalida')}
                    </span>
                  }
                  error={!!errors.resultDate}
                  control={control}
                  name="resultDate"
                  defaultValue={resultDate}
                  autoOk
                  minDate={dueDate}
                  minDateMessage={
                    <span className={classes.errorSpan}>
                      {t('atencao')}!{' '}
                      {t(
                        'a_data_de_resultado_nao_pode_ser_inferior_a_data_de_inscricoes_e_de_cadastro_de_desafio'
                      )}
                    </span>
                  }
                  onAccept={(data) => submit(data, 'resultDate')}
                />
              </div>
            </div>

            <p className={classes.error}>
              {errors.resultDate &&
                errors.resultDate.type === 'required' &&
                t('a_data_de_resultado_e_obrigatorio')}
            </p>

        </CardContent>
    </Card>
  );
}

export { ChallengeDatesCard };

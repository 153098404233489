import React from 'react';
import { useWindowSize } from '../../hooks';
import { Dialog } from '@material-ui/core';
import config from '../../config';
import { TransitionUp, Transition } from '../CreateIdeaDialogs/DialogTransition';

function DialogResponsive(props) {
  const [width] = useWindowSize();

  return (
    <Dialog
      {...props}
      maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
      fullWidth={true}
      fullScreen={width < config.RESPONSIVE_MOBILE ? true : false}
      /* PaperProps={{
        style: {
          minHeight: width < config.RESPONSIVE_MOBILE ? 'initial' : '80vh',
          maxHeight: width < config.RESPONSIVE_MOBILE ? 'initial' : '80vh',
        },
      }} */
      TransitionComponent={
        width < config.RESPONSIVE_MOBILE ? TransitionUp : Transition
      }
    >
      {props.children}
    </Dialog>
  );
}

export { DialogResponsive };

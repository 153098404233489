import React, { useState } from 'react';
import { IconButton, Menu, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { CustomTooltip } from './CustomTooltip';
import { useTranslation } from 'react-i18next';

function MenuInformation({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CustomTooltip title={t('ver_detalhes')} aria-label={t('ver_detalhes')}>
        <IconButton onClick={handleClick} size="small">
          <InfoIcon
            fontSize="inherit"
            color={anchorEl ? 'primary' : 'inherit'}
          />
        </IconButton>
      </CustomTooltip>
      <Menu
        id="menu-information"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <Paper style={{ width: 214, padding: 14 }}>{children}</Paper>
      </Menu>
    </div>
  );
}

export { MenuInformation };

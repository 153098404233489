import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { helpCenterService } from '../../services/help-center.service'
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    padding: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingLeft: 24,
    paddingRight: 124,
    marginBottom: 24,
    flexWrap: 'wrap'

  },
  item: {
    background: "theme.palette.neutrals.high.main 0% 0% no-repeat padding-box",
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  download: {
    textAlign: "left",
    font: "normal normal 600 14px Poppins",
    letterSpacing: "0px",
    color: "theme.palette.primary.main",
    textTransform: "uppercase"
  },
  link: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    display: 'flex',
    alignItems: 'center',

    width: 144,
    height: 40,

    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  iconDownload: {
    marginRight: 8,

  },

}));

function TutoriaisTable() {
  const classes = useStyles();
  const [tutorials, setTutorials] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    helpCenterService.getTutorials()
      .then(response => {
        setTutorials(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className={classes.root} >
      <div className={classes.header}>
        <strong>{t('titulo')}</strong>
        <strong>{t('link')}</strong>
      </div>

      {!!tutorials && (
        <>
          {
            tutorials.map((item, index) => (
              <div className={classes.item}>
                <p>{item.description}</p>
                <a className={classes.link} href={item.documentUrl} rel="noopener noreferrer" target='_blank'>
                  <GetAppIcon
                    htmlColor={theme.palette.primary.main}
                    className={classes.iconDownload} />

                  <strong className={classes.download} >DOWNLOAD</strong>
                </a>
              </div>
            ))
          }
        </>
      )}




    </div>
  )
}

export { TutoriaisTable }
import React, { useEffect } from 'react';
import {
  DialogContent,
  makeStyles,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  Hidden,
  Grid,
  TextField
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dialogCss } from './dialogCss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DialogCreateIdea } from './DialogCreateIdea';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogArea({ challenge, onClose }) {
  const classes = useStyles();
  const classesDialog = dialogCss();
  const { imagineLocals, Challenge } = useSelector((state) => state.domains);
  const idea = useSelector((state) => state.ideaCreate);
  const { dialogArea, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const { t } = useTranslation();

  function createDraft() {
    if (idea.businessUnitLocal.length > 0) {
      dispatch(
        ideaCreateActions.createDraft(idea, t('ideia_salva_em_rascunhos'))
      );
    }
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }

  function selectedCheckBox(imagineLocal, event) {
    if (!imagineLocal.checked) {
      dispatch(ideaCreateActions.setBusinessUnitLocal(imagineLocal));
      dispatch(ideaCreateActions.setLocalChecked(imagineLocal._id));
    } else {
      dispatch(ideaCreateActions.removeBusinessUnitLocal(imagineLocal));
      dispatch(ideaCreateActions.setLocalUnchecked(imagineLocal._id));
    }
  }

  function onSubmit() {
    if (idea.businessUnitLocal.length > 0) {
      if (fromHistory[fromHistory.length - 1] === 'sendIdea') {
        dispatch(ideaCreateDialogsActions.openDialogSendIdea());
        return;
      }

      if (challenge) {
        dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_AREA'));
      } else {
        if (Challenge && Challenge?.length > 0) {
        dispatch(ideaCreateDialogsActions.openDialogChallenge('DIALOG_AREA'));
        } else {
          dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_AREA'));
        }
      }
    }
  }

  return (
    <DialogCreateIdea open={dialogArea}>
      <DialogCustomTitle id="customized-dialog-title" onClose={onClose}>
        {t('para_qual_area_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classesDialog.subtitle}>
            {t('selecione_as_areas_que_esta_ideia_podera_se_implementada')}.
          </FormLabel>
          {idea.locals?.length > 0 &&
            idea.locals.map((imagineLocal) => (
              <FormControlLabel
                key={imagineLocal._id}
                className={classesDialog.label}
                label={imagineLocal.local?.name}
                name={imagineLocal._id}
                value={imagineLocal._id}
                checked={!!imagineLocal.checked}
                onChange={(event) => selectedCheckBox(imagineLocal, event)}
                // inputRef={register}
                control={<Checkbox color="primary" />}
              style={{
                      padding: 0, 
                    }}
              />
            ))}
        </FormControl>
      </DialogContent>
      <DialogActions className={classesDialog.containerAction}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {fromHistory.length > 0 && (
              <Button
                color="primary"
                onClick={previous}
                startIcon={<ArrowBackIcon />}
                className={classesDialog.button}
              >
                {t('voltar')}
              </Button>
            )}
          </div>
          <div>
            <Hidden smDown>
              <Button
                className={classesDialog.button}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={createDraft}
              >
                {t('salvar_rascunho')}
              </Button>
            </Hidden>
            <Button
              className={classesDialog.button}
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<ArrowForwardIcon />}
              onClick={onSubmit}
            >
              {t('avancar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogArea };

import React from 'react';
import {
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core"
import { useSelector } from 'react-redux';
import { DialogResponsive } from './DialogResponsive';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { ImagineGrid as Grid } from '../../components'
import { useDispatch } from "react-redux"
import { manageUsersActions } from "../../actions"

const useStyle = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalWrapper: {
    padding: "2rem",
    justifySelf: "center"
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  warningIcon: {
    color: theme.palette.auxiliary.yellow.main,
    fontSize: 100,
  },
  button: {
    width: "100%"
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600
  },
  description: {
    textAlign: "center",
    fontSize: 16
  }
}))

export const DialogDeleteUser = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { dialogDeleteUser, userToDelete, loading, paging } = useSelector(state => state.manageUsers)

  function handleNoButton() {
    dispatch(manageUsersActions.toggleDialogDeleteUser(false))
  }

  function handleYesButton() {
    dispatch(
      manageUsersActions.deleteUser(
        userToDelete,
        paging.search,
        paging.page,
        paging.sort,
        paging.limit,
        paging.statusCode
      )
    )
  }

  return (
    <DialogResponsive open={dialogDeleteUser}>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item className={classes.iconWrapper}>
            <WarningRounded className={classes.warningIcon} />
          </Grid>
          <Grid item className={classes.titleWrapper}>
            <Typography variant="h4" component="h2" className={classes.title}>
              atenção, exclusão de dados!
            </Typography>
          </Grid>
          <Grid item>
            <p className={classes.description}>A função do usuário será removida do sistema. Tem certeza de que deseja excluir?</p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={4} cols={2}>
          <Grid item>
            <Button
              disabled={loading}
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleNoButton}
            >
              não
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleYesButton}
            >
              sim
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  )
}

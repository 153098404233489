import React, { Fragment, useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText"
import Input from "@material-ui/core/Input";
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core" 
import { useDispatch, useSelector } from "react-redux"
import { ideaActions } from "./../../../actions"
import { useWindowSize  } from "./../../../hooks"
import config from '../../../config';
import { uniqueId } from 'lodash';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.grey.dark,
    borderRadius: "4px",
    fontSize: "15px",
    padding: "1rem",
    color: "#343A40"
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    padding: "4px 8px",
    fontSize: "10px",
    color: "#343A40",
    borderRadius: "4px",
    backgroundColor: "#CED4DA",
    marginLeft: "0.5rem"
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  },
}))

export const MyIdeasFilterMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    loading,
    paging,
    paging: { statusCode },
  } = useSelector((state) => state.idea);
  const [width] = useWindowSize();
  const filters = [
    { id: uniqueId, value: 'CONCLUDED', name: t('concluida') },
    { id: uniqueId, value: 'CANCELED', name: t('cancelada') },
    {
      id: uniqueId,
      value: 'AWAITING_QUALIFICATION',
      name: t('aguardado_qualificacao'),
    },
    { id: uniqueId, value: 'UNQUALIFIED', name: t('nao_qualificado') },
    { id: uniqueId, value: 'PATENT_PENDING', name: t('pendente_de_patente') },
    { id: uniqueId, value: 'EXECUTION_QUEUE', name: t('fila_de_execucao') },
    { id: uniqueId, value: 'EXECUTING', name: t('execucao') },
    { id: uniqueId, value: 'QUALIFIED', name: t('qualificada') },
    { id: uniqueId, value: 'DRAFT', name: t('rascunho') },
    { id: uniqueId, value: 'PENDING_REVIEW', name: t('solicitar_revisao')}
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeFilter(e) {
    dispatch(ideaActions.toggleFilter(e.target.value));
  }

  function handleFilter() {
    dispatch(
      ideaActions.getIdeasUser(
        1,
        paging.sort,
        paging.search,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleStatusToName(status) {
    const filteredItem = filters.filter((item) => {
      return item.value === status;
    })
    return filteredItem[0].name;
  }


  return (
    <Fragment>
      <ButtonBase 
          className={classes.filterButton}
          onClick={handleClick}
        >
          <FilterListIcon className={classes.filterButtonIcon} />
          filtro
          <ArrowDropDownIcon className={classes.filterButtonArrow} />
        </ButtonBase>
        <Menu
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <Box className={classes.filterMenuHeader}>
              <Typography component="p" className={classes.filterMenuHeaderTitle}>filtrar por</Typography>
            </Box>
            <Box className={classes.filterMenu}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={classes.select}>
                    <InputLabel id="status-label" className={classes.label}>{t('status')}</InputLabel>
                    <Select
                      labelId="status-label"
                      id="statusFilter"
                      multiple
                      fullWidth
                      value={statusCode}
                      onChange={handleChangeFilter}
                      renderValue={(selected) => {
                        if (width > config.RESPONSIVE_MOBILE) {
                          return `${handleStatusToName(selected[0])} ${
                            selected.length > 1 ? `+${selected.length - 1}` : ''
                            }`;
                        }
                      }}
                      className={classes.select}
                    >
                      {filters.map((filter, index) => (
                        <MenuItem key={index} value={filter.value}>
                          <Checkbox
                            color="primary"
                            checked={statusCode.indexOf(filter.value) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Button 
                        variant="contained" 
                        color="primary"
                        startIcon={<FilterListIcon />}
                        onClick={handleFilter}
                        disabled={loading}
                      >
                        filtrar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Menu>
    </Fragment>
  )
}

import React, { Fragment, useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core" 
import { useDispatch, useSelector } from "react-redux"
import { walletActions } from "./../../../actions"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.grey.dark,
    borderRadius: "4px",
    fontSize: "15px",
    padding: "1rem",
    color: "#343A40"
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    padding: "4px 8px",
    fontSize: "10px",
    color: "#343A40",
    borderRadius: "4px",
    backgroundColor: "#CED4DA",
    marginLeft: "0.5rem"
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  }
}))

export const UserPointsFilterMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    paging,
    loading,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  const {
    selectedUser,
  } = useSelector(state => state.userFunction)
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeFilter(e) {
    dispatch(walletActions.setStatus(e.target.value));
  }


  function handleChangePeriod(event) {
    dispatch(walletActions.setPeriod(event.target.value));
  }

  function filterClean(e) {
    dispatch(walletActions.setStatus("all"));
    dispatch(walletActions.setPeriod("all"));
  }

  function handleFilter() {
    dispatch(walletActions.getWallets(selectedUser.id, 1, quantityPerPage, filter, sort, query))
  }

  return (
    <Fragment>
      <ButtonBase 
          className={classes.filterButton}
          onClick={handleClick}
        >
          <FilterListIcon className={classes.filterButtonIcon} />
          filtro
          { (paging.filter.period !== "all" || paging.filter.classification !== "all") && (
            <Box className={classes.filterButtonBadge}>
              { paging.filter.period !== "all" && paging.filter.classification !== "all" ? 2 : 1 }
            </Box> 
          )}
          <ArrowDropDownIcon className={classes.filterButtonArrow} />
        </ButtonBase>
        <Menu
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <Box className={classes.filterMenuHeader}>
              <Typography component="p" className={classes.filterMenuHeaderTitle}>filtrar por</Typography>
            </Box>
            <Box className={classes.filterMenu}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={classes.select}>
                    <InputLabel id="tipoSelect-label" className={classes.label}>{t('tipo')}</InputLabel>
                    <Select
                      className={classes.select}
                      labelId="tipoSelect-label"
                      name="filterType"
                      value={paging.filter.classification}
                      onChange={handleChangeFilter}
                      disabled={loading}
                    >
                      <MenuItem value={'credit'}>{t('credito')}</MenuItem>
                      <MenuItem value={'debit'}>{t('resgatados')}</MenuItem>
                      <MenuItem value={'all'}>{t('todos')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={classes.select}>
                    <InputLabel id="tipoSelect-label" className={classes.label}>{t('periodo')}</InputLabel>
                    <Select
                      className={classes.select}
                      labelId="tipoSelect-label"
                      name="periodType"
                      value={paging.filter.period}
                      onChange={handleChangePeriod}
                      disabled={loading}
                    >
                      <MenuItem value={7}>07 {t('dias')}</MenuItem>
                      <MenuItem value={15}>15 {t('dias')}</MenuItem>
                      <MenuItem value={30}>30 {t('dias')}</MenuItem>
                      <MenuItem value={60}>60 {t('dias')}</MenuItem>
                      <MenuItem value={90}>90 {t('dias')}</MenuItem>
                      <MenuItem value={'all'}>{t('todos')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="primary" onClick={filterClean} disabled={loading}>
                          limpar filtro
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button 
                          variant="contained" 
                          color="primary"
                          startIcon={<FilterListIcon />}
                          onClick={handleFilter}
                          disabled={loading}
                        >
                          filtrar
                        </Button>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Menu>
    </Fragment>
  )
}

import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const infraStructureService = {
    getAssociation,
    getInfraStructure,
    postNewAssociation,
    searchByName,
    postInfraStructure,
    deleteInfraStructure,
    getById,
    editInfraStructures,
    deleteAssociation,
    updateAssociation
};

function getAssociation(id) {
    return axios.get(`${config.URL_BASE}/business-unit-local/infra/${id}`, requestHeaders());
};

function getInfraStructure(page, limit, types) {
    const filterType = !!types?.length ? `&types=${JSON.stringify(types)}` : '';
    return axios.get(`${config.URL_BASE}/infrastructures?page=${page}&limit=${limit}${filterType}`, requestHeaders()).then(handleResponse);
};

async function postNewAssociation(associations) {
    !!associations.length && associations.forEach(async (association) => {
        const { occupationArea, local, businessUnit } = association;
        const validationIds = !!occupationArea._id?.length && !!local._id?.length && !!businessUnit._id?.length;
        if (validationIds) return axios.post(`${config.URL_BASE}/business-unit-local`, association, requestHeaders()).then(handleResponse);
    });
    return true;
}

function searchByName(name) {
    return axios.get(`${config.URL_BASE}/infrastructures?searchTerm=${name}`, requestHeaders());
};

function postInfraStructure(type, name) {
    switch (type) {
        case 'local':
            return axios.post(`${config.URL_BASE}/infrastructures/local`, name, requestHeaders());
        case 'occupationArea':
            return axios.post(`${config.URL_BASE}/infrastructures/occupation-area`, name, requestHeaders());
        case 'businessUnit':
            return axios.post(`${config.URL_BASE}/infrastructures/business-unit`, name, requestHeaders());
        default:
            return null
    };
};

function deleteInfraStructure(id, type) {
    switch (type) {
        case 'local':
            return axios.delete(`${config.URL_BASE}/infrastructures/local/${id}`, requestHeaders()).then(handleResponse);
        case 'occupationArea':
            return axios.delete(`${config.URL_BASE}/infrastructures/occupation-area/${id}`, requestHeaders()).then(handleResponse);
        case 'businessUnit':
            return axios.delete(`${config.URL_BASE}/infrastructures/business-unit/${id}`, requestHeaders()).then(handleResponse);
        default:
            return null
    };
};

function getById(type, id) {
    switch (type) {
        case 'local':
            return axios.get(`${config.URL_BASE}/local/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        case 'occupationArea':
            return axios.get(`${config.URL_BASE}/occupation-area/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        case 'businessUnit':
            return axios.get(`${config.URL_BASE}/business-unit/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        default:
            return null
    };
};

function editInfraStructures(id, type, name) {
    switch (type) {
        case 'local':
            return axios.put(`${config.URL_BASE}/local/${id}`, name, requestHeaders());
        case 'occupationArea':
            return axios.put(`${config.URL_BASE}/occupation-area/${id}`, name, requestHeaders());
        case 'businessUnit':
            return axios.put(`${config.URL_BASE}/business-unit/${id}`, name, requestHeaders());
        default:
            return null
    };
};

function deleteAssociation(id) {
    return axios.delete(`${config.URL_BASE}/business-unit-local/${id}`, requestHeaders()).then(handleResponse);
};

async function updateAssociation(associations) {
    !!associations.length && associations.filter(({ isUpdate }) => isUpdate === true).forEach(async ({
        businessUnit,
        occupationArea,
        local,
        associationId }) => {
        return axios.put(`${config.URL_BASE}/business-unit-local/${associationId}`, {
        businessUnit, occupationArea, local
        }, requestHeaders()).then(handleResponse);
    });  
    return true;
};
import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const feedbackService = {
  sendFeedback,
};

function sendFeedback(feedbackText) {
  return axios.post(`${config.URL_BASE}/user-feedback`, feedbackText, requestHeaders())
  .then(handleResponse)
  .then((response) => response.data);
}
import update from "react-addons-update";
import { imageUploaderConstants } from "../constants/imageUploader.constants";

const initialState = {
  newImage: {},
};

export const imageUpload = (state = initialState, action) => {
  switch (action.type) {
    case imageUploaderConstants.IMAGEUPLOAD_SET_IMAGE:
      return update(state, { newImage: { $set: action.payload.image } });

    case imageUploaderConstants.SET_IMAGE_TO_REMOVE:
      return update(state, {
        imagesToRemove: { $push: [action.payload.type] }
      });

    case imageUploaderConstants.IMAGEUPLOAD_REMOVE_IMAGE:
      return update(state, {
        newImage: { $set: {} },
      });

    case imageUploaderConstants.IMAGEUPLOAD_CLEAR:
      return update(state, {
        newImage: { $set: {} },
      });

    default:
      return state;
  }
};
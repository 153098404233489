import React, { useState, useEffect, Fragment} from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  Box,
  List,
  ListItem,
  ListItemText, 
  Typography, 
  ListItemIcon
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { tableCss } from './TableCss';
import { truncate, history } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { manageUsersActions, walletActions, userFunctionActions } from "./../../actions";
import { TagPerfil } from "./../../components"
import { SkeletonUserTable } from "./../../components"
import { useTranslation } from 'react-i18next';
import { TableMenu } from './TableMenu';
import { CustomTooltip } from '../Shared';


function UsersTable() {
  const dispatch = useDispatch();
  const { users, paging, loading, selectedUser } = useSelector(state => state.manageUsers)
  const { imagineLocals } = useSelector(state => state.domains)
  // const classes = useStyle();
  const { t } = useTranslation();
  const columns = [
    { id: 'name', label: t('nome') },
    { id: 'area', label: t('area_de_atuacao') },
    { id: 'status', label: t('perfil_do_usuario') },
    { id: 'actions', label: '' },
  ];
  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });

  function handleChangePage(event) {
    let newPage = paging.page + 1;
    dispatch(
      manageUsersActions.getFilteredUsers(
        paging.search,
        newPage,
        paging.sort,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(
      manageUsersActions.getFilteredUsers(
        paging.search,
        1,
        paging.sort,
        limit,
        paging.statusCode
      )
    );
  }

  function handleOpenDeleteUser(userId) {
    return () => {
    dispatch(manageUsersActions.toggleDialogDeleteUser(userId))
    }
  }
  function handleNavigate(userId, to) {
    return () => {
      if (selectedUser) {
        history.push(to)
      } else {
        dispatch(userFunctionActions.loadPage(userId, imagineLocals))
        history.push(to)
      }
    }
  }
  function handleNavigatePoints(userId, to) {
    return () => {
      dispatch(walletActions.getUserPointsRequestStart())
      if (selectedUser) {
        history.push(to)
      } else {
        dispatch(userFunctionActions.loadPage(userId, imagineLocals))
        history.push(to)
      }
    }
  }

  return (
    <Box style={{ display: 'flex' }}>
      {loading ? (
      <SkeletonUserTable />
      ) : (
        <Paper className={tableClasses.paperRoot}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="users table"
              classes={{ root: tableClasses.table }}
            >
              <TableHead style={{ height: '70px' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  users.map(item => {
                    return (
                      <TableRow
                        hover
                        key={item.id}
                        style={{
                          cursor: 'pointer',
                          height: '60px'
                        }}
                        classes={{ root: tableClasses.tableRow }}
                      >
                        <TableCell
                          classes={{ root: tableClasses.tableCellRadiusLeft }}
                        >
                          <CustomTooltip 
                            title={item.name} 
                            aria-label={item.name} 
                            placement="bottom-start"
                          >
                            <Typography 
                              variant="subtitle2" 
                              className={tableClasses.link} 
                              onClick={handleNavigatePoints(item.id, `/users/points/${item.id}`)}
                            >
                          {truncate(item.name, 30)}
                            </Typography>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell classes={{ root: tableClasses.tableCell }}>
                          {item.occupationArea}
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCellRadiusRight }}
                        >
                          <TagPerfil perfil={item.typeProfile.description} />
                        </TableCell>
                        <TableMenu>
                          <List>
                            <ListItem onClick={handleNavigate(item.id, `/users/function/${item.id}`)} button>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={<Typography>
                                  {t('editar')}
                                </Typography>
                                }
                              />
                            </ListItem>
                            <ListItem onClick={handleOpenDeleteUser(item.id)} button>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={<Typography>
                                  {t('deletar')}
                                </Typography>
                                }
                              />
                            </ListItem>
                          </List>
                        </TableMenu>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            classes={{
              input: tableClasses.paginationInput,
              spacer: tableClasses.paginationSpacer,
              caption: tableClasses.paginationCaption,
              toolbar: tableClasses.paginationToolbar,
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('exibindo')}
            style={{
              zIndex: '50',
              position: 'relative'
            }}
          />
        </Paper>
      )}
    </Box >
  );
}

export { UsersTable };


import React from 'react';
import { Card, useTheme, makeStyles } from '@material-ui/core';
import { history } from '../../helpers';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  ideaPoints: {
    width: '100%',
    padding: theme.spacing(2, 0),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    '&:nth-child(1)':{
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.main,
  },
  number: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  link: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.grey.darkest,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

export function IdeaPoints({ ideaTotal, walletAmmount, userId }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.ideaPoints}>
      <Card className={classes.row} elevation={0}>
        <div className={classes.items}>
          <div style={{ marginRight: 8 }}>
            <EmojiObjectsIcon color="primary" />
          </div>
          <div className={classes.title}>{t('ideias').toLowerCase()}</div>
        </div>
        <div
          className={classes.items}
          style={{ justifyContent: 'space-between' }}
        >
          <div className={classes.number}>{ideaTotal}</div>
          <div className={classes.link} onClick={() => history.push('/my-ideas')}>
            {t('ver_todos')}
          </div>
        </div>
      </Card>

      <Card className={classes.row} elevation={0}>
        <div className={classes.items}>
          <div style={{ marginRight: 8 }}>
            <AccountBalanceWalletIcon style={{ color: theme.palette.auxiliary.cyan.main }} />
          </div>
          <div className={classes.title}>{t('pontos').toLowerCase()}</div>
        </div>
        <div
          className={classes.items}
          style={{ justifyContent: 'space-between' }}
        >
          {walletAmmount?  (
            <div className={classes.number}>{parseInt(walletAmmount)}</div>
          ):  (
            <div className={classes.number}>0</div>
          )}
          <div
            className={classes.link}
            onClick={() => history.push(`/wallet/${userId}`)}
          >
            {t('ver_todos')}
          </div>
        </div>
      </Card>
    </div>
  );
}


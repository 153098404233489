import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import { tableCss } from './TableCss';
import { infraStructureActions } from './../../actions';
import { InfrastructureRow } from '../Infrastructure/InfrastructureRow';

function InfraestructureTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { infraStructures, paging, filters: { types } } = useSelector((state) => state.infraStructure);
  const tableClasses = tableCss({ text: t('linhas_por_pagina') });

  const columns = [
    { id: 'date', label: t('data_do_cadastro') },
    { id: 'name', label: t('nome_da_infraestrutura') },
    { id: 'type', label: t('tipo_da_infraestrutura') },
    { id: 'actions', label: t('vincular') },
  ];  

  function handleChangePage(_event, page) {
    let newPage = page + 1;
    dispatch(infraStructureActions.getInfraStructure({ page: newPage, sort: 'name', limit: paging.limit, types }));
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(infraStructureActions.getInfraStructure({
      page: paging.page,
      limit: limit,
      types
    }));
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer sx={ { position: 'relative' }}>
        <Table
          stickyHeader
          aria-label="infrastructure table"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {infraStructures.map((item, key) => (
              <InfrastructureRow key={item._id} row={item} />  
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging.total}
        rowsPerPage={paging.limit}
        page={paging.page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
        style={{ position: 'relative', zIndex: 10 }}
      />
    </Paper>
  );
}

export { InfraestructureTable };

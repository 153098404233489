import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SaveIcon from '@material-ui/icons/Save';

import { LoadingButton } from '../../components/Shared';

const useStyle = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  tagCard: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  iconAvatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

export function UserSettingsContent({ register, control, handleProfileType }) {
  const classes = useStyle();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { loading } = useSelector(state => state.userFunction);

  return (
    <Card elevation={0} className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.tagCard}>
          <Avatar className={classes.iconAvatar}>
            <NotificationsIcon color="primary" />
          </Avatar>
          <Typography variant="h3">
            {t('notificacoes').toLocaleLowerCase()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {t('canal_de_notificacao')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={
                  <Typography variant="body2">
                    {t('desejo_receber_as_notificacoes_por_email')}
                  </Typography>
                }
              />
            }
            name="notificationAccepted.email"
            inputRef={register}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {t('me_notifique_quando')}...
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={
                  <Typography variant="body2">
                    {t('alguma_ideia_minha_for_aprovada')}
                  </Typography>
                }
              />
            }
            name="notificationTrigger.ideaApproved"
            inputRef={register}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={
                  <Typography variant="body2">
                    {t('alguem_der_um_up_em_uma_ideia_minha')}
                  </Typography>
                }
              />
            }
            name="notificationTrigger.ideaUpped"
            inputRef={register}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={
                  <Typography variant="body2">
                    {t('alguem_comentar_uma_ideia_minha')}
                  </Typography>
                }
              />
            }
            name="notificationTrigger.ideaComment"
            inputRef={register}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={
                  <Typography variant="body2">
                    {t('alguma_ideia_for_concluida')}
                  </Typography>
                }
              />
            }
            name="notificationTrigger.ideaConcluded"
            inputRef={register}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          {handleProfileType(user.typeProfile.description)?.map((item) => (
            <Controller
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label={
                    <Typography variant="body2">
                      {t(`${item.label}`)}
                    </Typography>
                  }
                />
              }
              name={item.name}
              inputRef={register}
              control={control}
            />
          ))}
        </Grid>
        <Grid item xs={12} className={classes.button}>
          {loading || user.loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
            >
              {t('salvar_configuracoes')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { 
  Card, 
  List, 
  Divider,
  ListItem, 
  ListItemText,
  Typography, 
  makeStyles,
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';

import { contentsActions } from '../../actions';
import { truncate, history } from '../../helpers';
import { CustomTooltip } from '../Shared/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  content: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.main,
  },
  icon: {
    marginRight: 8,
    color: theme.palette.auxiliary.pink.main,
  },
  list: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    backgroundColor: theme.palette.neutrals.high.main
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    wordWrap: 'break-word',
    color: theme.palette.neutrals.low.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    color: theme.palette.neutrals.low.light,    
  },
  item: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 2,
    paddingBottom: 2,
  },
  seeAll: {
    fontWeight: 600,
    fontSize: 11,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  headerSeeAll: {
    fontSize: 11,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.grey.darkest,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
    '&:last-child': {
      height: 0
    }
  }
}));

export function Contents({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contents, contentsLoading } = useSelector(state => state.contents)

  useEffect(() => {
    dispatch(contentsActions.getContents());
  }, [dispatch])

  return (
    <div className={classes.root}>
      {title && (
        <Card className={classes.row} elevation={0}>
          <div className={classes.content}>
            <NoteIcon className={classes.icon} />
            {t('conteudos')}
          </div>
          <div>
            <div
              className={classes.link}
              onClick={() => history.push('/contents-details')}
            >
              {t('ver_todos')}
            </div>
          </div>
        </Card>
      )}
      {!contentsLoading && (
        <List className={classes.list}>
         { contents &&
           contents.slice(0, 4).map((content, index) => {
            return content.highlight && (
              <>
                <ListItem className={classes.item} key={index}>
                  <ListItemText>
                    <CustomTooltip title={content.title}>
                      <Typography>
			{content.link ? (
	                        <a
	                          className={classes.title}
	                          href={content.link}
	                          target="_blank"
	                          rel="noopener noreferrer"
				  style={{ wordWrap: 'break-word' }} 
	                        >
	                          {content.title}
	                        </a>
                        )
                          : (
                            <>
                              {content.title}
                            </>
                          )}
                      </Typography>
                    </CustomTooltip>
                    {/* <div className={classes.subTitle}>
                      <TimeAgo
                        date={content.createdAt}
                        formatter={formatterTimer}
                      />{' '}
                      por {content.user.name}
                    </div> */}
                    <div className={classes.subTitle}>
                      {truncate(content.description, 120)}
                    </div>
                  </ListItemText>
                </ListItem>
                <Divider className={classes.divider} />
              </>
            );
          })}
        </List>
      )}
      {/*  <div className={classes.seeAll}>VER TODOS</div> */}
    </div>
  );
}
import React, { useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Menu,
  Button,
  makeStyles,
  useTheme,
  IconButton,
  Typography
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import batchPrediction from '../../assets/imgs/batch-prediction-rounded-48dp.svg';
import { useTranslation } from 'react-i18next';
import { dashboardActions } from '../../actions';
import { truncate, history } from '../../helpers';
import { today, beforeMonth, formatDate } from '../../utils/date';
import { SkeletonDashboardTops } from '../../components/Skeletons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useDispatch } from 'react-redux';
import { dashboardService } from '../../services';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.text.primary, 
    fontWeight: 500,
    marginLeft: 8,
  },
  line: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineGroup: {
    display: 'flex',
  },
  icon: {
    width: 20,
    color: theme.palette.text.secondary,
    marginRight: 6,
  },
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  buttonCsv: {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: theme.palette.neutrals.high.main
  },
  chevron: {
    cursor: 'pointer'
  },
  bulTitle: {
    fontSize: 14,
    color: theme.palette.text.dark,
    fontWeight: 600,
  },
  bulSubtitle: {
    fontSize: 14,
  },
}));

function Gestor({ user: { name, _id }, businessUnitLocal, count }) {
  const classes = useStyles();
  const theme = useTheme();
  const [ open, setOpen ] = useState(false)
  const { t } = useTranslation();

  function handleOpenBul() {
    setOpen(!open)
  }


  return (
    <div className={classes.line}>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.lineGroup}>
            <div
              onClick={() => history.push(`/profile/${_id}`)}
              style={{ fontSize: 14, cursor: 'pointer' }} 
              title={name}
            >
              {truncate(name, 25)}
            </div>
          </div>
        </Grid>
        <Grid container item xs={6}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <img src={batchPrediction} className={classes.icon} alt="batch" />
                <Typography component={"p"} className={classes.text}>
                  {count}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                <Typography component="p" style={{ color: theme.palette.neutrals.low.light, fontSize: 14 }}>ideias avaliadas</Typography>
              </Box>
             
            </Grid>
            <Grid item xs={1}>
              <Box cursor="pointer" onClick={handleOpenBul}>
                { open ? (
                  <KeyboardArrowUpIcon  className={classes.chevron} />
                ) : (
                  <KeyboardArrowDown  className={classes.chevron} />
                ) }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        { open && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    { t("unidade_de_negocio") }
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    { businessUnitLocal?.businessUnit?.name }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    { t("local") }
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    { businessUnitLocal?.local?.name }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    { t("area_de_atuacao") }
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    { businessUnitLocal?.occupationArea?.name }
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

function TopGestores({ topManagers, loadingTopManagers }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDownloadCsv() {
    const filters = dashboardService.getFilters();
    filters.delimiter = ";";
    filters.reportType = "top-managers";

    dispatch(dashboardActions.getTopFiveAreas(filters));
  }

  return (
      <Card elevation={0} className={classes.card}>
        {loadingTopManagers ? (
          <SkeletonDashboardTops />
        ) : (
          <>
            <Box className={classes.box}>
              <Typography className={classes.title}>top 10 gestores</Typography>
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                MenuListProps={{ disablePadding: true }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Button
                  variant="contained" 
                  startIcon={<GetAppIcon />}
                  className={classes.buttonCsv}
                  onClick={handleClickDownloadCsv}
                >
                  exportar para csv
                </Button>
              </Menu>
            </Box>

            {topManagers.map((user, index) => (
              <Gestor {...user} key={index} />
            ))}
          </>
        )}
      </Card>
  );
}

export { TopGestores };

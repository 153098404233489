import React, { useState, useEffect, Fragment } from 'react'
import { ReportLayout } from "./../../components"
import { Tabs, Tab, makeStyles, TableCell, Box, Typography } from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { TablePanel } from "./../../components"
import { TopManager } from "./../../components/TopManager"
import { GraphicPanel } from "./../../components"
import { useSelector, useDispatch } from "react-redux";
import { tableCss } from './../../components/Tables/TableCss';
import { useTranslation } from 'react-i18next';
import { reportActions } from "./../../actions"
import { CustomTooltip } from '../../components/Shared';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
  buttonChart: {
    marginRight: 16
  },
  chartBox: {
    padding: "0 120px"
  }
}));

export const ReportManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { report, chart, tab } = useSelector(state => state.report)
  const tableClasses = tableCss();
  const { t } = useTranslation();

  function handleView(id) {
    history.push(`/profile/${id}`);
  }

  const tableInfo = {
    columns: [
      { id: 'manager', label: t('gestor') },
      { id: 'email', label: t('email') },
      { id: 'businessUnit', label: t('unidade_de_negocio') },
      { id: 'local', label: t('local') },
      { id: 'occupationArea', label: t('area_de_atuacao') },
    ],
    data: report.map(item => (
      <Fragment>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip>
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.email}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.bul.businessUnit?.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.bul.local?.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>{item.bul.occupationArea?.name}</TableCell>
      </Fragment>
    )),
  };

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    };
  }, [dispatch]);

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index);
  };

  return (
    <ReportLayout title={t('lista_de_gestores')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      {tab === "table" && (
        <TablePanel tableInfo={tableInfo} />
      )}

      {tab === "graph" && (
        <GraphicPanel>
            <Box className={classes.chartBox}>
                {chart.sort((a, b) => b.count - a.count).map((item, index) => (
                <TopManager
                    key={index}
                    pointsCalc={item.count}
                    {...item}
                />
                ))}
            </Box>
        </GraphicPanel>
      )}
    </ReportLayout>
  )
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import {
  BackNavAdmin,
  DashboardIdeaTable,
  FiltersDashboard,
  IdeaForChallengeDashboard,
  InfoDashboard,
  InfoUniqueDashboard,
  MonthlyEvolutionDashboard,
  TopAreas,
  TopAreasGenerateIdeas,
  TopColaboradores,
  TopGestores
} from '../../components';
import { 
  beforeMonth, 
  formatDate, 
  today, 
  formatDateMonthYear, 
  formatDateMouthsEquals
} from '../../utils/date';
import { dashboardService } from '../../services/dashboard.service';
import { NumberFormatCustom } from '../../helpers/number-format-custom';
import { useTranslation } from 'react-i18next';
import { dashboardActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  media: {
    height: 246,
    backgroundColor: theme.palette.neutrals.low.light,
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { 
          csvBase64,
          topUsers,
          loaders: { loadingTopUsers },
          sortUsers
        } = useSelector(state => state.dashboard);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    statusIdeaCode: [],
    statusChallengeCode: [],
    businessUnits: [],
    locals: [],
    occupationArea: [],
    endDate: formatDate(today),
    startDate: formatDate(beforeMonth),
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const [indicators, setIndicators] = useState([]);
  const [ideasStatus, setIdeasStatus] = useState([]);
  const [ideasMonth, setIdeasMonth] = useState([]);
  const [topAreas, setTopAreas] = useState([]);
  const [topManagers, setTopManagers] = useState([]);
  const [topAreasGenerateIdeas, setTopAreasGenerateIdeas] = useState([]);
  const [ideasList, setIdeasList] = useState();

  // loadings
  const [indicatorsLoading, setIndicatorsLoading] = useState(false);
  const [loadingIdeasStatus, setLoadingIdeasStatus] = useState(false);
  const [loadingIdeasMonth, setLoadingIdeasMonth] = useState(false);
  const [loadingTopAreas, setLoadingTopAreas] = useState(false);
  const [loadingTopManagers, setLoadingTopManagers] = useState(false);
  const [loadingTopAreasGenerateIdeas, setLoadingTopAreasGenerateIdeas] = useState(false);


  const getServices = (filter) => {
    setIndicatorsLoading(true);
    setLoadingIdeasStatus(true);
    setLoadingIdeasMonth(true);
    setLoadingTopAreas(true);
    setLoadingTopManagers(true);
    setLoadingTopAreasGenerateIdeas(true);
    dashboardService.setFilters(filter);

    dashboardService
      .getInicators(filter)
      .then((response) => {
        setIndicators(response.data);
        setIndicatorsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIndicatorsLoading(false);
      });

    dashboardService
      .getTopAreas(filter)
      .then((response) => {
        setTopAreas(response.data.areas);
        setLoadingTopAreas(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopAreas(false);
      });

    dashboardService
      .getTopAreasGenerateIdeas(filter)
      .then((response) => {
        setTopAreasGenerateIdeas(response.data.areas);
        setLoadingTopAreasGenerateIdeas(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopAreasGenerateIdeas(false);
      });

    dashboardService
      .getTopManagers(filter)
      .then((response) => {
        setTopManagers(response.data.managers);
        setLoadingTopManagers(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopManagers(false);
      });

    dashboardService
      .getIdeasStatus(filter)
      .then((response) => {
        setIdeasStatus(response.data.status);
        setLoadingIdeasStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeasStatus(false);
      });

    dashboardService
      .getIdeasMonth({
        ...filter,
        endDate: formatDateMonthYear(filter.endDate),
        startDate: formatDateMouthsEquals(filter.startDate, filter.endDate)
      })
      .then((response) => {
        setIdeasMonth(response);
        setLoadingIdeasMonth(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeasMonth(false);
      });
  };

  const getIdeasList = (filter) => {
    setLoading(true)
    filter.sort ='{ "createdAt": -1 }';
    dashboardService
      .getIdeasList({ ...filter, page, limit })
      .then((response) => {
        setLoading(false)
        setIdeasList(response);
      })
      .catch((error) => {
        setLoading(true)
        console.log(error)
      });
  };

  useEffect(() => {
    getServices(filters);
    dispatch(dashboardActions.getUsers(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
      dispatch(dashboardActions.getUsers({ ...filters, sort: sortUsers}));
  }, [sortUsers]);

  useEffect(() => {
    getIdeasList({ ...filters, page, limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filters]);

  useEffect(() => {
    if (csvBase64) {
      const linkSource = `data:text/csv;base64,${csvBase64.substring(csvBase64.indexOf(";") + 1)}`;
      const downloadLink = document.createElement("a");
      const fileName = "Reports";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [csvBase64]);
  const theme = useTheme();

  const handleChangePage = (value) => {
    setPage(value);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
  };

  const handleFilterChange = (filter) => {
    setFilters({ ...filter });
    setPage(1);
    setLimit(10);
    dashboardService.setFilters({ ...filter });
  };

  return (
    <>
      <BackNavAdmin title="dashboard" />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FiltersDashboard onFilterChange={handleFilterChange} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InfoDashboard
              {...indicators}
              indicatorsLoading={indicatorsLoading}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InfoUniqueDashboard
              label={t('retorno_financeiro')}
              color={theme.palette.auxiliary.green.light}
              indicatorsLoading={indicatorsLoading}
              totalCosts={indicators.totalCosts}
              roi={indicators.roi}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <IdeaForChallengeDashboard
              ideasStatus={ideasStatus}
              loadingIdeasStatus={loadingIdeasStatus}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <MonthlyEvolutionDashboard
              ideasMonth={ideasMonth}
              loadingIdeasMonth={loadingIdeasMonth}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopColaboradores
              topUsers={topUsers}
              loadingTopUsers={loadingTopUsers}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopGestores
              topManagers={topManagers}
              loadingTopManagers={loadingTopManagers}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopAreas 
              topAreas={topAreas} 
              loadingTopAreas={loadingTopAreas} 
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopAreasGenerateIdeas
              topAreasGenerateIdeas={topAreasGenerateIdeas}
              loadingTopAreasGenerateIdeas={loadingTopAreasGenerateIdeas}
            />
          </Grid>

          <Grid item xs={12}>
            {ideasList && (
              <DashboardIdeaTable
                ideas={ideasList.data}
                paging={ideasList.paging}
                onChangePage={handleChangePage}
                onChangeLimit={handleChangeLimit}
                loading={loading}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export { DashboardPage };

import { userConstants } from '../constants';
import update from 'react-addons-update';

const lsUser = JSON.parse(localStorage.getItem('user'));
const initialState = lsUser ? lsUser : {
  loading: false
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return state;

    case userConstants.GET_USER_SUCCESS:
      return action.user;

    case userConstants.GET_USER_FAILURE:
      return {};

    case userConstants.UPDATE_USER_REQUEST:
      return { 
        ...state,
        loading: true
      };

    case userConstants.UPDATE_USER_SUCCESS: 
      return {
        ...state,
        loading: false,
      }
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case userConstants.UPLOAD_USER_PHOTO_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case userConstants.UPLOAD_USER_PHOTO_SUCCESS:
      return update(state, {
        loading: { $set: false },
      });

    case userConstants.UPLOAD_USER_PHOTO_FAILURE:
      return state;

    case userConstants.DELETE_USER_PHOTO_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case userConstants.DELETE_USER_PHOTO_SUCCESS:
      return update(state, {
        loading: { $set: false },
      });

    case userConstants.DELETE_USER_PHOTO_FAILURE:
      return state;

    case userConstants.GET_USER_RANKING_DETAILS_SUCCESS:
      return update(state, {
        details: { $set: action.payload.details },
      });

    case userConstants.GET_USER_RANKING_DETAILS_FAILURE:
      return state;

    default:
      return state;
  }
}

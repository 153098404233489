import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';
import { authHeader } from '../helpers/auth-headers';
import axios from 'axios';

export const userService = {
  getUser,
  uploadPhoto,
  updateUser,
  updateUserAdmin,
  searchContribuitor,
  userDetails,
  deletePhoto,
  getUserById,
  passwordRecover,
  confirmPasswordRecover,
  sendRequestNewPasswor
};

function getUser() {
  return axios
    .get(`${config.URL_BASE}/user`, requestHeaders())
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));

      return user;
    });
}

function updateUser(user) {
  return axios.put(
    `${config.URL_BASE}/user/edit-profile`,
    user,
    requestHeaders()
  );
}
function updateUserAdmin(user) {
  return axios.put(
    `${config.URL_BASE}/user/edit-profile-admin`,
    user,
    requestHeaders()
  );
}

function uploadPhoto(file, id) {
  const data = new FormData();

  data.append('file', file);

  return axios.post(
    `${config.URL_BASE}/uploads/users/${id}`,
    data,
    requestHeaders()
  );
}

function deletePhoto(user) {
  return axios.put(
    `${config.URL_BASE}/user/delete-photo`,
    user,
    requestHeaders()
  );
}

function searchContribuitor(name) {
  return axios
    .get(
      `${config.URL_BASE}/user/find-by-name?name=${name}&enrolment=${name}`,
      requestHeaders()
    )
    .then((response) => response.data);
}

function userDetails() {
  return axios
    .get(`${config.URL_BASE}/timeline/user-details`, requestHeaders())
    .then((response) => response.data.data);
}

function getUserById(id) {
  return axios
    .get(`${config.URL_BASE}/user/${id}/public`, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}
function passwordRecover(email) {
  return axios.post(
    `${config.URL_BASE}/recover-password/recover-code-email`, {email})
  .then((token) => {
    localStorage.setItem('token', JSON.stringify(token));
    return token;
  })
}
function confirmPasswordRecover(code, password, confirmPassword) {
  return axios.post(
    `${config.URL_BASE}/recover-password/confirm-password-recover`, 
    {code, password, confirmPassword }
  )
}
function sendRequestNewPasswor(email) {
  return axios.post(`${config.URL_BASE}/recover-password/recover-code-email`, { email }, requestHeaders())
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { dateFormat, history } from '../../helpers';
import { truncate } from 'lodash';
import { CardActionArea } from '@material-ui/core';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formatUTCHours from '../../helpers/format-utc-hours';

const useStyles = makeStyles((theme) => ({
  root: {
  borderRadius: '10px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#2F2F2F',
  },
  description: {
    color: '#2F2F2F',
    fontSize: 13,
  },
  media: {
    backgroundColor: '#E9E9E9',
    paddingTop: '56.25%',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #E9E9E9',
    padding: theme.spacing(2),
  },
  information: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  containerMedia: {
    position: 'relative',
  },
  status: {
    position: 'absolute',
    left: 16,
    top: 16,
    background: '#FFF3F5',
    padding: theme.spacing(0.4, 0.8),
    fontSize: 11.24,
    color: ({ status }) => status,
    borderRadius: 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  icon: {
    color: '#ABABAB',
  },
  containerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: 16,
    marginRight: 20,
  },
  infoText: {
    color: '#585858',
    fontSize: 12,
    fontWeight: 600,
  },
  infoDate: {
    color: '#434343',
    fontSize: 13,
  },
}));

function colorLabelChallenge(status) {
  switch (status) {
    case 'encerrado':
      return '#FF1744';
    case 'submetido':
      return '#448AFF';
    case 'programado':
      return '#FF5722';
    case 'em andamento':
      return '#00C766';
    default:
      return 'black';
  }
}

function ChallengeCard({ challenge }) {
  const { t } = useTranslation();
  const status = colorLabelChallenge(
    challenge.statusChallenge?.name.toLowerCase()
  );
  const classes = useStyles({ status });
  const [shadow, setShadow] = useState(false);
  const language = useSelector((state) => state.language);

  function goToDetail() {
    history.push(`/challenge/detail/${challenge._id}`);
  }

  const startDate = formatUTCHours(challenge.startDate);
  const dueDate = formatUTCHours(challenge.dueDate);
  const urlImage = challenge?.thumbnail?.url ? challenge?.thumbnail?.url : challenge?.attachment?.url
  return (
    <Card
      className={classes.root}
      raised={shadow}
      onMouseOver={() => setShadow(true)}
      onMouseOut={() => setShadow(false)}
    >
      <CardActionArea style={{ padding: 0 }} onClick={goToDetail}>
        <div className={classes.containerMedia}>
          {urlImage ? (
            <CardMedia
              className={classes.media}
              image={urlImage}
            />
          ) : (
            <CardMedia className={classes.media} image={imageCard} />
          )}

          <div className={classes.status}>{challenge.statusChallenge?.name}</div>
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {truncate(challenge.name, { length: 20, separator: '...' })}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}
          >
            {truncate(challenge.description, { length: 80, separator: '...' })}
          </Typography>
          <div className={classes.information}>
            <EventIcon className={classes.icon} />
            <div className={classes.containerInfo}>
              <div>
                <div className={classes.infoText}>{t('inicio')}</div>
                <div className={classes.infoDate}>
                  {dateFormat(startDate, language)}
                </div>
              </div>
              <div>
                <div className={classes.infoText}>{t('termino')}</div>
                <div className={classes.infoDate}>
                  {dateFormat(dueDate, language)}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.information}>
            <EmojiEventsIcon className={classes.icon} />
            <div className={classes.containerInfo}>
              <div>
                <div className={classes.infoText}>{t('premiacao')}</div>
                <div className={classes.infoDate}>
                  {challenge?.rewards && truncate(challenge?.rewards[0]?.description, {
                    length: 24,
                    separator: '...',
                  })}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GroupIcon color="primary"></GroupIcon>
          <div style={{ marginLeft: 6 }}>
            <span style={{ color: 'black', fontWeight: 600, fontSize: 18 }}>
              {challenge.subscribers || 0}
            </span>{' '}
            <span style={{ color: '#2F2F2F', fontSize: 13 }}>
              {t('ideias').toLowerCase()}
            </span>
          </div>
        </div>
      </CardActions>
    </Card>
  );
}

export { ChallengeCard };

import { externalContentService } from '../services';
import { externalContentsConstants } from '../constants'

import { alertActions } from './alert.actions'

import { history } from '../helpers';
import { imageUploaderActions } from './imageUploader.actions';
import { imageUploaderConstants } from '../constants/imageUploader.constants';

export const externalContentActions = {
  getExternalContentList,
  getExternalContentById,
  createContent,
  editContent,
  toggleFilter,
  deleteContent,
  resetExternalContent,
  setNewContent,
  setNewContentSwitch,
  uploadContentImage
}

function setLoading(state) {
  return {
    type: externalContentsConstants.SET_EXTERNAL_CONTENT_LOADING,
    payload: state,
  };
}

function getExternalContentList(
  page = 1,
  query,
  limit,
  statusList = []
) {
  return dispatch => {
    dispatch({
      type: externalContentsConstants.GET_EXTERNAL_CONTENT_LIST_REQUEST
    });

    externalContentService.getExternalContentList(
      page,
      query,
      limit,
      statusList
    )
      .then((result) => {
        if (result && result.data) {
          dispatch({
            type: externalContentsConstants.GET_EXTERNAL_CONTENT_LIST_SUCCESS,
            payload: result
          })
        }
        dispatch(clearContentFields());
      })
  }
}

function getExternalContentById(id) {
  return dispatch => {
    dispatch(setLoading(true));

    externalContentService.getExternalContentById(id)
      .then(({ data, data: { attachment } }) => {
        if(!!attachment?.url?.length) {
          dispatch(imageUploaderActions.setImage({
            id: attachment._id,
            name: attachment.fileName,
            file: {},
            readableSize: attachment.size,
            preview: attachment.url,
            type: attachment.mimeType.mimeType
          }));
        }
        dispatch({
          type: externalContentsConstants.SET_EXTERNAL_CONTENT_DATA,
          payload: data
        });

        dispatch(setLoading(false));
      })
  }
}

function createContent(data, image) {
  return dispatch => {
    dispatch(setLoading(true));

    externalContentService.createContent(data)
      .then(
        ({ data: { _id } }) => {
          if (!!image.file && _id) {
            dispatch(uploadContentImage(image.file, _id));
          };
          dispatch(setLoading(false));
          dispatch(alertActions.success('Novo conteúdo salvo com sucesso'));
          history.push('/external-contents');
          dispatch(clearContentFields());
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  }
}

function editContent(data, id, image) {
  return dispatch => {
    dispatch(setLoading(true));

    externalContentService.editContent(data, id)
      .then(
        () => {
          if (!!image.file && id) {
            dispatch(uploadContentImage(image.file, id));
          };
          dispatch(setLoading(false));
          dispatch(alertActions.success('Seu conteúdo foi alterado com sucesso!'));
          history.push('/external-contents');
          dispatch(clearContentFields());
        },
        (error) => {
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors.indexOf('Já existe um conteúdo fixado') >= 0) {
              dispatch(alertActions.error(
                'Já existe um conteúdo fixado, é necessário desafixar este conteúdo para fixar um novo'
              ))
            } else {
              dispatch(alertActions.error(error.response.data.errors));
            }
          }
        }
      )
  }
}

function deleteContent(id, successMessage) {
    return (dispatch) => {
        externalContentService.deleteContent(id).then(
            () => {
                dispatch(getExternalContentList());
                dispatch(alertActions.success(successMessage));
                history.push('/external-contents');
            },
            (error) => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
}

function toggleFilter(statusCode) {
    return (dispatch) => {
        dispatch({
            type: externalContentsConstants.TOGGLE_FILTER,
            payload: statusCode
        })
    };
}

function resetExternalContent() {
  return dispatch => {
    dispatch({
      type: externalContentsConstants.SET_EXTERNAL_CONTENT_DATA,
      payload: {}
    })
  }
}

function setNewContent({ name, value }) {
  return (dispatch) => {
    dispatch({ type: externalContentsConstants.SET_NEW_CONTENT, payload: { name, value } });
  }
}

function setNewContentSwitch({ name, checked }) {
  return (dispatch) => {
    dispatch({ type: externalContentsConstants.SET_NEW_CONTENT_SWITCH, payload: { name, checked } });
  }
}

function clearContentFields() {
  return (dispatch) => {
    dispatch({ type: externalContentsConstants.CLEAR_CONTENT_FIELDS });
    dispatch(imageUploaderActions.clearImageUploader());
  }
}

function uploadContentImage(image, id) {
  return (dispatch) => {
    dispatch({ type: imageUploaderConstants.REQUEST_IMAGE_UPLOAD });
    externalContentService.postContentImage(image, id).then(() => dispatch({ type: imageUploaderConstants.SUCCESS_IMAGE_UPLOAD })).catch((err) => {
      console.log(err);
      dispatch({ type: imageUploaderConstants.FAILURE_IMAGE_UPLOAD });
    })
  };
};
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
//import { userCustomProfile } from './mock';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    fontWeight: 600,
  }
}));

export function CustomProfile({ handleChange, value }) {
  const classes = useStyles();
  const { userCustomProfile } = useSelector((state) => state.domains);

  return (
    <FormControl variant='filled' className={classes.formControl}>
      <TextField
        select
        id="userCustomProfile"
        name="userCustomProfile"
        label="perfil customizado"
        variant="filled"
        type="text"
        defaultValue={value}
        onChange={handleChange}
        InputLabelProps={{
          classes: {
            root: classes.title,
          },
        }}
      >
        {userCustomProfile && userCustomProfile.map((userCustomProfiles, index) => (
          <MenuItem key={index} value={userCustomProfiles._id}>
            {userCustomProfiles.description}
          </MenuItem>
        ))} 
      </TextField>
    </FormControl>
  );
}
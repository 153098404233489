import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, TableCell, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import config from '../../config';
import { crudTemplateActions } from '../../actions';
import { useWindowSize } from '../../hooks';
import { dateFormat, truncate } from '../../helpers';
import { TemplateCrudTable } from '../../components/Tables';
import { tableCss } from './../../components/Tables/TableCss';
import { TemplateCrudCards } from '../../components/TemplateCrud';

export function QuestionTable() {
  const tableClasses = tableCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const [itensCard, setItensCard] = useState({
    cardContent: [],
    dialogContent: []
  });
  const { language } = useSelector(state => state.language);
  const { table } = useSelector(state => state.crudTemplate);


  function handleEditButton({_id, title, description}) {
    dispatch(crudTemplateActions.setIdToUpdate(_id));
    dispatch(crudTemplateActions.setTypePage('crud-edit'));
    dispatch(crudTemplateActions.setItem({ name: 'title', value: title }));
    dispatch(crudTemplateActions.setItem({ name: 'description', value: description }));
  };

  function handleDeleteButton({ _id }) {
    dispatch(crudTemplateActions.setIdToUpdate(_id));
    dispatch(crudTemplateActions.setOpenDeleteDialog(true));
  };

  const tableInfo = {
    columns: [
      { id: 'date', label: t('data') },
      { id: 'title', label: t('titulo')},
      { id: 'description', label: t('descricao')},
    ],
    data: table && table.map(({ _id, title, description, createdAt }) => (
      <>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          {dateFormat(createdAt, language) || ""}
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>
          {truncate(title, 30) || ""}
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>
          {truncate(description, 40) || ""}
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={() => handleEditButton({ _id, title, description })}>
              <EditIcon />
            </IconButton>
            <IconButton 
              onClick={() => handleDeleteButton({ _id })}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </>
    )),
  }

  useEffect(() => {
    table && setItensCard({
      cardContent: table.map(item => (
        [
          {
            name: t('data'),
            value: dateFormat(item.createdAt, language),
          },
          {
            name: t('titulo'),
            value: item.title,
          },
          {
            name: t('descricao'),
            value: item.description,
          },
        ]
      )),
      tableContent: table.map(item => (
        [
          {
            name: t('data'),
            value: dateFormat(item.createdAt, language),
          },
          {
            name: t('titulo'),
            value: truncate(item.title, 30),
          },
          {
            name: t('descricao'),
            value: truncate(item.description, 40),
          },
        ]
      ))
    })
  }, [table]);

  return (
    table && width > config.RESPONSIVE_MOBILE ?
      <TemplateCrudTable tableInfo={tableInfo} /> :
      <TemplateCrudCards itensCard={itensCard} />
  );
};

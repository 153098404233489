import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, fade } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    position: 'relative',
  },
  image: {
    height: '100px !important',
    width: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: 20,
  },
  grid: {
    marginLeft: 2,
    marginRight: 2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '32.6%',
    height: '100px !important',
    backgroundColor: fade(theme.palette.neutrals.low.main, 0.6),
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 20,
    marginLeft: 2,
    marginRight: 2,
  },
  overlayText: {
    fontSize: 26,
    fontWeight: 'bold',
  },
}));

function FbGridImage(props) {
  const classes = useStyles();
  const { images } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const modals = images.map((image) => image.url);

  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementsByClassName('ReactModal__Overlay');
      if (container.length > 0) {
        container[0].style.zIndex = '99999';
      }
    }, 100);
  });

  function getGrid() {
    switch (images.length) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        return 4;
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.container}>
        {images.map((image, index) => {
          if (index < 3) {
            return (
              <Grid
                item
                key={index}
                xs={getGrid()}
                className={classes.grid}
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpenModal(true);
                }}
              >
                <img src={image.url} alt="imagem" className={classes.image} />
              </Grid>
            );
          } else {
            return null;
          }
        })}
        {images.length > 3 && (
          <div
            className={classes.overlay}
            onClick={() => {
              setPhotoIndex(2);
              setIsOpenModal(true);
            }}
          >
            <div className={classes.overlayText}>+{images.length - 3}</div>
          </div>
        )}
      </Grid>
      {isOpenModal && (
        <Lightbox
          // style={{ zIndex: 1200 }}
          mainSrc={modals[photoIndex]}
          nextSrc={modals[(photoIndex + 1) % modals.length]}
          prevSrc={modals[(photoIndex + modals.length - 1) % modals.length]}
          onCloseRequest={() => setIsOpenModal(false)}
          // reactModalStyle={{ zIndex: 1200 }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + modals.length - 1) % modals.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % modals.length)
          }
        />
      )}
    </div>
  );
}

export { FbGridImage };

import React from 'react';
import {
  Grid,
  makeStyles,
  useTheme,
  withStyles
} from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useTranslation } from 'react-i18next';

const BorderLinearProgess = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: 'white',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const TopManagerStyles = makeStyles((theme) => ({
  container: {
    padding: '12px 0',
  },
  name: {
    fontSize: 14,
    color: theme.palette.text.primary
  }
}));

const TopManager = ({ name, pointsCalc, count }) => {
  const classes = TopManagerStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={4}>
        <div className={classes.name}>
          {name}
        </div>
      </Grid>
      <Grid item xs={6} >
        <BorderLinearProgess
          variant="determinate"
          value={pointsCalc}
          style={{ marginTop: 4 }}
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'end' }}>
        <span style={{ fontSize: 14, color: theme.palette.neutrals.low.light }}>
          { count } {t('gestores').toLowerCase()}
        </span>
      </Grid>
    </Grid>
  );
};

export { TopManager };

import { faqConstants } from '../constants';
import { faqService } from '../services';

export const faqActions = {
  getAllQuestions,
  getQuestionById,
  deleteQuestion,
  setPage,
  setLimit,
};

function getAllQuestions(page, sort, limit) {
  return (dispatch) => {
    dispatch({ type: faqConstants.GET_ALL_QUESTIONS_REQUEST });
    faqService.getAllQuestions(page, sort, limit).then((questions) => {
      dispatch({ 
        type: faqConstants.GET_ALL_QUESTIONS_SUCCESS, 
        payload : {
          questions,
          page,
      }
      })
    }).catch((error) => {
      dispatch({ type: faqConstants.GET_ALL_QUESTIONS_FAILURE });
    })
  };
}

function getQuestionById(id){
  return (dispatch) => {
    dispatch({ type: faqConstants.GET_QUESTION_BY_ID_REQUEST });
    faqService.getQuestionById(id).then((questions) => {
      dispatch({ 
        type: faqConstants.GET_QUESTION_BY_ID_SUCCESS,
        payload: questions.data
       });
    }).catch((error) => {
      dispatch({ type: faqConstants.GET_QUESTION_BY_ID_FAILURE });
    })
  };
  }

function deleteQuestion(id) {
  return (dispatch) => {
    faqService.deleteQuestion(id).then(() => {
      dispatch({ type: faqConstants.DELETE_QUESTION_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: faqConstants.DELETE_QUESTION_FAILURE });
    });
    };
  }

function setPage(page) {
  const payload = { page };
  return { type: faqConstants.SET_PAGE, payload };
  }
function setLimit(limit) {
  const payload = { limit };
  return { type: faqConstants.SET_LIMIT, payload };
}
import React from 'react'
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@material-ui/core"
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import { history } from "../../helpers";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Copyright } from '../Shared';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  sidebarIcon: {
    minWidth: "auto",
    paddingRight: 8,
  },
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export const SidebarManageAccess = () => {
  const classes = useStyles();
  const location = useLocation();
  const { selectedUser } = useSelector(state => state.userFunction)
  const { typeProfile } = useSelector(state => state.user)
  const { t } = useTranslation();

  return (
    <>
      <List style={{ padding: 0 }}>
        { selectedUser?.id !== undefined ? (
          <ListItem 
            button 
            onClick={() => history.replace(`/users/function/${selectedUser?.id}`)}
            className={location.pathname.split("/")[2] === "function" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <DescriptionIcon color={location.pathname.split("/")[2] === "function" ? 'primary' : 'inherit'}/>
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={location.pathname.split("/")[2] === "function" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('atribuir_funcao_ao_usuario')}
            </Typography>} />
          </ListItem>
        ) : <Skeleton height={50} width={'100%'} />}
        { typeProfile.type === "admin" && selectedUser?.id !== undefined ? (
          <ListItem 
            button 
            onClick={() => history.replace(`/users/edit/${selectedUser?.id}`)}
            className={location.pathname.split("/")[2] === "edit" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <EditIcon color={location.pathname.split("/")[2] === "edit" ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={location.pathname.split("/")[2] === "edit" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('editar_dados_do_usuario')}
            </Typography>} />
          </ListItem>
        ) : <Skeleton height={50} width={'100%'} />}
        { typeProfile.type === "admin" && selectedUser?.id !== undefined ? (
          <ListItem 
            button 
            onClick={() => history.replace(`/users/points/${selectedUser?.id}`)}
            className={location.pathname.split("/")[2] === "points" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <AccountBalanceWalletIcon color={location.pathname.split("/")[2] === "points" ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={location.pathname.split("/")[2] === "points" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('gerenciar_pontos')}
            </Typography>} />
          </ListItem>
        ): <Skeleton height={50} width={'100%'} />}
      </List>
      <Divider style={{ marginTop: 16 }}/>
      <Copyright />
    </>
  )
}

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'; 
import { useDispatch, useSelector } from 'react-redux';
import { ideaActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../Shared/LoadingButton';
import { MenuInformation } from '../Shared/MenuInformation';
import {
  Box,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  makeStyles,
  CardContent,  
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import RateReviewIcon from '@material-ui/icons/RateReview';

import config from '../../config';

const filterOptions = createFilterOptions({
  limit: 100,
});


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.neutrals.high.medium
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: theme.spacing(5)
  },
  iconBackground: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qualificationIcon: {
    width: 22,
  },
  content: {
    position: 'relative'
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  buttons: {
    display: 'flex',
    alingItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  },
  formControl: {
    paddingBottom: theme.spacing(2)
  },
  qualificationTypeTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    paddingBottom: theme.spacing(1)
  },
  menuItem: {
    '& .MuiMenuItem-root': {
      paddingTop: theme.spacing(4),
      backgroundColor: '#000'
    },
  },
  radioGroupLabel: {
    marginBottom: "0.5rem",
    fontWeight: 600,
    color: "#343A40"
  }
}));
 
function IdeaQualificationAdmin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
   
  const [newPersonalizedClassificationChosed, setNewPersonalizedClassificationChosed] = useState([]);
  
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);

  const { ideaDetails, approved } = useSelector((state) => state.idea);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    personalizedClassification.forEach((item) => setOptions(prev => [...prev, {
      value: item.name,
      id: item._id,
      label: item.name,
      checked: ideaDetails?.personalizedClassifications?.some(itemAlredyCheked => item._id === itemAlredyCheked._id)
    }]));
  }, [personalizedClassification, ideaDetails]);
 

  useEffect(() => {
    if (approved !== null) {
      dispatch(ideaActions.sendApproved(approved, t('sua_avaliacao_foi_feita_com_sucesso')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    dispatch(ideaActions.setIdApproved(ideaDetails._id));

    return () => dispatch(ideaActions.clearApproved());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    return () => dispatch(ideaActions.setPersonalizedClassificationIdeiaDetails(newPersonalizedClassificationChosed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  function handleEvaluation(e) {
    dispatch(ideaActions.setEvaluation(e.target.value));
  }
  function handleQualification(e) {
    dispatch(ideaActions.setQualification(e.target.value))
  }

  function sendApproved() {
    if (approved.evaluation.length > 0 && approved.qualification) {
      if (approved.fieldsErrors) {
        dispatch(ideaActions.setQualificationFieldsErrors());
      }
    setLoading(!loading)
    selectedClassification()
    dispatch(ideaActions.setEvaluation(approved.evaluation));
    dispatch(ideaActions.setApproved(approved));

    dispatch(ideaActions.sendApproved({
      ...approved,
      approved: true 
    }, t('sua_avaliacao_foi_feita_com_sucesso')));

    if (!approved) {
      dispatch(ideaActions.clearApproved());

    }
    }
  }


  function handleChange(details) {

    let optionsToChange = [...options];
    if (!details) {
      optionsToChange.forEach((item, index) => {

        optionsToChange[index] = { ...item, checked: false }

      })

      setOptions(optionsToChange);
    } else {
      const indexToChange = options.findIndex(item => item.id === details.option.id)

      optionsToChange.forEach((item, index) => {
        if (index === indexToChange) {
          optionsToChange[indexToChange] = { ...item, checked: !item.checked }

        } else {
          optionsToChange[index] = item
        }
      })

      setOptions(optionsToChange);
    }

  };

  function selectedClassification() {

    const itemsChecked = personalizedClassification.filter((item) => {
      return options.some(itemAlredyCheked => {
        return (itemAlredyCheked.id === item._id && itemAlredyCheked.checked === true)
      })
    })
    setNewPersonalizedClassificationChosed(itemsChecked)

    if (!!itemsChecked) {
      dispatch(ideaActions.setPersonalizedClassification(itemsChecked));
    }
  }
  function handleChange(details) {
    let optionsToChange = [...options];
    if (!details) {
      optionsToChange.forEach((item, index) => {
        optionsToChange[index] = { ...item, checked: false }
      })
      setOptions(optionsToChange);
    } else {
      const indexToChange = options.findIndex(item => item.id === details.option.id)
      optionsToChange.forEach((item, index) => {
        if (index === indexToChange) {
          optionsToChange[indexToChange] = { ...item, checked: !item.checked }
        } else {
          optionsToChange[index] = item
        }
      })
      setOptions(optionsToChange);
    }
  };
 
  function sendRejected() {
    if (approved.evaluation.length > 0) {
      setLoading(!loading)
      dispatch(ideaActions.sendApproved({
        ...approved,
        approved: false
      }, t('sua_avaliacao_foi_feita_com_sucesso')));
    } else {
      dispatch(ideaActions.setQualificationFieldsErrors());
    }
  }

  return (
    <Card className={classes.card} elevation={0}>
      <Typography className={classes.cardTitle}>
        <div className={classes.iconBackground}>
          <ThumbsUpDownIcon color="primary" className={classes.qualificationIcon} />
        </div>
        {t('qualificar_ideias_para_o_feed')}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <CardContent style={{ padding: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl 
                  component="fieldset"
                >
                  <FormLabel required component="legend" className={classes.radioGroupLabel}>tipo de qualificação</FormLabel>
                  <RadioGroup aria-label="qualification" name="qualification" onChange={handleQualification} value={approved.qualification}>
                    <FormControlLabel value="EXECUTION_QUEUE" control={<Radio />}  label="Avaliar Ideia" />
                    <FormControlLabel value="PENDING_REVIEW" control={<Radio />} label={`Solicitar Revisão`} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {config.REACT_PERSONALIZED_CLASSIFICATION_MANAGER && <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterOptions={filterOptions}
                  noOptionsText={t('sem_opcoes')}
                  loadingText={t('carregando')}
                  options={options}
                  loading={options.length === 0}
                  value={options.filter(item => item.checked)}
                  getOptionLabel={(option) => option.label}
                  onChange={(_e, _v, _r, details) => handleChange(details)}
                  renderOption={(option) => (
                    <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={option.checked}
                      />
                      {option.label}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" label={t('classificacao_da_ideia')} />
                  )}
                />}
            <Box className={classes.about}>
              <Box className={classes.characters}>
                    <span>{approved.evaluation.length}/1024 {t('caracteres')}</span>
              </Box>
              <TextField
                    error={approved.fieldsErrors && approved.evaluation.length === 0}
                id="outlined-multiline-static"
                name="evaluation"
                label={t('feedback_da_ideia')}
                multiline
                fullWidth
                rows={8}
                variant="filled"
                    value={approved.evaluation}
                onChange={handleEvaluation}
                    inputRef={{
                  maxLength: 1024,
                  required: t('o_feedback_da_ideia_e_obrigatorio'),
                    }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
              <p className={classes.error}>
                    {
                      approved.evaluation.length >= 1024 &&
                      t('voce_passou_o_limite_de_caracteres')
                    }
              </p>
                <Box className={classes.buttons}>
              {loading ? (
                <LoadingButton />
              ) : (
                <>
                  {approved.qualification !== 'EXECUTION_QUEUE' ? (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<RateReviewIcon />}
                      onClick={sendApproved} 
                    >
                      {t('solicitar_revisao')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: 16 }}
                        startIcon={<ThumbDownIcon color="primary" />}
                        onClick={sendRejected}
                      >
                        {t('desqualificar')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={approved.evaluation.length === 0 || approved.qualification === null} 
                        startIcon={<ThumbUpIcon />}
                        onClick={sendApproved} 
                      >
                        {t('qualificar')}
                      </Button>
                    </>
                  )}
                </>
              )}   
            </Box>
              </Box>
              </Grid>
            </Grid>
        </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export { IdeaQualificationAdmin };
import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { Avatar, makeStyles, fade } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7), //56px
    height: theme.spacing(7),
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
  medium: {
    width: theme.spacing(6), // 48px
    height: theme.spacing(6),
  },
  xlarge: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  superLarge: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
}));

function AvatarWithoutPhoto(props) {
  const classes = useStyles();

  function getSize() {
    if (props.superLarge) {
      return classes.superLarge;
    }

    if (props.large) {
      return classes.large;
    }

    if (props.medium) {
      return classes.medium;
    }

    return {};
  }

  return (
    <Avatar className={clsx(classes.avatar, props.className, getSize())}>
      <PersonIcon className={classes.icon} style={props.iconStyle} />
    </Avatar>
  );
}

export { AvatarWithoutPhoto };

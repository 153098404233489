import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { makeStyles, Container, Grid, Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';

import { infraStructureActions } from './../../actions';
import { TemplateFilter } from '../../components/TemplateFilter';
import { Search, BackNavAdmin, MultiSelectCustom } from '../../components';
import { DialogAddInfra } from '../../components/Infrastructure/DialogAddInfra';
import { InfraestructureTable } from './../../components/Tables/InfraestructureTable'
import { SkeletonTable } from '../../components/Skeletons';

const filters = [
  {
    id: '001',
    type: 'local',
    description: 'local',
  },
  {
    id: '002',
    type: 'occupationArea',
    description: 'área de ocupação',
  },
  {
    id: '003',
    type: 'businessUnit',
    description: 'unidade de negócio'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    float: "right"
  }
}));

export const InfraestructurePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paging, filters: { types }, loading } = useSelector((state) => state.infraStructure);
  const [openDialogAddInfra, setOpenDialogAddInfra] = useState(false);
  const [countFilters, setCountFilters] = useState(0);

  useEffect(() => {
    setCountFilters(types.length);
  }, [types]);

  useEffect(() => {
    dispatch(infraStructureActions.getInfraStructure({ page: 1, limit: 10 }));
  }, []);

  function sendQuery(query) {
    dispatch(infraStructureActions.searchByName({ name: query }));
  }

  function handleOpenDialogAddInfra() {
    setOpenDialogAddInfra(!openDialogAddInfra);
  }
  
  function changeFilter(value) {
    dispatch(infraStructureActions.setInfraStructureFilters(value));
  };

  function handleClearFilter() {
    dispatch(infraStructureActions.clearInfraStructureFilter());
  };

  function sendFilter() {
    dispatch(infraStructureActions.getInfraStructure({ page: 1, limit: 10, types }));
  };

  return (
    <>
      <DialogAddInfra
        open={openDialogAddInfra}
        onClose={handleOpenDialogAddInfra}
      />
      <BackNavAdmin title={t('infraestrutura')}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleOpenDialogAddInfra}
          startIcon={<AddCircle />}
        >
          {t('adicionar_infraestrutura')}
        </Button>
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.filters}>
            <TemplateFilter
              handleClear={handleClearFilter}
              handleSendFilters={sendFilter}
              countFilters={countFilters}
            >
              <MultiSelectCustom
                filters={filters.map(item => ({ id: item.id, value: item.type, name: item.description }))}
                statusCode={types}
                changeFilter={changeFilter}
              />
            </TemplateFilter>
            <Search
              placeholder={t('pesquisar_infraestrutura')}
              value={paging.query}
              query={sendQuery}
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? <SkeletonTable cols={4} /> : <InfraestructureTable />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

import React from 'react';
import { makeStyles, Avatar, Box, Typography } from '@material-ui/core';
import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';
import { useTranslation } from 'react-i18next';
import emptyStateUp from '../../assets/imgs/emptystate-up.svg';

const useStyle = makeStyles((theme) => ({
  root: {
    width: 500,
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(3, 0),
  },
  grow: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1.5)
  },
  container: {
    paddingBottom: theme.spacing(2.3),
    display: 'flex',
  },
  photo: {
    width: 45,
    height: 45,
    marginRight: 15,
  },
  avatar: {
    width: 45,
    height: 45,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  user: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 600,
  },
  position: {
    color: theme.palette.neutrals.low.light,
    fontSize: 12.2,
    fontWeight: 500,
  },
  emptyStateUp: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  emptyStateImg: {
    width: 96,
    height: 96,
    marginBottom: theme.spacing(2.5)
  },
  emptyStateDescription: {
    textAlign: 'center',
    fontSize: 16
  }
}));

function IdeaUpContent({ ups }) {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {ups <= 0 ? (
        <div className={classes.emptyStateUp}>
          <img 
            className={classes.emptyStateImg} 
            src={emptyStateUp} 
            alt="Empty State Up" 
          />
          <Typography className={classes.emptyStateDescription}>
            {t('ainda_nao_ha_ups')} <br />
            {t('na_publicacao')}
          </Typography>
        </div>
      ) : (
        <div className={classes.grow}>
          {ups &&
            ups.map((ups, index) => (
              <div key={index}>
                <div className={classes.container}>
                  <span className={classes.photo}>
                    {ups.attachment?.url ? (
                      <Avatar 
                        className={classes.avatar} 
                        src={ups.attachment.url} 
                      />
                    ) : (
                      <AvatarWithoutPhoto medium />
                    )}
                  </span>
                  <div className={classes.content}>
                    <span className={classes.user}>
                      {ups.name}
                    </span>
                    <span className={classes.position}>
                      {ups.position}
                    </span>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        )}
    </div>
  );
}

export { IdeaUpContent };

import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { ideaCreateActions } from '../../actions';
import { ideaUpdateActions } from '../../actions/ideaUpdate.actions';

const useStyles = makeStyles(() => ({
  removeButton: {
    top: 0,
    left: 0,
  },
}));

function VideoPreview({ video, isEdit=false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  function removeVideo() {
    if (isEdit) {
    dispatch(ideaUpdateActions.removeVideo(video.id ? video.id : video._id));
    } else {
    dispatch(ideaCreateActions.removeVideo());
  }
  }

  return (
    <div style={{ width: '100%', position: 'relative' }} key={video.id}>
      <IconButton onClick={removeVideo} className={classes.removeButton}>
        <DeleteIcon />
      </IconButton>
      <video style={{ width: '100%' }} height="255" controls>
        <source
          src={video.preview ? video.preview : video.url}
          type={video.type}
        />
        <object
          data={video.preview ? video.preview : video.url}
          width="470"
          height="255"
        >
          <embed
            src={video.preview ? video.preview : video.url}
            width="470"
            height="255"
          />
        </object>
      </video>
    </div>
  );
}

export { VideoPreview };

import { ideaCreateDialogsActions } from '../actions';

function returnPage(dispatch, from) {
  if (from === 'sendIdea') {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
    return;
  }

  if (from === 'DIALOG_SEND_IDEA') {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
    return;
  }

  if (from === 'DIALOG_TITLE_DESCRIPTION') {
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
    return;
  }

  if (from === 'DIALOG_AREA' || from === 'DIALOG_MULTI_AREA') {
    dispatch(
      ideaCreateDialogsActions.openDialogArea(from)
    );
    return;
  }

  if (from === 'DIALOG_CHALLENGE') {
    dispatch(
      ideaCreateDialogsActions.openDialogChallenge(from)
    );
    return;
  }

  
}

export { returnPage };

import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  Menu,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ViewIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import { StatusIdea } from '../Shared';
import { tableCss } from '../Tables/TableCss';
import { dateFormat, truncate } from '../../helpers';
import { dashboardActions } from '../../actions';
import { today, beforeMonth, formatDate } from '../../utils/date';
import { dashboardService } from '../../services/dashboard.service';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SkeletonTable } from "./../../components/Skeletons/SkeletonTable"
import { TableMenu } from '../Tables';
import { history } from '../../helpers';
import { CustomTooltip } from '../Shared';


const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: 10,
    color: theme.palette.grey[400], 
  },
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0, 2),
  },
  buttonCsv: {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: theme.palette.neutrals.high.main
  }
}));

function DashboardIdeaTable({
  ideas,
  paging,
  id,
  userRule,
  onChangePage,
  onChangeLimit,
  loading
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const tableClasses = tableCss({ content: `"${t('linhas_por_pagina')}"` });
  const columns = [
    { id: 'date', label: t('data') },
    { id: 'title', label: t('ideia') },
    { id: 'contributor', label: 'colaborador' },
    { id: 'area', label: 'área' },
    { id: 'engagement', label: t('engajamento') },
    { id: 'comments', label: t('comentarios') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: '' },
  ];

  function handleChangePage(event, page) {
    let newPage = page + 1;
    onChangePage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    onChangeLimit(limit);
  }

 function goToPage(idea) {
    if (userRule === 'manager') {
      switch (idea.statusIdea.code) {
        case 'AWAITING_QUALIFICATION':
          return history.push(`/idea/${idea._id}/qualification`);

        case 'EXECUTION_QUEUE':
          return history.push(`/idea/${idea._id}/execution`);

        case 'EXECUTING':
          return history.push(`/idea/${idea._id}/conclusion`);

        default:
          return history.push(`/idea/${idea._id}/general`);
      }
    } else {
      return history.push(`/idea/${idea._id}/general`);
    }
  };

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDownloadCsv() {
    const filters = dashboardService.getFilters();
    filters.delimiter = ";";
    filters.reportType = "ideas-list";
    filters.page = 1;
    dispatch(dashboardActions.getTopFiveAreas(filters));
    handleClose();
  }


  return (
      <>
      { loading ? (
        <SkeletonTable cols={7} />
      ) : (
        <>
          <Box width='100%' display='flex' justifyContent='space-between'>
            <Typography className={classes.title}>{t('lista_de_ideias')}</Typography>
            <IconButton onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
          </Box>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                MenuListProps={{ disablePadding: true }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Button
                  variant="contained" 
                  startIcon={<GetAppIcon />}
                  className={classes.buttonCsv}
                  onClick={handleClickDownloadCsv}
                >
                  exportar para csv
                </Button>
              </Menu>

            <Paper className={tableClasses.paperRoot}>
              <TableContainer sx={{ position: 'relative' }}>
                <Table
                  stickyHeader
                  aria-label="tabela de ideias"
                  classes={{ root: tableClasses.table }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          classes={{ root: tableClasses.tableCellHeader }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ideas &&
                      ideas.length > 0 &&
                      ideas.map((idea) => {
                        return (
                          <TableRow
                            hover
                            key={idea._id}
                            classes={{ root: tableClasses.tableRow }}
                          style={{ height: '60px'}}
                          >

                            <TableCell
                              classes={{ root: tableClasses.tableCellRadiusLeft }}
                            >
                              {dateFormat(idea.createdAt)}
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                            <CustomTooltip 
                              title={idea.name} 
                              aria-label={idea.name} 
                              placement="bottom-start"
                            >
                              <Typography 
                                variant="subtitle2" 
                                className={tableClasses.link} 
                                onClick={() => goToPage(idea)}
                              >
                              {truncate(idea.name, 20)}
                              </Typography>
                            </CustomTooltip>
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                              {truncate(idea.user?.name, 18)}
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                              {idea.businessUnitLocal &&
                                idea.businessUnitLocal.length > 0 &&
                                truncate(idea?.businessUnitLocal[0]?.local?.name, 15)}
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <ThumbUpIcon className={classes.icon} />{' '}
                                <Box>{idea.upsLength || 0} {t('up')}s</Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <ChatBubbleIcon className={classes.icon} />{' '}
                                <Box>{idea.commentsLength || 0} {t('comentarios')}</Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                            classes={{ root: tableClasses.tableCellRadiusRight }}
                            >
                              {idea.statusIdea?.name && (
                                <StatusIdea status={idea.statusIdea} />
                              )}
                            </TableCell>
                            <TableMenu>
                              <List>
                                <ListItem onClick={() => goToPage(idea)} button>
                                  <ListItemIcon>
                                    <ViewIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={<Typography>
                                      {t('visualizar')}
                                    </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </TableMenu>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="Box"
                classes={{
                  input: tableClasses.paginationInput,
                  spacer: tableClasses.paginationSpacer,
                  caption: tableClasses.paginationCaption,
                  toolbar: tableClasses.paginationToolbar,
                }}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                count={paging.total}
                rowsPerPage={paging.limit}
                page={paging.page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('exibindo')}
                style={{zIndex: 10, position: 'relative'}}
              />
            </Paper>
        </>
       )}
    </>
  );
}

export { DashboardIdeaTable };

import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  Collapse,
  CardContent,
  Grid,
  TextField,
  IconButton,
  Button,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { executionActions, ideaConclusionActions } from '../../actions';
import { ButtonNext } from './ButtonNext';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { formatReal } from '../../helpers';
import { InputMask } from "./../../components"
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.neutrals.low.dark,
  },
}));

function CostStructureCollapse({ isConclusion, isResults = false }) {
  const classes = useStyles();
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { costsFields } = useSelector(state => state.execution)
  const regexPattern = new RegExp(',', 'g');
  const regexPointPattern = new RegExp('[.]', 'g');

  useEffect(() => {
    if (isConclusion || isResults) {
      if (costsFields.length > 0) {
        dispatch(ideaConclusionActions.setConclusionInput(costsFields, "costs"))
      }
    }
  }, [costsFields, isConclusion, isResults, dispatch])


  function handleAddMoreCostField() {
    dispatch(executionActions.addCostField())
  }

  function handleChangeCost(value, index, name) {
    if (name === "estimatedCost" || name === "cost") {
      const formatedValue = value.replace(regexPointPattern, '').replace(regexPattern, '')
      if (!isNaN(formatedValue) && value.toString().length <= 14) {
        dispatch(executionActions.changeCost(formatReal(formatedValue), index, name))
      }
    } else {
      dispatch(executionActions.changeCost(value, index, name))
    }

  }

  function handleDeleteField(index) {
    dispatch(executionActions.deleteCostField(index))
  }

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('estrutura_de_custos')}
          icon={<MoneyOffIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        <Grid container spacing={2}>
          {costsFields.map((cost, index) => (
            <Fragment key={index}>
              <Grid item xs={isConclusion || isResults ? 6 : 8}>
                {cost._id && (isConclusion || isResults) ? <>
                  <strong>{isResults ? t('despesa') : t('informe_o_nome_da_empresa')}</strong>
                  <Typography>{cost.costDescription}</Typography>
                </> : <TextField
                  type="text"
                  variant="filled"
                  label={t('informe_o_nome_da_empresa')}
                  fullWidth
                  inputRef={{
                    required: t('nome_da_empresa_e_obrigatorio'),
                    maxLength: 100,
                  }}
                  onChange={(e) => handleChangeCost(e.target.value, index, "costDescription")}
                  value={cost.costDescription}
                />}
              </Grid>
              <Grid item xs={isConclusion || isResults ? 3 : 3}>
                {cost._id && (isConclusion || isResults) ? <>
                  <strong>{`${t('dinheiro')} ${t('estimado')}`}</strong>
                  <Typography>{cost.estimatedCost}</Typography>
                </> : <TextField
                  type="text"
                  variant="filled"
                  label={`${t('dinheiro')} ${t('estimado')}`}
                  onChange={(e) => handleChangeCost(e.target.value, index, "estimatedCost")}
                  value={cost.estimatedCost}
                  fullWidth
                  inputRef={{
                    maxLength: 6,
                    required: isConclusion || isResults ? false : t('o_valor_e_obrigatorio'),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: collapseClasses.labelRoot,
                    },
                  }}
                />}
              </Grid>
              {(isConclusion || isResults) && (
                <Grid item xs={3}>
                  {isResults ? <>
                  <strong>{`${t('dinheiro')} ${t('real')}`}</strong>
                  <Typography>{cost.cost}</Typography>
                </> : <TextField
                    type="text"
                    variant="filled"
                    fullWidth
                    label={`${t('dinheiro')} ${t('real')}`}
                    inputRef={{
                      maxLength: 6,
                      required: t('o_valor_e_obrigatorio'),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: collapseClasses.labelRoot,
                      },
                    }}
                    value={cost.cost}
                    onChange={(e) => handleChangeCost(e.target.value, index, "cost")}
                  />}
                </Grid>
              )}
              {!isConclusion && !isResults && index > 0 && (
                <Grid item xs={1} className={collapseClasses.containerRemoveButton}>
                  <IconButton className={collapseClasses.removeButton} onClick={() => handleDeleteField(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
            </Fragment>
          ))}
          {!isResults && (
            <Grid item xs={12}>
              <Button
                onClick={handleAddMoreCostField}
                startIcon={<AddCircleIcon />}
                color="primary"
              >
                {t('incluir_nova_despesa')}
              </Button>
            </Grid>
          )}
          <Grid item xs={isConclusion || isResults ? 6 : 8}>
            <Box className={classes.value}>{t('total')}</Box>
          </Grid>
          <Grid item xs={isConclusion || isResults ? 3 : 4}>
            <Box className={classes.value}>
              {costsFields.length > 0 ? formatReal(
                costsFields
                  .filter((cost) => cost.estimatedCost)
                  .map(item => item.estimatedCost.replace(regexPointPattern, '').replace(regexPattern, ''))
                  .reduce((prev, next) => prev + Number(next), 0)) : 0
              }
            </Box>
          </Grid>
          {(isConclusion || isResults) && (
            <Grid item xs={3}>
              <Box className={classes.value}>
                {costsFields.length > 0 ? formatReal(
                  costsFields
                    .filter((cost) => cost.cost)
                    .map(item => item.cost.replace(regexPointPattern, '').replace(regexPattern, ''))
                    .reduce((prev, next) => prev + Number(next), 0)) : 0
                }
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export { CostStructureCollapse };

import React from 'react';
import { makeStyles, fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  status: {
    color: theme.palette.neutrals.high.main,
    fontSize: 11,
    fontWeight: '600',
    textTransform: 'uppercase',
    backgroundColor: (status) => fade(status.color, 1),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
  },
}));

function StatusIdea({ status }) {
  const classes = useStyles(status);

  return <span className={classes.status}>{status.name}</span>;
}

export { StatusIdea };

import React from 'react';
import {
  makeStyles,
  Typography,
  Box,
  Avatar,
} from '@material-ui/core';
import TimeAgo from 'react-timeago';
import { formatterTimer, dateFormat, timeFormat, history } from '../../helpers';
import { AvatarWithoutPhoto } from './AvatarWithoutPhoto';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import { CustomTooltip } from '../Shared/CustomTooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  authorHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  authorHeaderPaddingless: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 11,
    color: theme.palette.neutrals.low.light,
    fontWeight: 500,
  },
  mr: {
    marginRight: theme.spacing(2),
  },
  name: {
    cursor: 'pointer',
  },
  namePopover: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  authorProfileHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  labelProfile: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    textTransform: 'lowercase',
    fontWeight: 'bold',
  },
  boxInformation: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 190,
    },
  },
  disableTooltip: {
    position: "absolute",
    bottom: 0,
    right: 16,
    background: 'white'
  },
  disableIcon: {
    position: "absolute",
    zIndex: 2,
    height: 20,
    width: 20,
    color: theme.palette.grey.darkest,
    border: "2px solid white",
    borderRadius: "50%"
  }
}));

function Author(props) {
  const classes = useStyles();
  const { timelineDate, dateAndTime, userAuthor } = props;
  const language = useSelector((state) => state.language); 
  const { t } = useTranslation();

  function goToProfile() {
    history.push(`/profile/${userAuthor._id}`);
  }

  return (
    <div className={props.paddingless ? classes.authorHeaderPaddingless : classes.authorHeader }>
      <Box position={"relative"}>
        {userAuthor?.attachment?.url ? (
          <Avatar 
            alt={userAuthor?.name}
            src={userAuthor?.attachment?.url}
            className={classes.small}
          />
        ) : (
          <AvatarWithoutPhoto className={classes.mr} large></AvatarWithoutPhoto>
        )}
        { !props.activeUser && (
          <CustomTooltip
            className={classes.disableTooltip}
              title={t('disabled')}
              aria-label={t('disabled')}
            >
            <CancelIcon className={classes.disableIcon} />
          </CustomTooltip>
        )}
      </Box>
      <div className={classes.boxInformation}>
        <Typography
          variant="subtitle1"
          component="h1"
          className={classes.name}
          onClick={goToProfile}
        >
      
          <Box
            fontWeight="fontWeightBold"
            fontSize={14}
            onClick={goToProfile}
          >
            {userAuthor?.name}
          </Box>
        </Typography>
        
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {userAuthor?.position && (
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.subtitle}
            >
              {userAuthor?.position}
            </Typography>
          )}
          {userAuthor?.businessUnitLocal?.length > 0 &&
            userAuthor?.businessUnitLocal[0]?.local?.name && (
              <Typography
                component="span"
                variant="subtitle2"
                className={classes.subtitle}
              >
                {' '}
                | {userAuthor?.businessUnitLocal[0]?.local?.name}
              </Typography>
            )}
        </div>
        {timelineDate && (
          <Typography variant="subtitle2" component="h5">
            <Box className={classes.subtitle}>
              <TimeAgo
                date={timelineDate}
                formatter={formatterTimer(language)}
              />
            </Box>
          </Typography>
        )}
        {dateAndTime && (
          <Typography variant="subtitle2" component="h5">
            <Box className={classes.subtitle}>
              {dateFormat(dateAndTime, language)} - {timeFormat(dateAndTime)}
            </Box>
          </Typography>
        )}
      </div>
    </div>
  );
}

export { Author };
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { 
  Container, 
  Grid, 
  makeStyles, 
  Hidden, 
} from '@material-ui/core';
import { 
  BackNavAdmin, 
  SidebarProfile, 
  ProfileTable, 
  Loading 
} from '../../components';
import { IdeaCard } from '../../components/Idea';
import { profileActions } from '../../actions/profile.actions';

const useStyles = makeStyles((theme) => ({
  root: { 
    flexGrow: 1,
    marginTop: theme.spacing(4)
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ProfilePage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, ideas, paging } = useSelector((state) => state.profile);
  const loading = useSelector((state) => state.profile.loading);
  const { id } = useParams();

  // const [openDialog, setOpentDialog] = useState(false);
  // const [idea, setIdea] = useState({});
  
  useEffect(() => {
    if (!user || user._id !== id) {
      dispatch(profileActions.getUser(id));
      dispatch(profileActions.getIdeasByUserId(id, paging.page, paging.limit));
    }
    // eslint-disable-next-line
  }, []);

  function loadMoreIdeas() {
    if (!loading) {
      var newPage = paging.page === 1 ? 2 : paging.page;
      dispatch(profileActions.getIdeasByUserIdMobile(id, newPage, paging.limit));
    }
  }

  function renderCards() {
    if (ideas.length > 0) {
      return (
        <InfiniteScroll
          dataLength={ideas.length}
          next={loadMoreIdeas}
          hasMore={true}
          scrollThreshold={'95%'}
          loader={
            loading && (
              <div className={classes.containerLoading}>
                <Loading />
              </div>
            )
          }
        >
          {ideas.map((idea, index) => (
            <IdeaCard
              index={index}
              idea={idea}
              setIdea={null}
              key={index}
              history={props.history}
              dialog={{
                setOpentDialog: null,
                openDialog: null,
              }}
            />
          ))}
        </InfiniteScroll>
      );
    }
    return null;
  }

  return (
    <>
      <BackNavAdmin title={t('perfil_de_usuario')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            {user && <SidebarProfile user={user} />}
          </Grid>
          <Grid item xs={12} md={9}>
            <Hidden smDown>
              <ProfileTable ideas={ideas} paging={paging} userId={id} />
            </Hidden>
            <Hidden smUp>
            <div style={{ fontSize: 14.22, fontWeight: 'bold', paddingBottom: 12 }}>
              IDEIAS PUBLICADAS NO FEED
            </div>
              {renderCards()}
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ProfilePage };

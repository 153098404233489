import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from "@material-ui/lab";
import { ImagineGrid } from "..";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    backgroundColor: "#C0C0C0",
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  spacing: {
    padding: theme.spacing(1),
    backgroundColor: "#C0C0C0",
  }
}));

export function SkeletonSidebarProfile() {
  const classes = useStyles();

  return(
    <ImagineGrid container spacing={2} className={classes.gridContainer}>
      <ImagineGrid item xs={12} className={classes.gridItem}>
        <Skeleton variant="circle" className={classes.avatar} />
      </ImagineGrid>
      <ImagineGrid item xs={12} className={classes.gridItem}>
        <Skeleton variant="text" width="90%" className={classes.spacing} />
      </ImagineGrid>
      <ImagineGrid item xs={12} className={classes.gridItem}>
        <Skeleton variant="text" width="90%" className={classes.spacing} />
      </ImagineGrid>
      <ImagineGrid item xs={12} className={classes.gridItem}>
        <Skeleton variant="text" width="90%" className={classes.spacing} />
      </ImagineGrid>
      <ImagineGrid item xs={12} className={classes.gridItem}>
        <Skeleton variant="text" width="90%" className={classes.spacing} />
      </ImagineGrid>
    </ImagineGrid>
  );
}
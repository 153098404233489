import React, { useEffect, useState } from 'react';
import { Container, Grid, Button, useTheme } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  PortifolioTitle,
  PortifolioTable,
  PortifolioCardsStatus,
  PortifolioMenusTable
} from '../../components';
import { portfolioDetailsActions } from '../../actions/portfolio/portfolio-details.actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ReactComponent as PushPinIcon} from '../../assets/imgs/push_pin.svg';
import { DialogCreateTask } from './DialogCreateTask';
import { SkeletonDetailsTask } from './SkeletonDetailsTask';

function PortifolioDetailsTask(props) {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const { tasks } = useSelector(state => state.portfolioDetails);
  const { projectTasksStatus } = useSelector(state => state.domains);

  useEffect(() => {
    if (projectTasksStatus && projectTasksStatus.length > 0) {
      const code = projectTasksStatus.filter(item => item.order === 1)[0].code;
      dispatch(portfolioDetailsActions.changeField("taskTabChosed", code))
      dispatch(portfolioDetailsActions.getAllTask({
        page: 1,
        limit: 10,
        taskStatusCode: code,
        projectId: id
      }, projectTasksStatus))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id, dispatch, projectTasksStatus ])

  function handleOpenTaskDialog() {
    setOpenTaskDialog(!openTaskDialog);
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', alingItems: 'center', justifyContent: 'space-between' }}>
          <PortifolioTitle
            title={t('tarefas')}
            icon={<PushPinIcon style={{ fill: theme.palette.primary.main }} />}
          />
          <Button
            color="primary"
            startIcon={<AddCircle />}
            onClick={handleOpenTaskDialog}
          >
            {t('adicionar_tarefa')}
          </Button>
          { openTaskDialog && <DialogCreateTask
              open={openTaskDialog}
              onClose={handleOpenTaskDialog}
              projectId={id}
            />
          }
        </Grid>
        {tasks.loading ? (
          <SkeletonDetailsTask />
        ) : (
          <>
            <Grid item xs={12}>
              <PortifolioCardsStatus />
            </Grid>
            <Grid item xs={12}>
              <PortifolioMenusTable />
            </Grid>
            <Grid item xs={12}>
              <PortifolioTable />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

export { PortifolioDetailsTask };

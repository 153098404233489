import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-hook-form';
import { feedbackActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import illustrationFeedback from '../../assets/imgs/illustration-feedback.svg';
import { DiscartFeedback } from './DiscartFeedback';

const styles = (theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: theme.palette.text.primary
  },
  text: {
    fontSize: 16,
    color: '#707070'
  },
  actionsFlex: {
    display: 'flex',
    alingItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 3, 3)
  },
  form: {
    width: '100%',
  },
  btnSend: {
    float: 'right',
    marginTop: theme.spacing(2),
    color: theme.palette.neutrals.high.main,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, open, onClose, ...other } = props;
  const [ openDiscartFeedback, setOpenDiscartFeedback ] = useState(false);

  function handleCloseFeedback() {
    setOpenDiscartFeedback(false);
  }

  function handleOpenFeedback() {
    setOpenDiscartFeedback(true);
  }

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      <IconButton
        onClick={handleOpenFeedback}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <DiscartFeedback 
        open={openDiscartFeedback} 
        onClose={handleCloseFeedback}
      /> 
    </MuiDialogTitle>
  );
});

function DialogCustomTitle(props) {
  return <DialogTitle {...props} />;
}

function DialogFeedback({ open, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { feedbackText } = useSelector((state) => state.feedback);
  const { register, handleSubmit, getValues } = useForm({ mode: 'onBlur' });

  function setValues() {
    const values = getValues();
    const data = {
      feedbackText: values.feedbackText,
    };
    dispatch(feedbackActions.setFeedback(data));
  }

  function onSubmit(data) {
    if (!data.feedbackText) {
      return;
    }

    dispatch(feedbackActions.sendFeedback(data));
    dispatch(feedbackActions.clearFeedback());
    onClose();
  }
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogCustomTitle>
        {t('enviar_feedback')}
      </DialogCustomTitle>
      <DialogContent dividers style={{ borderBottom: 'none'}}>
        <img src={illustrationFeedback} alt="illustration Feedback" />
        <DialogContentText className={classes.subTitle}>
          {t('gostaria_de_nos_ajudar_a_melhorar_o_imagine')}
        </DialogContentText>
        <DialogContentText className={classes.text}>
          {t('compartilhe_conosco_a_sua_experiencia_com_a_plataforma')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsFlex}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            multiline
            fullWidth
            id="feedbackText"
            name="feedbackText"
            label="feedback"
            variant="filled"
            rows={8}
            onBlur={setValues}
            defaultValue={feedbackText}
            inputRef={register({
              required: "O feedback é obrigatorio"
            })}
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.btnSend}
          >
            {t('enviar')}
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
}

export { DialogFeedback };
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Typography, Button, makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions  } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { portfolioActions } from '../../../actions';
import { portfolioDialogProjectActions } from '../../../actions/portfolio/portfolio-dialog-project.actions';
import { portfolioDetailsActions } from "./../../../actions/portfolio/portfolio-details.actions"
import { DialogPortifolio } from '../DialogPortifolio';
import { DialogPortfolioDelete } from '../DialogPortfolioDelete';
import { TextArea } from '../../Shared';
import { portfolioExecutionFlow } from '../../../actions/portfolio/portfolio-execution-flow.actions';

const filterOptions = createFilterOptions({
  limit: 200,
});

const useStyles = makeStyles((theme) => ({
  titleDelete: {
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 15,
    color: theme.palette.neutrals.low.dark
  },
  descriptionDelete: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium
  },
  btnCreate: {
    float: 'right',
  },
}));

export function DialogCreateProject({ open, onClose, title, project = false, executionFlow = false }) {
  const classes = useStyles();
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialogDeleteProject, setDialogDeleteProject] = useState(false);
  const user = useSelector((state) => state.user);
  const { portfolioStatus } = useSelector(state => state.domains)
  const { ideaDetails } = useSelector((state) => state.idea);

  const { 
    linkedIdeas,
    idea,
    name,
    description,
    dateInit,
    dateEnd,
    loading,
    success,
 } = useSelector((state) => state.portfolioDialogProject);

  useEffect(() => {
    if (project) {
      dispatch(portfolioDialogProjectActions.loadProject(project))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedIdeas, project]);

  // useEffect(() => {
  //   if (success) {
  //     dispatch(portfolioDetailsActions.getProject(projectId));
  //     handleCloseDialog()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [success]);

  function handleCloseDialog() {
    dispatch(portfolioDialogProjectActions.clearCreateProject());
    onClose();
  }  

  function handleOpenDialogDeleteProject() {
    setDialogDeleteProject(!openDialogDeleteProject)
  }

  function handleLinkedIdea(data) {
    dispatch(portfolioDialogProjectActions.changeField('idea', data.option));
  }

  function handleSetProjectName(e) {
    const { value } = e.target;
    dispatch(portfolioDialogProjectActions.changeField('name', value));
  }

  function handleSetProjectDescription(e) {
    const { value } = e.target;
    dispatch(portfolioDialogProjectActions.changeField('description', value));
  }

  const handleDateInit = (date) => {
    dispatch(portfolioDialogProjectActions.changeField('dateInit', date));
  };

  const handleDateEnd = (date) => {
    dispatch(portfolioDialogProjectActions.changeField('dateEnd', date));
  };

  function handleDeleteProject() {
    dispatch(portfolioActions.deleteProject(projectId, t('projeto_excluido')));
    handleCloseDialog();
  }

  function handleSubmit() {
    let data = {
      idea: idea.id,
      name,
      description,
      dateInit,
      dateEnd,
      author: user._id
    }

    if (project) {
      data = { ...data, _id: project._id }
    }

    if (project) {
      dispatch(portfolioDialogProjectActions.putUpdateProject(data, t('projeto_editado'), portfolioStatus));
      dispatch(portfolioExecutionFlow.getProjects(ideaDetails._id));
    } else {
      dispatch(portfolioDialogProjectActions.postCreateProject(data, t('projeto_criado'), portfolioStatus));
      executionFlow && dispatch(portfolioExecutionFlow.getProjects(ideaDetails._id));
    }
  }

  return (
    <DialogPortifolio 
      open={open} 
      onClose={handleCloseDialog}
      title={title}
      content={
        <>
          <Grid item xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              loadingText={t('carregando')}
              noOptionsText={t('sem_opcoes')}
              id="checkboxes-dialog"
              options={linkedIdeas}
              loading={linkedIdeas.length === 0}
              defaultValue={idea}
              onChange={(e, items, options, details) => handleLinkedIdea(details)}
              getOptionLabel={(option) => option.label || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label={t('ideia_vinculada')} variant="filled" />}
              renderTags={(option, state) => (<span>{`${option[0].name}`}</span>)}
              disabled={executionFlow}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              fullWidth
              variant="filled"
              id="name"
              name="name"
              label={t('nome_do_projeto')}
              onBlur={handleSetProjectName}
              defaultValue={name}
            />
          </Grid>

          <Grid item xs={12}>
             <TextArea
              limitChars={512}
              onBlur={handleSetProjectDescription}
              label={t("descricao_do_projeto")}
              value={description}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              autoOk
              fullWidth
              id="dateInit"
              name="dateInit"
              variant="inline"
              label={t('inicio')}
              format="dd/MM/yyyy"
              inputVariant="filled"
              value={dateInit}
              onChange={handleDateInit}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              autoOk
              fullWidth
              id="dateEnd"
              name="dateEnd"
              variant="inline"
              label={t('termino')}
              format="dd/MM/yyyy"
              inputVariant="filled"
              value={dateEnd}
              onChange={handleDateEnd}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </Grid>
        </>
      }
      action={
        <>
          {project ? 
            <Grid container>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleOpenDialogDeleteProject}
                  disabled={loading}
                >
                  {t('excluir_projeto')}
                </Button>
                <DialogPortfolioDelete
                  open={openDialogDeleteProject}
                  onClose={handleOpenDialogDeleteProject}
                  project={project}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnCreate}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {t('salvar_projeto')}
                </Button>
              </Grid>
            </Grid>
          : 
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnCreate}
              onClick={handleSubmit}
              disabled={loading || (( executionFlow ? false : !idea ) || !name || !description || !dateInit || !dateEnd)}
            >
              {t('criar_projeto')}
            </Button>
          }
        </>
      }   
    />
  );
}
import React, { useEffect, useState, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, TextField, ButtonBase, Typography } from "@material-ui/core" 
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Menu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { 
  BackNavAdmin,
  SidebarManageAccess,
  Search,
  UsersPointsTable,
  SelectedUser,
  UserPointsFilterMenu,
} from "./../../components"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { userFunctionActions, walletActions } from "./../../actions"
import { history, searchToObject } from "./../../helpers"
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  container: {
    marginTop: 32
  },
  filterButton: {
    backgroundColor: theme.palette.grey.dark,
    borderRadius: "4px",
    fontSize: "15px",
    padding: "1rem",
    color: "#343A40"
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    padding: "4px 8px",
    fontSize: "10px",
    color: "#343A40",
    borderRadius: "4px",
    backgroundColor: "#CED4DA",
    marginLeft: "0.5rem"
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  },
  pointsWalletCard: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "2rem"
  },
  pointsWalletCardName: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  pointsWalletCardDescription: {
    fontSize: "12px",
    color: theme.palette.grey.darkest,
  },
  pointsWalletCardPointsTitle: {
    fontSize: "12px",
    color: theme.palette.grey.darkest,
  },
  pointsWalletCardPoints: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  walletIcon: {
    color: theme.palette.secondary.main,
    fontSize: "2rem"
  },
}))

export const UserPoints = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    selectedUser,
    loadingUser,
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains)
  const {
    paging,
    loading,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  const { _id } = useSelector((state) => state.user);
  const params = useParams();

  useEffect(() => {
    if (!selectedUser && !loadingUser) {
      if (params.id) {
        dispatch(userFunctionActions.loadPage(params.id, imagineLocals));
      } else {
        history.push("/users");
      }
    } else {
      if (selectedUser) {
        dispatch(walletActions.getUserPoints(selectedUser.id));
        dispatch(walletActions.getWallets(selectedUser.id, 1, quantityPerPage, filter, sort, query))
      }
    }
  }, [selectedUser, params.id])

  useEffect(() => {
    return () => dispatch(walletActions.clearState())
  }, [dispatch])

  function searchWallet(q) {
    dispatch(walletActions.setQuery(q));
    dispatch(walletActions.getWallets(_id, 1, quantityPerPage, filter, sort, q));
  }

 
  return (
    <Fragment>
      <BackNavAdmin title={t('dados_do_usuario')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
          <Hidden smDown>
            <Grid item xs={3}>
              <SidebarManageAccess />
            </Grid>
            <Grid item xs={9}>
              { selectedUser && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <SelectedUser showPoints={true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={7}>
                        <UserPointsFilterMenu />
                      </Grid>
                      <Grid item xs={5}>
                        <Search
                          disabled={loading}
                          placeholder={"pesquisar por ideia ou ação"}
                          query={searchWallet}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <UsersPointsTable />
                  </Grid>
                </Grid>
              ) }
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Fragment>
  )
}
export const challengeConstants = {
  GET_CHALLENGE_BY_ID_REQUEST: 'GET_CHALLENGE_BY_ID_REQUEST',
  GET_CHALLENGE_BY_ID_SUCCESS: 'GET_CHALLENGE_BY_ID_SUCCESS',
  GET_CHALLENGE_BY_ID_FAILURE: 'GET_CHALLENGE_BY_ID_FAILURE',

  GET_CHALLENGES_REQUEST: 'GET_CHALLENGES_REQUEST',
  GET_CHALLENGES_SUCCESS: 'GET_CHALLENGES_SUCCESS',
  GET_CHALLENGES_FAILURE: 'GET_CHALLENGES_FAILURE',

  GET_MORE_CHALLENGES_REQUEST: 'GET_MORE_CHALLENGES_REQUEST',
  GET_MORE_CHALLENGES_SUCCESS: 'GET_MORE_CHALLENGES_SUCCESS',
  GET_MORE_CHALLENGES_FAILURE: 'GET_MORE_CHALLENGES_FAILURE',

  GET_IDEAS_CHALLENGE_REQUEST: 'GET_IDEAS_CHALLENGE_REQUEST',
  GET_IDEAS_CHALLENGE_SUCCESS: 'GET_IDEAS_CHALLENGE_SUCCESS',
  GET_IDEAS_CHALLENGE_FAILURE: 'GET_IDEAS_CHALLENGE_FAILURE',

  GET_CHALLENGE_EDIT_REQUEST: 'GET_CHALLENGE_EDIT_REQUEST',
  GET_CHALLENGE_EDIT_SUCCESS: 'GET_CHALLENGE_EDIT_SUCCESS',
  GET_CHALLENGE_EDIT_FAILURE: 'GET_CHALLENGE_EDIT_FAILURE',

  POST_CHALLENGE_REQUEST: 'POST_CHALLENGE_REQUEST',
  POST_CHALLENGE_SUCCESS: 'POST_CHALLENGE_SUCCESS',
  POST_CHALLENGE_FAILURE: 'POST_CHALLENGE_FAILURE',

  UPDATE_CHALLENGE_REQUEST: 'UPDATE_CHALLENGE_REQUEST',
  UPDATE_CHALLENGE_SUCCESS: 'UPDATE_CHALLENGE_SUCCESS',
  UPDATE_CHALLENGE_FAILURE: 'UPDATE_CHALLENGE_FAILURE',


  NEXT_FORM_IMAGE: 'NEXT_FORM_IMAGE',

  SET_PROP_CHALLENGE: 'SET_PROP_CHALLENGE',
  SET_CRITERIA: 'SET_CRITERIA',
  SET_CHALLENGE: 'SET_CHALLENGE',
  SET_IMAGEM: 'SET_IMAGEM',
  REMOVE_IMAGEM: 'REMOVE_IMAGEM',
  SET_DATES: 'SET_DATES',
  SET_DATE: 'SET_DATE',
  SET_FILTER_COLABORATOR: 'SET_FILTER_COLABORATOR',

  NEXT_CARD_IMAGE: 'NEXT_CARD_IMAGE',

  ADD_AWARD: 'ADD_AWARD',
  REMOVE_AWARD: 'REMOVE_AWARD',
  SET_AWARD: 'SET_AWARD',

  SET_WINNERS: 'SET_WINNERS',
  SET_WINNER_CARD: 'SET_WINNER_CARD',
  SET_SEND_WINNERS: 'SET_SEND_WINNERS',
  CLEAR: 'CLEAR',
  CLEAR_FORM: 'CLEAR_FORM',
  CLEAR_WINNER_CARD: 'CLEAR_WINNER_CARD',
  CLEAR_SELECT_WINNER: 'CLEAR_SELECT_WINNER',
  CLEAR_PROGRESS: 'CLEAR_PROGRESS',
  SET_IMAGE_TO_REMOVE: 'SET_IMAGE_TO_REMOVE'
};

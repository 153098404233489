import { feedbackConstants } from '../constants';

const initialState = {
  feedbackText: '',
  loading: false,
  openFeedback: false, 
};

export function feedback(state = initialState, action) {
  switch (action.type) {

    case feedbackConstants.SET_FEEDBACK_TEXT: {
      return {
        ...state,
        feedbackText: action.payload.feedbackText
      }
    }

    case feedbackConstants.CLEAR_FEEDBACK_TEXT: {
      return initialState;
    }

    case feedbackConstants.OPEN_FEEDBACK: {
      return {
        ...state,
        openFeedback: action.payload
      }
    }

    case feedbackConstants.POST_USER_FEEDBACK_SUCCESS: {
      return state;
    }

    case feedbackConstants.POST_USER_FEEDBACK_FAILURE: {
      return state;
    }

    default:
      return state;
  }
}

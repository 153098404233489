import React, { Fragment } from 'react'
import {
  makeStyles,
  Box,
  Card, 
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  fixedPost: {
    marginBottom: theme.spacing(6),
  },
}))

export const SkeletonHighlightPost = () => {
  const classes = useStyle();

  return (
    <Fragment>
      <Card className={classes.fixedPost}>
        <Skeleton width="100%" height="200px" variant="rect" animation="wave" />
        <Box display="flex" flexDirection="column" padding="1rem">
          <Skeleton variant="text" width="200px" animation="wave" className={classes.title} />
          <Skeleton variant="text" width="100%" animation="wave" />
          <Skeleton variant="text" width="50%" animation="wave" />
        </Box>
      </Card>
    </Fragment>
    
  )
}

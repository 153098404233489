import React, { useState } from 'react';
import {
    Box,
    Menu,
    withStyles,
    makeStyles,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    useTheme
  } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    filter: {
      display: 'flex',
      height: '100%',
      backgroundColor: theme.palette.neutrals.high.medium,
      color: theme.palette.neutrals.low.medium,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      borderWidth: 2,
      borderColor: theme.palette.neutrals.high.medium,
      borderStyle: 'solid',
      alignItems: 'center'
    },
    primaryBorder: {
      borderColor: theme.palette.primary.main
    },
    groupLabel: {
      color: `${theme.palette.neutrals.low.main} !important`,
      fontWeight: 400,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2)
    },
    radioLabel: {
      justifyContent: 'space-between',
      marginLeft: 0
    },
    radioView: {
      marginLeft: theme.spacing(10),
    },
    radioPeriod: {
      marginLeft: theme.spacing(11.8),
    },
    separator: {
      backgroundColor: theme.palette.neutrals.low.lightest,
      height: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    paper: {
      boxShadow: `1px 2px 6px 1px ${theme.palette.neutrals.low.lightest}`,
    },
}))

function WalletMobileFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles()

  const theme = useTheme();
  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${theme.palette.neutrals.high.dark}`,
      maxHeight: 480,
      overflow: 'scroll'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
        <div className={`${classes.filter} material-icons ${Boolean(anchorEl) && classes.primaryBorder}`}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          filter_alt
        </div>
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{paper: classes.paper}}
        >
          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.groupLabel} >visualizar: </FormLabel>
                <RadioGroup aria-label="view" name="view1" value={props.classification}
                onChange={(e) => {
                  props.onHandleClassification(e)
                  handleClose()
                  }}>
                  <FormControlLabel
                  value="credit"
                  control={<Radio classes={{root: classes.radioView}} />} 
                  label="Créditos" 
                  labelPlacement='start'
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="debit" 
                  control={<Radio classes={{root: classes.radioView}} />} 
                  label="Resgates" 
                  labelPlacement='start'
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="all" 
                  control={<Radio classes={{root: classes.radioView}} />} 
                  label="Todos" 
                  labelPlacement='start' 
                  classes={{labelPlacementStart: classes.radioLabel}}/>
              </RadioGroup>
            </FormControl>
            <div className={classes.separator}></div>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.groupLabel} >período: </FormLabel>
                <RadioGroup aria-label="view" name="view1" value={props.period} 
                onChange={(e)=>{
                  props.onHandlePeriod(e)
                  handleClose()
                  }}>
                  <FormControlLabel
                  value="7"
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="07 dias" 
                  labelPlacement='start'
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="15" 
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="15 dias" 
                  labelPlacement='start'
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="30" 
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="30 dias" 
                  labelPlacement='start' 
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="60" 
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="60 dias" 
                  labelPlacement='start' 
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="90" 
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="90 dias" 
                  labelPlacement='start' 
                  classes={{labelPlacementStart: classes.radioLabel}}/>
                  <FormControlLabel 
                  value="all" 
                  control={<Radio classes={{root: classes.radioPeriod}} />} 
                  label="Todos" 
                  labelPlacement='start' 
                  classes={{labelPlacementStart: classes.radioLabel}}/>
              </RadioGroup>
            </FormControl>
          </Box>
        </StyledMenu>
    </Box>
    
  );
}

export { WalletMobileFilter }
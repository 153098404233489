import React, { Fragment } from 'react';
import { Card, CardActions, Grid, Box, CardContent, Typography } from '@material-ui/core';
import { collapseCss } from './collapseCss';
import EventIcon from '@material-ui/icons/Event';
import { HeaderCollapse } from './HeaderCollapse';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { executionActions, ideaConclusionActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { selectedLanguageDateInputFormat } from '../../helpers/input-date-format';
import moment from 'moment';

function DeadlineCollapse({ isConclusion, isResults = false }) {
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const {
    estimatedStartDate,
    estimatedConclusionDate
  } = useSelector((state) => state.execution);
  const {
    startDate,
    conclusionDate
  } = useSelector(state => state.ideaConclusion);

  function handleChange(date, name) {
    if (date instanceof Date && isFinite(date)) {
      if (!isConclusion && !isResults) {
        dispatch(executionActions.setInputDeadline(date, name));
      } else {
        dispatch(ideaConclusionActions.setConclusionInput(date, name))
      }
    }
  }

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('prazo_execucao')}
          icon={<EventIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Box fontSize="14px" fontWeight="600">{t('estimado')}</Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            {isConclusion || isResults ? <>
              <strong>{t('inicio')}</strong>
              <Typography>{moment(estimatedStartDate).format("DD/MM/YYYY")}</Typography>
            </> : <KeyboardDatePicker
              variant="inline"
              inputVariant="filled"
              required={true}
              format={selectedLanguageDateInputFormat(language)}
              fullWidth
              id="estimatedStartDate"
              name="estimatedStartDate"
              label={t('inicio')}
              invalidDateMessage={
                <span className={collapseClasses.errorSpan}>
                  {t('data_de_inicio_invalida')}
                </span>}
              value={estimatedStartDate}
              onChange={(data) => handleChange(data, 'estimatedStartDate')}
              autoOk
            />}
          </Grid>
          <Grid item xs={12} lg={5}>
            {isConclusion || isResults ? <>
              <strong>{t('termino')}</strong>
              <Typography>{moment(estimatedConclusionDate).format("DD/MM/YYYY")}
              </Typography>
            </> : <KeyboardDatePicker
              variant="inline"
              inputVariant="filled"
              required={true}
              format={selectedLanguageDateInputFormat(language)}
              fullWidth
              id="estimatedConclusionDate"
              name="estimatedConclusionDate"
              label={t('termino')}
              invalidDateMessage={
                <span className={collapseClasses.errorSpan}>
                  {t('data_de_termino_invalida')}
                </span>
              }
              value={estimatedConclusionDate}
              minDate={estimatedStartDate}
              autoOk
              onChange={(data) => handleChange(data, 'estimatedConclusionDate')}
              minDateMessage={
                <span className={collapseClasses.errorSpan}>
                  {t('atencao')}!{' '}
                  {t('a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio')}
                </span>
              }
            />}
          </Grid>
          {(isConclusion || isResults) && (
            <Fragment>
              <Grid item xs={12} lg={2}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Box fontSize="14px" fontWeight="600">{t('real')}</Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={5}>
                {isResults ? <>
                  <strong>{t('inicio')}</strong>
                  <Typography>{moment(startDate ? startDate : estimatedStartDate).format("DD/MM/YYYY")}</Typography>
                </> : <KeyboardDatePicker
                  variant="inline"
                  inputVariant="filled"
                  format={selectedLanguageDateInputFormat(language)}
                  fullWidth
                  margin="normal"
                  id="date-picker-inline"
                  label={t('inicio')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage={t('data_de_inicio_invalida')}
                  name="startDate"
                  value={startDate}
                  onChange={(data) => handleChange(data, 'startDate')}
                  autoOk
                  disabled={isResults}
                />}
              </Grid>
              <Grid item xs={12} lg={5}>
                {isResults ? <>
                  <strong>{t('termino')}</strong>
                  <Typography>{moment(conclusionDate ? conclusionDate : estimatedConclusionDate).format("DD/MM/YYYY")}</Typography>
                </> : <KeyboardDatePicker
                  variant="inline"
                  inputVariant="filled"
                  format={selectedLanguageDateInputFormat(language)}
                  fullWidth
                  margin="normal"
                  id="date-picker-inline"
                  label={t('termino')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage={
                    <span className={collapseClasses.errorSpan}>
                      {t('data_de_termino_invalida')}
                    </span>
                  }
                  name="conclusionDate"
                  value={conclusionDate}
                  minDate={startDate}
                  autoOk
                  onChange={(data) => handleChange(data, 'conclusionDate')}
                  minDateMessage={
                    <span className={collapseClasses.errorSpan}>
                      {t('atencao')}!{' '}
                      {t(
                        'a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio'
                      )}
                    </span>
                  }
                  disabled={isResults}
                />}
              </Grid>
            </Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export { DeadlineCollapse };

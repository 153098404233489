import React, { useEffect, useState } from 'react';
import { helpCenterService } from '../../services/help-center.service'
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 80,
  },
  video: {
    width: '90%',
    height: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
  }
}));

function FirstAccess() {
  const [urlVideo, setUrlVideo] = useState();
  const classes = useStyles();



  useEffect(() => {
    helpCenterService.getWelcomeVideo()
      .then(response => {
        setUrlVideo(response.data.value)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  return (
    <div className={classes.root} >
      {
        urlVideo && (
          <div className={classes.video} >
            <video
              className={classes.iframe}
              width="600"
              height="338"
              controls>
              <source src={`${urlVideo}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
    </div>
  );
}

export { FirstAccess };
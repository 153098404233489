export const ideaCreateConstans = {
  SET_TITLE_DESCRIPTION: 'SET_TITLE_DESCRIPTION',

  SET_METHODOLOGY: 'SET_METHODOLOGY',

  SET_BUSINESS_UNIT_LOCAL: 'SET_BUSINESS_UNIT_LOCAL',
  REMOVE_BUSINESS_UNIT_LOCAL: 'REMOVE_BUSINESS_UNIT_lOCAL',

  SET_CHALLENGE: 'SET_CHALLENGE',

  SET_IMAGES: 'SET_IMAGES',
  REMOVE_IMAGE: 'REMOVE_IMAGE',

  SET_VIDEO: 'SET_VIDEO',
  REMOVE_VIDEO: 'REMOVE_VIDEO',

  SET_FILES: 'SET_FILES',
  REMOVE_FILE: 'REMOVE_FILE',

  SET_LINKS: 'SET_LINKS',
  REMOVE_LINK: 'REMOVE_LINK',

  SET_CONTRIBUTORS: 'SET_CONTRIBUTORS',

  SET_IDEA_LOCALS: 'SET_IDEA_LOCALS',
  SET_LOCALS_CHECKED: 'SET_LOCALS_CHECKED',
  SET_LOCALS_UNCHECKED: 'SET_LOCALS_UNCHECKED',

  GET_IDEA_USER_DRAFT_REQUEST: 'GET_IDEA_USER_DRAFT_REQUEST',
  GET_IDEA_USER_DRAFT_SUCCESS: 'GET_IDEA_USER_DRAFT_SUCCESS',
  GET_IDEA_USER_DRAFT_FAILURE: 'GET_IDEA_USER_DRAFT_FAILURE',
  CLEAR_CREATE_IDEA: 'CLEAR_CREATE_IDEA',

  SET_CANVAS: 'SET_CANVAS',

  SET_CLASSIFICATION: 'SET_CLASSIFICATION',
  DROP_CLASSIFICATION: 'DROP_CLASSIFICATION',
  CLEAR_CLASSIFICATION: 'CLEAR_CLASSIFICATION',

  SET_VALUE_CANVAS: 'SET_VALUE_CANVAS',

  SET_IDEA_CREATE_CHALLENGE: 'SET_IDEA_CREATE_CHALLENGE',

  SET_SELECTED_BUSINESS: "SET_SELECTED_BUSINESS",
  POST_IDEA_IMAGINE_REQUEST: 'POST_IDEA_IMAGINE_REQUEST',
  POST_IDEA_IMAGINE_SUCCESS: 'POST_IDEA_IMAGINE_SUCCESS',
  POST_IDEA_IMAGINE_FAILURE: 'POST_IDEA_IMAGINE_FAILURE',
  CLEAR_SELECTED_BUSIINESS: "CLEAR_SELECTED_BUSIINESS",

  CLEAR: "CLEAR",
};

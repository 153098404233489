import React from 'react';
import {
  Box,
  Card,
  Divider,
  makeStyles,
} from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { SkeletonInfoUniqueDashboard } from '../../components/Skeletons';
import { NumberFormatCustom } from '../../helpers/number-format-custom';

const useStyles = makeStyles((theme) => ({
  card: {
    height: 302,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  box: {
    width: '100%',
    height: '50%',
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.neutrals.low.light,
  },
  valueTotalCosts: {
    fontSize: 32,
    fontWeight: 'bold',
    color: theme.palette.auxiliary.green.light,
  },
  valueRoi: {
    fontSize: 32,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.main,
  },
  icon: {
    fontSize: 22,
    marginRight: theme.spacing(1),
    color: theme.palette.auxiliary.green.light
  }
}));

export function InfoUniqueDashboard({ indicatorsLoading, totalCosts, roi }) {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
        {indicatorsLoading ? (
          <SkeletonInfoUniqueDashboard />
        ) : (
          <>
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            flexDirection="column"
            className={classes.box}
            >
            <div className={classes.label}>Retorno Financeiro</div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <MonetizationOnIcon className={classes.icon} />
              <div className={classes.valueTotalCosts}>
                <NumberFormatCustom value={totalCosts} />
            </div>
            </Box>
          </Box>
          <Divider />
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            flexDirection="column"
            className={classes.box}
          >
            <div className={classes.label}>ROI (%)</div>
            <div className={classes.valueRoi}>{roi}</div>
          </Box>
          </>
        )}
    </Card>
  );
}


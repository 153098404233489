import React, { useState, useEffect } from 'react';
import { makeStyles, InputBase, fade, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    marginLeft: 0,
    maxWidth: 410,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.grey[500], 0.25),
    },
    [theme.breakpoints.down('md')]: {
    width: '100%',
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

function Search({ placeholder = 'Pesquise por nome da ideia ou desáfio', query, value, InputProps={} }) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(value || "");
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!firstRender) {
      const timeout = setTimeout(() => {
        query(searchValue)
      }, 1000)
      return () => clearTimeout(timeout)
    }

    setFirstRender(false);
  }, [searchValue]);

  return (
    <Box className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={searchValue}
        inputProps={{ 'aria-label': 'search' }}
        InputProps={InputProps}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </Box>
  );
}

export { Search };

import { crudTemplateConstants } from "../constants";
import { history } from "../helpers";
import { crudTemplateService } from "../services/crudTemplate.service";
import { alertActions } from "./alert.actions";

export const crudTemplateActions = {
    getAll,
    create,
    setItem,
    destroy,
    update,
    setTitle,
    setType,
    setTypePage,
    setIdToUpdate,
    setOpenDeleteDialog,
    setPage,
    setLimit,
    clearBodyFields,
    setSearch,
    search,
};

function getAll({ endPoint, page, sort, limit }) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.REQUEST_GET_ALL });
        crudTemplateService.getAll({ endPoint, page, sort, limit }).then(data => {
            dispatch({ type: crudTemplateConstants.GET_ALL, data });
        }).catch(error => {
            console.error(error);
            dispatch({  type: crudTemplateConstants.FAILURE_GET_ALL });
        });
    };
};

function create({ body, endPoint, message, errorMessage }) {
    return dispatch => {
        crudTemplateService.create({ body, endPoint }).then(() => {
            dispatch({ type: crudTemplateConstants.CREATE });
            alertActions.success(message);
        }).catch(error => {
            console.error(error);
            dispatch({ type: crudTemplateConstants.FAILURE_CREATE });
            alertActions.error(errorMessage);
        });
    };
};

function destroy({ id, message, errorMessage }) {
    return dispatch => {
        crudTemplateService.destroy(id).then(() => {
            dispatch({ type: crudTemplateConstants.DELETE });
            alertActions.success(message);
        }).catch(error => {
            console.error(error);
            dispatch({ type: crudTemplateConstants.FAILURE_DELETE });
            alertActions.error(errorMessage);
        });
    };
};

function update({ body, id, endPoint, message, errorMessage }) {
    return dispatch => {
        crudTemplateService.update({ body, endPoint, id }).then(() => {
            dispatch({ type: crudTemplateConstants.UPDATE });
            alertActions.success(message);
        }).catch(error => {
            console.error(error);
            dispatch({ type: crudTemplateConstants.FAILURE_UPDATE });
            alertActions.success(errorMessage);
        });
    };
};

function setItem({ name, value }) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_ITEMS, payload: { name, value } })
    };
};

function setTitle(title) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_TITLE, payload: { title } })
    };
};

function setType(type) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_TYPE, payload: { type } })
    };
};

function setTypePage(type) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_TYPE_PAGE, payload: { type } })
    };
};

function setIdToUpdate(id) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_ID, payload: { id } })
    };
};

function setOpenDeleteDialog(bool) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_OPEN_DELETE_DIALOG, payload: { bool } });
    };
};

function setPage(page) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_PAGE,  payload: { page } });
    };
};

function setLimit(limit) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_LIMIT,  payload: { limit } });
    };
};

function clearBodyFields() {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.CLEAR_BODY_FIELDS });
    };
};

function setSearch(query) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SET_SEARCH, payload: { query } });
    }
}

function search({ endPoint, typeQuery, query, page, sort, limit }) {
    return dispatch => {
        dispatch({ type: crudTemplateConstants.SEARCH_REQUEST });
        crudTemplateService.search({ endPoint, typeQuery, query }).then(({data}) => {
            dispatch({ type: crudTemplateConstants.SEARCH_SUCCESS,data });
        }).catch((error) => {
            console.log(error);
            dispatch({ type: crudTemplateConstants.SEARCH_FAILURE });
            dispatch(crudTemplateActions.getAll({endPoint, page, sort, limit}))
        })
    }
}
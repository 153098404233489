import React, { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { StatusIdea } from "./../../components"
import { Author } from "./../../components/Shared"
import { useSelector, useDispatch } from 'react-redux'
import { ideaUpdateActions, challengeActions } from "./../../actions"
import { ideaEditStyles } from "./IdeaEditStyles"

export const IdeaEditGeneral = () => {
  const { ideaUpdate } = useSelector((state) => state);
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { 
    challenges,
    paging: { sort, filter },
  } = useSelector((state) => state.challenge);
  const [ initialIdeaUpdate ] = useState(ideaUpdate)

  useEffect(() => {
    dispatch(challengeActions.getChallengesActive());
  }, [dispatch])

  useEffect(() => {
    if (challenges.length > 0) {
      const challegensArray = challenges.map((s) => ({
        label: s.name,
        value: s._id,
        checked: false
      }))
      dispatch(ideaUpdateActions.setChallenges(challegensArray))
      dispatch(ideaUpdateActions.selectChallenge(initialIdeaUpdate.challenge?._id ? initialIdeaUpdate.challenge._id : false))
    }
  }, [ challenges, initialIdeaUpdate, dispatch ])

  const handleChange = (event) => {
    dispatch(ideaUpdateActions.changeField(event.target.name, event.target.value))
  }

  const handleChallengeChange = (details) => {
    dispatch(ideaUpdateActions.selectChallenge(details ? details.option.value : false))
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Author
            activeUser={ideaUpdate.user.status}
            paddingless={true}
            dateAndTime={ideaUpdate.createdAt}
            userAuthor={{...ideaUpdate.user, businessUnitLocal: ideaUpdate.businessUnitLocal}}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" marginRight={8}>
            <Typography variant="subtitle2" className={classes.textStatus}>{t('status')}:</Typography>
            <StatusIdea status={ideaUpdate.statusIdea} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            id="name"
            label={t('titulo')}
            autoComplete="name"
            type="text"
            variant="filled"
            fullWidth
            defaultValue={ideaUpdate.name}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            id="description"
            label={t('descricao')}
            autoComplete="description"
            type="text"
            variant="filled"
            fullWidth
            multiline
            rows={8}
            defaultValue={ideaUpdate.description}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          { (!initialIdeaUpdate.challenge?._id || ideaUpdate.challengesList.filter(item => item.checked)[0]) && (
            <Autocomplete
              id="challenges-list"
              options={ideaUpdate.challengesList}
              getOptionLabel={(option) => option.label}
              defaultValue={ideaUpdate.challengesList ? ideaUpdate.challengesList[0] : ""}
              renderInput={(params) => (
              <TextField {...params} label={t('desafio')} variant="filled" />
              )}
              loading={ideaUpdate.challengesList.length === 0}
              value={ideaUpdate.challengesList.filter(item => item.checked)[0]}
              onChange={ (e, items, options, details) => handleChallengeChange(details) }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

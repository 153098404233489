import React, { useState, Fragment, useEffect } from 'react'
import { ReportLayout } from "./../../components"
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  TableCell,
  Typography
} from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { TablePanel, ReportCards } from "./../../components"
import { GraphicPanel, PieChartImagine } from "./../../components"
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from '../../hooks';
import config from '../../config';
import moment from 'moment';
import { tableCss } from './../../components/Tables/TableCss';
import { useTranslation } from 'react-i18next';
import { reportActions } from "./../../actions"
import { CustomTooltip } from '../../components/Shared';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))

export const ReportColaborator = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [tabIndex, setTabIndex] = useState(0);
  const [arrItensCard, setArrItensCArd] = useState({
    cardContent: [],
    dialogContent: []
  })
  const [width] = useWindowSize();
  const { report, chart, tab } = useSelector(state => state.report);
  const { t } = useTranslation();

  function handleView(id) {
    history.push(`/profile/${id}`);
  }

  const tableInfo = {
    columns: [
      { id: 'colaborador', label: t('colaborador') },
      { id: 'status', label: t('matrícula') },
      { id: 'local', label: t('local') },
      { id: 'area', label: t('area') },
      { id: 'date', label: t('data') },
      { id: 'perfil', label: t('perfil') },
    ],
    data: report.map(item => (
      <Fragment>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip>
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.enrolment}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.bul?.local.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.bul?.occupationArea.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{`${moment(item.registerDate).format('DD/MM/YYYY')}`}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>{item.typeProfile}</TableCell>
      </Fragment>
    )),
  }

  useEffect(() => {
    setArrItensCArd({
      cardContent: report.map(item => (
        [
          {
            name: "colaborador",
            value: item.name,
          },
          {
            name: "matrícula",
            value: item.enrolment
          },
          {
            name: "local",
            value: item.bul?.local.name
          }
        ]
      )),
      dialogContent: report.map(item => (
        [
          {
            name: "colaborador",
            value: item.name,
          },
          {
            name: "matrícula",
            value: item.enrolment
          },
          {
            name: "local",
            value: item.bul?.local.name
          },
          {
            name: "area",
            value: item.bul?.occupationArea.name
          },
          {
            name: "data",
            value:  moment(item.registerDate).format('DD/MM/YYYY')
          },
          {
            name: "perfil",
            value: item.typeProfile
          }
        ]
      ))
    })
  }, [report])

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }
  
  return (
    <ReportLayout title={t('lista_de_colaboradores')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      { tab === "table" && (
        <Fragment>
          { width > config.RESPONSIVE_MOBILE ?  
            <TablePanel tableInfo={tableInfo} /> : 
            <ReportCards arrItems={arrItensCard} /> 
          }
        </Fragment>
      )}

      { tab === "graph" && (
        <GraphicPanel>
          <PieChartImagine data={chart} />
        </GraphicPanel>
      )}
    </ReportLayout>
  )
}

export const ideaUpdateConstants = {
  GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS: 'GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS',
  GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE: 'GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE',

  UPDATE_IDEA_REQUEST: "UPDATE_IDEA_REQUEST",
  UPDATE_IDEA_SUCCESS: 'UPDATE_IDEA_SUCCESS',
  UPDATE_IDEA_FAILURE: 'UPDATE_IDEA_FAILURE',
  
  PUT_TITLE_DESCRIPTION: 'PUT_TITLE_DESCRIPTION',
  PUT_CANVAS: 'PUT_CANVAS',
  PUT_CANVAS_VALUE: 'PUT_CANVAS_VALUE',
  PUT_LINKS: 'PUT_LINKS',
  PUT_SET_IMAGES: 'PUT_SET_IMAGES',
  PUT_CONTRIBUTORS: 'PUT_CONTRIBUTORS',

  CLEAR_UPTADE_IDEA_DETAILS: 'CLEAR_UPTADE_IDEA_DETAILS',

  REMOVE_IMAGE: 'REMOVE_IMAGE',
  SET_PUT_SELECTED_BUSINESS: 'SET_PUT_SELECTED_BUSINESS',
  PUT_SET_VIDEO: 'PUT_SET_VIDEO',
  REMOVE_VIDEO: 'REMOVE_VIDEO',
  PUT_SET_FILE: 'PUT_SET_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_EDIT_IDEA_CHALLENGES: "SET_EDIT_IDEA_CHALLENGES",
  SELECT_EDIT_IDEIA_CHALLENGE: "SELECT_EDIT_IDEIA_CHALLENGE",
  CHANGE_IDEIA_EDIT_FIELD: "CHANGE_IDEIA_EDIT_FIELD",
  ADD_IDEIA_EDIT_LINK: "ADD_IDEIA_EDIT_LINK",
  REMOVE_IDEIA_EDIT_LINK: "REMOVE_IDEIA_EDIT_LINK",
  CHANGE_IDEA_EDIT_LINK: "CHANGE_IDEA_EDIT_LINK",
};

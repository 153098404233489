import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';

import defaultStyle from './defaultStyle';
import { userService } from '../../../services';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: '0.9rem',
    color: theme.palette.neutrals.low.medium,
    fontWeight: 600,
  },
  error:{
    color: theme.palette.auxiliary.red.main,
    fontSize: 12,
  }
}));

export function MentionsUsers({ onAddMentions, isEdition = false }) {
  const { ideaCreate, ideaUpdate } = useSelector(status => status);
  const classes = useStyles();
  const [mentionValue, setMentionValue] = useState('');
  const [mentioned, setMentioned] = useState([]);
  const [errors, setErrors] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (!isEdition) {
      if (ideaCreate.contributors.length) {
        if (ideaCreate.contributors[0].hasOwnProperty('name'))
        {
          const mentions = ideaCreate.contributors.map(({ name, _id }) => `@[${name.replace(', ', '')}](${_id})`).reduce((acc, crr) => acc + crr, []);
          ideaCreate.contributors.length && setMentionValue(`${mentions}`);
        }
        else
        {
      const mentions = ideaCreate.contributors.map(({ display, id }) => `@[${display.replace(', ', '')}](${id})`).reduce((acc, crr) => acc + crr, []);
      ideaCreate.contributors.length && setMentionValue(`${mentions}`);
        }
      }
    }
    if (isEdition) {
      if (ideaUpdate.contributors.length) {
        ideaUpdate.contributors.forEach(id => userService.getUserById(id).then(({ name, _id }) => {
          setMentionValue(prev => prev + `@[${name}](${_id})`);
          setMentioned(prev => [...prev, { display: `${name}, `, id: _id }])
        }));
      }
    }
  }, []);


  function fetchContribuitor(query, callback) {
    if (!query) return;
    userService
      .searchContribuitor(query)
      .then((contribuitors) =>
        contribuitors.map((contribuitor) => ({
          display: contribuitor.name,
          id: contribuitor._id,
        }))
      )
      .then(callback);
  }

  function handleMentionsChange({ target: { value } }, _newValue, _newPlainTextValue, mentions) {
    let indexOfLastMentions = 0;
    let count;

    if (mentions.length) {
      const lastMentions = `@[${mentions[mentions.length -1].display.replace(', ', '')}](${mentions[mentions.length -1].id})`;
      indexOfLastMentions = value.indexOf(lastMentions) + lastMentions.length;
      count = value.substring(indexOfLastMentions, value.length);
    } else {
      count = value;
    }
    
    if (count.indexOf('@') === -1) {
      setErrors(count)
    }

    if(mentions.length) {
      setMentioned(mentions)
    }

   setMentionValue(value);
  }


  function handleBlur(data) {
    onAddMentions(data); 
  };

  return (
    <div className={classes.box}>
      <div className={classes.title}>{t('participantes').toUpperCase()}</div>
      <MentionsInput
        singleLine
        allowSpaceInQuery
        onChange={handleMentionsChange}
        value={mentionValue}
        style={defaultStyle}
        placeholder={t('mencione_outros_membros_que_participarao_desta_ideia')}
        onBlur={() => {
          const mention = mentionValue === "" ? [] : mentioned;
          handleBlur(mention);
        }}
      >
        <Mention
          trigger="@"
          displayTransform={(_id, display) => `${display}, `}
          data={fetchContribuitor}
          style={{ color: theme.palette.primary.main }}
        />
      </MentionsInput>
       {errors ? (
        <div className={classes.error}>
          Digite @ e em seguida o nome do participante
        </div>
      ) : null}
    </div>
  );
}


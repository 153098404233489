import React from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  makeStyles,
  InputAdornment,
  Hidden,
} from '@material-ui/core';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { userActions } from '../../actions';
import { FooterAuthentication } from '../../components/Shared/FooterAuthentication';

import Mail from '@material-ui/icons/Mail';
import Person from '@material-ui/icons/Person';

import logoImagine from '../../assets/imgs/logo.svg'; 
import loginImage from '../../assets/imgs/reset-password-email.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  loginGrid: {
    backgroundColor: 'white',
  },
  loginContainer: {
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  loginTitle: {
    fontSize: 28.83,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: 14,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  spaceBottom: {
    paddingBottom: theme.spacing(4),
  },
  presentation: {
    padding: theme.spacing(0, 12),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoImagine: {
    width: '100%',
    maxWidth: 140,
    marginRight: 30,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  logoCustomer: {
    width: '100%',
    maxWidth: 226,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  containerLogoImagine: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  flexIcon: {
    display: 'flex',
    alignContent: 'center',
  },
  iconSpacingRight: {
    marginRight: theme.spacing(1),
  },
  resetPassword: {
    width: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.secondary.dark,
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
}));

function RecoverPasswordPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });

  function onSubmit({email}) {
    dispatch(
      userActions.passwordRecover(
        email,
        t('email_enviado'),
        t('email_invalidao')
      )
    );
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        <Grid item xs={12} md={6} lg={4} className={classes.loginGrid}>
          <div className={classes.loginContainer}>
            <div>
              <div className={clsx(classes.title, classes.loginTitle)}>
                {t('esqueceu_a_senha')}
              </div>
              <div
                className={clsx(classes.subtitle, classes.spaceBottom)}
                style={{ fontWeight: 'bold' }}
              >
                {t('recupere_sua_senha')}
              </div>
              <div className={clsx(classes.subtitle, classes.flexIcon)}>
                <Mail className={classes.iconSpacingRight} color="primary" />
                {t('email_de_contanto')}
              </div>
              <div className={clsx(classes.subtitle, classes.spaceBottom)}>
                {t('informe_o_seu')} <b>e-mail</b>{' '}
                {t('para_enviarmos_um_link_de_recuperacao_de_senha')}
              </div>
              

              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  type="text"
                  name="email"
                  id="email"
                  placeholder="e-mail"
                  variant="filled"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  className={classes.spaceBottom}
                  inputRef={register({
                    required: t('o_campo_email_e_obrigatorio'),
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  style={{ marginBottom: 15 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('enviar_link_de_recuperação')}
                </Button>
                
                <Button 
                  style={{ marginBottom: 15 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.resetPassword}
                >
                  {t('reenviar_link')}
                </Button>
              </form>
            </div>
            <div className={classes.footerContainer}>
              <FooterAuthentication />
            </div>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item xs={8} md={6} lg={8} className={classes.presentation}>
            <div className={classes.containerLogoImagine}>

              <img
                className={classes.logoImagine}
                src={logoImagine}
                alt="logo-imagine"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <img style={{ width: '70%' }} src={loginImage} alt="login"></img>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
}

export {RecoverPasswordPage};
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  Box,
  useTheme
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
  },
  card: {
    padding: theme.spacing(3),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`
  },
  status: {
    color: theme.palette.neutrals.high.main,
    fontSize: 11.24,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
  },
  contentBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3)
  },
  totalNumber: {
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  description: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 14,
    textAlign: 'right',
    fontWeight: 600,
    textTransform: 'lowercase'
  },
}));

function PortifolioCardsStatus() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { tasks } = useSelector((state) => state.portfolioDetails);
  const [ firstStatus, setFirstStatus ] = useState({});
  const [ secondStatus, setSecondStatus ] = useState({});
  const [ thirdStatus, setThirdStatus ] = useState({});
  const { projectTasksStatus } = useSelector((state) => state.domains);
 
  useEffect(() => {
    if (projectTasksStatus) {
      setFirstStatus({
        ...projectTasksStatus.filter(item => item.order === 1)[0],
        background: theme.palette.secondary.dark
      })

      setSecondStatus({
        ...projectTasksStatus.filter(item => item.order === 2)[0],
        background: theme.palette.info.main
      })

      setThirdStatus({
        ...projectTasksStatus.filter(item => item.order === 3)[0],
        background: theme.palette.auxiliary.green.main
      })
    }
  }, [projectTasksStatus])

  return (
    <Grid container spacing={2} className={classes.root}>
      { tasks.items && projectTasksStatus && (
        <>
          <Grid item md={4} xs={12}>
            <Card className={classes.card} elevation={0}>
              <span className={classes.status} style={{ backgroundColor: firstStatus?.background }}>
                { firstStatus?.name }
              </span>
              <Box className={classes.contentBottom}>
                <span className={classes.totalNumber}>
                  { tasks.items[firstStatus?.code]?.length }
                </span>
                <Typography className={classes.description}>
                  {t('tarefas')}{' '}{ firstStatus?.name }
                </Typography>
              </Box>
            </Card>
          </Grid>
          
          <Grid item md={4} xs={12}>
            <Card className={classes.card} elevation={0}>
              <span className={classes.status} style={{ backgroundColor: secondStatus?.background }}>
                { secondStatus?.name }
              </span>
              <Box className={classes.contentBottom}>
                <span className={classes.totalNumber}>
                  { tasks.items[secondStatus?.code]?.length }
                </span>
                <Typography className={classes.description}>
                  {t('tarefas')}{' '}{ secondStatus?.name }
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item md={4} xs={12}>
            <Card className={classes.card} elevation={0}>
              <span className={classes.status} style={{ backgroundColor: thirdStatus?.background }}>
                { thirdStatus?.name }
              </span>
              <Box className={classes.contentBottom}>
                <span className={classes.totalNumber}>
                  { tasks.items[thirdStatus?.code]?.length }
                </span>
                <Typography className={classes.description}>
                  {t('tarefas_feitas')}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export { PortifolioCardsStatus }
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { infraCss } from './infraCss';
import { truncate } from '../../helpers';
import { tableCss } from '../Tables/TableCss';
import { TableMenu } from '../Tables/TableMenu';
import { DialogAddInfra } from './DialogAddInfra';
import { DialogDeleteInfra } from './DialogDeleteInfra';
import { DialogInfraDetails } from './DialogInfraDetails';
import { DialogAssociationInfra } from './DialogAssociationInfra';
import { infraStructureActions } from '../../actions/infraStructure.actions';
import { TagInfrastructure } from './TagInfrastructure';

export function InfrastructureRow({ row }) {
  const classes = infraCss();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [openDialogEditInfra, setOpenDialogEditInfra] = useState(false);
  const [openDialogDeleteInfra, setOpenDialogDeleteInfra] = useState(false);
  const [openDialogDetailsInfra, setOpenDialogDetailsInfra] = useState(false);
  const [openDialogAssociationInfra, setOpenDialogAssociationInfra] = useState(false);
  const { associations } = useSelector(state => state.infraStructure);

  useEffect(() => {
    if (associations) {
      dispatch(infraStructureActions.loadInfraStructureAssociations(associations.map(item => ({
        ...item,
        local: {
          name: item?.local?.name,
          value: item?.local?._id
        },
        businessUnit: {
          name: item?.businessUnit?.name,
          value: item?.businessUnit?._id
        },
        occupationArea: {
          name: item?.occupationArea?.name,
          value: item?.occupationArea?._id
        }
      }))))
    }
  }, [associations]);

  function handleOpenDialogEditInfra() {
    if (!openDialogEditInfra) {
      dispatch(infraStructureActions.loadInfraStructureDialog(row));
    }
    setOpenDialogEditInfra(!openDialogEditInfra);
  }

  function handleOpenDialogDeleteInfra() {
    setOpenDialogDeleteInfra(!openDialogDeleteInfra);
  }

  function handleOpenDialogDetailsInfra() {
    if (!openDialogDetailsInfra) {
      dispatch(infraStructureActions.getAssociation(row._id));
    }

    setOpenDialogDetailsInfra(!openDialogDetailsInfra);
  }

  function handleOpenDialogAssociationInfra() {
    if (!openDialogAssociationInfra && row.type === 'businessUnit') {
      dispatch(infraStructureActions.getAssociation(row._id));
    }

    setOpenDialogAssociationInfra(!openDialogAssociationInfra);
  }

  return (
    <>
      <DialogInfraDetails
        open={openDialogDetailsInfra}
        onClose={handleOpenDialogDetailsInfra}
        row={row}
      />
      <DialogAddInfra
        open={openDialogEditInfra}
        onClose={handleOpenDialogEditInfra}
        id={row._id}
        isEdit={true}
      />
      <DialogDeleteInfra
        open={openDialogDeleteInfra}
        onClose={handleOpenDialogDeleteInfra}
        row={row}
      />
      <DialogAssociationInfra
        open={openDialogAssociationInfra}
        onClose={handleOpenDialogAssociationInfra}
        row={row}
      />
      <TableRow classes={{ root: tableClasses.tableRow }}>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          {moment(row.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>
          {truncate(row.name, 30)}
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>
          <TagInfrastructure type={row.type} />
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
          {row.type === 'businessUnit' && (
            <IconButton onClick={handleOpenDialogAssociationInfra}>
              <Tooltip title={t('vincular')} aria-label="vincular">
                <InsertLinkIcon />
              </Tooltip>
            </IconButton>
          )}
        </TableCell>
        <TableMenu>
          <MenuList style={{ padding: 0 }}>
            <MenuItem onClick={handleOpenDialogDetailsInfra}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary={
                <Typography className={classes.menuText}>{t('visualizar')}</Typography>
              }/>
            </MenuItem>
            <MenuItem onClick={handleOpenDialogEditInfra}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={
                <Typography className={classes.menuText}>{t('editar')}</Typography>
              }/>
            </MenuItem>
            <MenuItem onClick={handleOpenDialogDeleteInfra}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={
                <Typography className={classes.menuText}>{t('excluir')}</Typography>
              }/>
            </MenuItem>
          </MenuList>
        </TableMenu>
      </TableRow>
    </>
  );
}

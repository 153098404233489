import React, { useEffect } from 'react';
import { Container, Grid, makeStyles, Hidden, Box } from '@material-ui/core';
import {
  BackNavAdmin,
  RankingTable,
  Loading,
  RankingCard,
  Search,
  RankingMobileFilter,
  SkeletonTableRanking
} from '../../components';
import { RankingFilters } from "./../../components/Shared/FilterMenu"
import { useDispatch, useSelector } from 'react-redux';
import { rankingActions } from '../../actions';
import { RankingInfo } from '../../components';
import { useWindowSize } from '../../hooks';

import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function RankingPage() {
  const dispatch = useDispatch();
  const { paging, ranking, userRanking } = useSelector(
    (state) => state.ranking
  );
  const loading = useSelector((state) => state.ranking.loading);
  const { _id } = useSelector((state) => state.user);
  const classes = useStyles();
  const [width] = useWindowSize();

  useEffect(() => {
    dispatch(
      rankingActions.getRanking(paging.filter, 1, paging.sort, paging.limit)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadMoreRanking() {
    if (!loading) {
      var newPage = paging.page === 1 ? 2 : paging.page;
      dispatch(
        rankingActions.getRankingMobile(
          paging.filter,
          newPage,
          paging.sort,
          paging.limit
        )
      );
    }
  }

  function searchRanking(query) {
    const newFilter = {
      period: paging.filter.period,
      userName: query,
    };
    dispatch(
      rankingActions.getRanking(newFilter, 1, paging.sort, paging.limit)
    );
  }

  function handleFilter(value) {
    const newFilter = {
      period: value,
      userName: paging.filter.userName,
    };
    dispatch(
      rankingActions.getRanking(newFilter, 1, paging.sort, paging.limit)
    );
    dispatch(rankingActions.setFilterPeriod(value));
  }

  function renderTable() {
    if (loading) {
      return <SkeletonTableRanking />
    } else {
      if (ranking.length && _id) {
        return <RankingTable rankings={ranking} paging={paging} userId={_id} />;
      }
    }
  }

  function renderCards() {
    if (ranking.length && _id) {
      return (
        <InfiniteScroll
          dataLength={ranking.length}
          next={loadMoreRanking}
          hasMore={true}
          scrollThreshold={'90%'}
          loader={
            loading && (
              <div className={classes.containerLoading}>
                <Loading />
              </div>
            )
          }
        >
          {ranking.map((ranking, index) => (
            <RankingCard key={index} ranking={ranking} userId={_id} />
          ))}
        </InfiniteScroll>
      );
    }

    return null;
  }

  return (
    <>
      <BackNavAdmin title="ranking" />
      <Container maxWidth="lg" className={classes.root}>
        <div style={{ marginBottom: 40 }}>
          {userRanking && (
            <RankingInfo
              points={userRanking.score}
              ideas={userRanking.ideas}
              placing={userRanking.position}
            />
          )}
        </div>
        <Hidden smDown>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <RankingFilters paging={paging} />
              <Search
              query={searchRanking}
              placeholder="Pesquise por nome do colaborador"
            />
          </Box>
        </Hidden>
        <Hidden smUp>
          <div className={classes.filter}>
            <RankingMobileFilter onHandleFilter={handleFilter} />
            <Search
              query={searchRanking}
              placeholder="Pesquise por nome do colaborador"
            />
          </div>
        </Hidden>
        <Grid container>
          <Grid item xs={12}>
            {width > config.RESPONSIVE_MOBILE ? renderTable() : renderCards()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { RankingPage };

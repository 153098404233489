import { ideaUpdateConstants } from '../constants';
import { ideaService } from '../services';
import { history, getFiles } from '../helpers';
import { alertActions } from './alert.actions';

export const ideaUpdateActions = {
  getIdeaDetails,
  updateIdea,
  clearIdeaDetails,
  updateTitleDescription,
  updateCanvas,
  updateCanvasValue,
  updateIdeaLinks,
  updateSetImages,
  removeImage,
  setContributors,
  setSelectedBusiness,
  updateSetVideo,
  removeVideo,
  sendRemoveFiles,
  updateSetFile,
  removeFile,

  setChallenges,
  selectChallenge,
  changeField,
  addLink,
  removeLink,
  changeLink
};

function getIdeaDetails(id, to) {
  return (dispatch) => {
    ideaService.getIdeaDetails(id).then(
      (idea) => {
        dispatch(success(idea));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(payload) {
    return { type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE, error };
  }
}


function clearIdeaDetails() {
  return { type: ideaUpdateConstants.CLEAR_UPTADE_IDEA_DETAILS };
}

function updateIdea(idea, newIdea, successMessage) {
  return (dispatch) => {
    dispatch({
      type: ideaUpdateConstants.UPDATE_IDEA_REQUEST
    })
    
    ideaService.updateIdea(idea, newIdea).then(
      (ideas) => {
        const files = getFiles(newIdea);     
        ideaService.uploadFiles(files, newIdea._id)
          .then(() => {
            dispatch({ type: 'REGISTER_FILES' })
          });
        dispatch(success());
        history.goBack()
        dispatch(alertActions.success(successMessage));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success() {
    return { type: ideaUpdateConstants.UPDATE_IDEA_SUCCESS };
  }

  function failure(error) {
    return { type: ideaUpdateConstants.UPDATE_IDEA_FAILURE, error };
  }
}

function updateTitleDescription(payload) {
  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_TITLE_DESCRIPTION, payload });
  };
}

function updateCanvas(canvas) {
  const payload = {
    canvas,
  };

  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_CANVAS, payload });
  };
};

function updateCanvasValue(canvas) {
  const payload = {
    canvas,
  };

  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_CANVAS_VALUE, payload });
  };
};

function updateIdeaLinks(links) {
  return (dispatch) => dispatch({ type: ideaUpdateConstants.PUT_LINKS, links });
};

function updateSetImages(images) {
  return (dispatch) => {
    const payload = {
      images,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_IMAGES, payload });
  };
}

function removeImage(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_IMAGE, payload });
  };
}

function updateSetVideo(video) {
  return (dispatch) => {
    const payload = {
      video,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_VIDEO, payload });
  };
}

function setContributors(payload) {
  return dispatch => {
    dispatch({ type: ideaUpdateConstants.PUT_CONTRIBUTORS, payload })
  };
}

function setSelectedBusiness(payload) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.SET_PUT_SELECTED_BUSINESS,
      payload
    })
  }
}

function removeVideo(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_VIDEO, payload });
  };
} 

function updateSetFile(file) {
  return (dispatch) => {
    const payload = {
      file,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_FILE, payload });
  };
}

function removeFile(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_FILE, payload });
  };
} 


function sendRemoveFiles(ideaId, fileIds) {
  return (dispatch) => {
    ideaService.removeFiles(ideaId, fileIds).then(() => dispatch({type: 'SEND_REMOVE_FILES'})).catch(error => {
      dispatch({type: 'SEND_REMOVE_FILES_ERROR'});
      console.log(error);
    });
  };
};

function setChallenges(payload) {
  return dispatch => {
    dispatch({ 
      type: ideaUpdateConstants.SET_EDIT_IDEA_CHALLENGES, 
      payload 
    });
  }
}

function selectChallenge(payload) {
  return dispatch => {
    dispatch({ type: ideaUpdateConstants.SELECT_EDIT_IDEIA_CHALLENGE, payload })
  }
}

function changeField(name, value) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CHANGE_IDEIA_EDIT_FIELD,
      payload: { name, value }
    })
  }
}

function addLink() {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.ADD_IDEIA_EDIT_LINK,
    })
  }
}

function removeLink(index) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.REMOVE_IDEIA_EDIT_LINK,
      payload: index
    })
  }
}

function changeLink(value, index) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CHANGE_IDEA_EDIT_LINK,
      payload: {
        value,
        index
      }
    })
  }
}
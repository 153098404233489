import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import makeStyles from '@material-ui/styles/makeStyles';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { SkeletonTeams } from './SkeletonGeneralData';

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    color: theme.palette.neutrals.low.dark,
  },
  item: {
    marginTop: 16
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  spacingTop: {
    paddingTop: theme.spacing(4)
  },
  iconAuthor: {
    marginLeft: 8,
    fontSize: 15,
    color: theme.palette.auxiliary.yellow.main
  }
}))

export const TeamList = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const { 
    teams, 
    project: { 
      user 
    } 
  } = useSelector(state => state.portfolioDetails);
  const { tasks } = useSelector(state => state.portfolioDetails);

  function loadTeam(loading, component) {
    return (loading ? <SkeletonTeams /> : component);
  };
  
  function filterTasks(userId, code) {
    const taskList = tasks.details?.data !== undefined ? tasks.details?.data.filter(({ responsible, projectId }) => responsible === userId && projectId === id) : [];
    return  code ? taskList.filter(({ taskStatus }) => taskStatus.code === code).length : taskList.length;
  };

  return (
    <Grid item xs={12} className={classes.spacingTop}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle2" className={classes.tableTitle}>{t('participantes')}</Typography>
        </Box>
        <Box style={{ minWidth: '240px' }}>
          <Typography variant="subtitle2" className={classes.tableTitle}>{t('tarefas')}</Typography>
        </Box>
      </Box>
      <Box className={classes.item} display="flex" alignItems="center" justifyContent='space-between'>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Avatar
            alt={user?.name}
            title={user?.name}
            src={user?.attachment?.url}
            className={classes.avatar}
          />
          <Typography variant="body2">{user?.name}</Typography>
          <BeenhereIcon className={classes.iconAuthor} />
        </Box>
        <Box style={{ minWidth: '240px' }}>
          <Typography variant="body2">{`${filterTasks(user?._id, 'CONCLUDED')}/${filterTasks(user?._id)} tarefas feitas`}</Typography>
        </Box>
      </Box>
      {loadTeam(teams.loading, teams.details?.length > 0 && (
        <>
          {teams.details.map(({ user: { name, attachment, _id } }, index) => (
            <Box className={classes.item} display="flex" alignItems="center" justifyContent='space-between' key={index}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Avatar
                  alt={name}
                  title={name}
                  src={attachment?.url}
                  className={classes.avatar}
                />
                <Typography variant="body2">{name}</Typography>
              </Box>
              <Box style={{ minWidth: '240px' }}>
                <Typography variant="body2">{`${filterTasks(_id, 'CONCLUDED')}/${filterTasks(_id)} tarefas feitas`}</Typography>
              </Box>
            </Box>
          ))}
        </>
      ))}
    </Grid>
  )
}

import { feedbackConstants } from '../constants';
import { feedbackService } from '../services';
import { alertActions } from './alert.actions';

export const feedbackActions = {
  setFeedback,
  clearFeedback,
  openFeedback,
  sendFeedback,
};

function setFeedback(feedbackText) {
  return (dispatch) => {
    dispatch({ 
      type: feedbackConstants.SET_FEEDBACK_TEXT, 
      payload: feedbackText
    });
  };
}

function clearFeedback() {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.CLEAR_FEEDBACK_TEXT,
    })
  }
}

function openFeedback(payload) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.OPEN_FEEDBACK,
      payload
    })
  }
}

function sendFeedback(feedbackText) {
  return (dispatch) => {
    feedbackService.sendFeedback(feedbackText).then(
      () => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_SUCCESS,
          payload: feedbackText,
        });
        dispatch(alertActions.success('O seu Feedback foi enviado!'))
      },
      (error) => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_FAILURE,
          error
        });
      }
    );
  }
}
import React from 'react';
import { makeStyles, Card, Box } from '@material-ui/core';
import { ImagineGrid } from '../../components';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  },
  wrapper: {
    padding: theme.spacing(1.5, 1.5, 0),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: 40,
    height: 45,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  info: {
    width: '40%',
    height: 45,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  label: {
    width: '40%',
    height: 25,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  card: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(2)
  },
  box: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'space-between',
  }
}));

export function SkeletonInfoDashboard() {
  const classes = useStyles();

  return(
    <ImagineGrid container spacing={2} className={classes.skeletonAnimaton}>
      <ImagineGrid container spacing={2} cols={12} className={classes.wrapper}>
        <ImagineGrid item xs={3} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Card>
        </ImagineGrid>
        <ImagineGrid item xs={3} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Card>
        </ImagineGrid>
        <ImagineGrid item xs={3} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Card>
        </ImagineGrid>
        <ImagineGrid item xs={3} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Card>
        </ImagineGrid>
        <ImagineGrid item xs={6} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Box className={classes.box}>
              <Skeleton variant="text" className={classes.info}/>
              <Skeleton variant="text" className={classes.label}/>
            </Box>
          </Card>
        </ImagineGrid>
        <ImagineGrid item xs={6} className={classes.flex}>
          <Card className={classes.card} elevation={0}>
            <Skeleton variant="text" className={classes.icon}/>
            <Box className={classes.box}>
              <Skeleton variant="text" className={classes.info}/>
              <Skeleton variant="text" className={classes.label}/>
            </Box>
          </Card>
        </ImagineGrid>
      </ImagineGrid>
    </ImagineGrid>
  );
} 
import React from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

const Tab = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
}))(MuiTab);

export function ChallengeDetailsMenu({ type, handleChangeTab, tabChosed }) {
  const { t } = useTranslation();

  return (
    <>
      {type === 'admin' && (
        <Tabs
          centered
          textColor="primary"
          indicatorColor="primary"
          value={tabChosed}
          onChange={(_e, value) => handleChangeTab(value)} 
        >
          <Tab label={t('detalhes')} />
          <Tab label={t('acompanhamento')} />
          <Tab label={t('premiacao')} />
        </Tabs>
      )}
    </>
  );
}
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  Grid,
  Container,
  makeStyles,
} from '@material-ui/core';

import { faqActions } from '../../actions';
import { BackNavAdmin } from '../../components/Shared';
import { SidebarTemplateCrud } from '../../components/Sidebars';
import { TemplateNewItem } from '../../components/TemplateCrud';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  }
}));

const data = [
  {
    label: 'item 01',
    link: '/template-crud'
  },
  {
    label: 'item 02',
    link: '/edit-profile'
  },
  {
    label: 'item 03',
    link: '/template-crud'
  },
]

export function TemplateCrudEditPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(faqActions.getQuestionById(params.id));
  // }, []);

  return (
    <>
      <BackNavAdmin title={t('new item')} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <SidebarTemplateCrud data={data} />
          </Grid>
          <Grid item xs={9}>
            <TemplateNewItem />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardProfile } from '../CardProfile/CardProfile';
import { helpCenterService } from '../../services/help-center.service'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '70%',
    justifyContent: 'space-around',

    overflowY: 'scroll',

  },

})


function Administrators() {
  const classes = useStyles();
  const [administrators, setAdministrators] = useState([])



  useEffect(() => {
    helpCenterService.getAdministrators()
      .then(response => {
        setAdministrators(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [administrators])







  return (
    <div className={classes.container}  >
      {!!administrators && (
        <>
          {
            administrators.map((item) => (
              <CardProfile
                key={item.createdAt}
                name={item.name}
                email={item.email}
                phone={item.phone}
                pictureUrl={item.pictureUrl}
                position={item.position}
              />
            )
            )
          }
        </>
      )



      }
    </div>
  );
}
export { Administrators };





import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { makeStyles } from "@material-ui/core"; 
import { useSelector } from "react-redux"
import { history } from '../../helpers';
const useStyles = makeStyles((theme) => ({
  pointsWalletCard: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "2rem"
  },
  pointsWalletCardName: {
    fontSize: 15,
    fontWeight: "bold",
    cursor: 'pointer',
  },
  pointsWalletCardDescription: {
    fontSize: "12px",
    color: theme.palette.grey.darkest,
  },
  pointsWalletCardPointsTitle: {
    fontSize: "12px",
    color: theme.palette.grey.darkest,
  },
  pointsWalletCardPoints: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  walletIcon: {
    color: theme.palette.secondary.main,
    fontSize: "2rem"
  }
}))

export const SelectedUser = ({ showPoints = false }) => {
  const classes = useStyles();
  const {
    selectedUser,
    selectedProfileType
  } = useSelector(state => state.userFunction)
  const {
    points,
  } = useSelector((state) => state.wallet);

  function getProfileTypeName(profile) {
    switch(profile) {
      case 'admin': 
      return "Administrador(a)"

      case 'colaborator': 
        return "Colaborador(a)"

      case 'manager':
        return "Gestor(a)"

      default: 
        return false;
    }
  }

  function goToProfile() {
    history.push(`/profile/${selectedUser.id}`)
  }
  return (
    <Box className={classes.pointsWalletCard}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Box
            onClick={goToProfile}
            className={classes.pointsWalletCardName}
          >
            {selectedUser.name}
          </Box>
          <Box className={classes.pointsWalletCardDescription}>
            {getProfileTypeName(selectedProfileType)}
          </Box>
        </Grid>
        { showPoints && (
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box display="flex" alignItems="center" height="100%">
                  <AccountBalanceWalletIcon className={classes.walletIcon} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" flexDirection="column">
                  <Box className={classes.pointsWalletCardPointsTitle}>Pontos</Box>
                  <Box className={classes.pointsWalletCardPoints}>{points?.amount?.toFixed(2) || 0}</Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

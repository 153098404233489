import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
}));

function TextAreaCustom(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.characters}>
        <span>
          {props.value?.length || 0}/1024 {t('caracteres')}
        </span>
      </div>
      <TextField
        {...props}
        multiline
        fullWidth
        rows={8}
        variant="filled"
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.labelRoot,
          },
        }}
      />
    </div>
  );
}

export { TextAreaCustom };

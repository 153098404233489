import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux"
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { portfolioActions } from "./../../actions"
import { DialogPortifolio } from './DialogPortifolio';
import { useParams } from 'react-router-dom';
import { portfolioDialogProjectActions } from "./../../actions/portfolio/portfolio-dialog-project.actions"

const useStyles = makeStyles((theme) => ({
  titleDelete: {
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 15,
    color: theme.palette.neutrals.low.dark
  },
  descriptionDelete: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium
  },
  btnFloat: {
    float: 'right',
  },
}));

export function DialogPortfolioDelete({ open, onClose, project }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, success } = useSelector(state => state.portfolioDialogProject);
  const { projectTasksStatus } = useSelector(state => state.domains)
  const { t } = useTranslation();

  useEffect(() => {
    if (success) {
      handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]) 

  function handleClose() {
    dispatch(portfolioDialogProjectActions.clearFlags())
    onClose()
  }

  function handleDeleteTask() {
    dispatch(portfolioDialogProjectActions.deleteProject(project._id, t('projeto_excluido'), projectTasksStatus));
  }

  return (
    <DialogPortifolio
      title={t('excluir_projeto')}
      open={open}
      onClose={onClose}
      content={
        <Grid item xs={12}>
          <Typography className={classes.titleDelete}>
            {t('deseja_excluir_o_projeto')}?
          </Typography>
          <Typography className={classes.descriptionDelete}>
            {t('as_informacoes_sobre_o_projeto_serao_excluidas_permanentemente')}
          </Typography>
        </Grid>
      }
      action={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={handleClose}
              disabled={loading}
            >
              {t('voltar')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnFloat}
              startIcon={<DeleteIcon />}
              onClick={handleDeleteTask}
              disabled={loading}
            >
              {t('excluir_projeto')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}
import { curationConstants } from '../constants';
import update from 'react-addons-update';

const classificationInitialState = {
  dateSort: "",
  occupationAreaSort: ""
}

const INITIAL_STATE = {
  ideas: [],
  loading: false,
  curationTab: curationConstants.CURATION_TAB_ESTEIRA,
  esteiraTab: "AWAITING_QUALIFICATION",
  paging: {
    page: 1,
    query: '',
    total: 0,
    limit: 10,
    sort: { createAt: -1 },
  },
  filters: {
    dateSort: "",
    occupationAreaSort: "",
    loading: false,
    statusCode: [{
        "label": "Aguardando qualificação",
        "value": "AWAITING_QUALIFICATION",
        "checked": false
    },
    {
        "label": "Qualificada",
        "value": "QUALIFIED",
        "checked": false
    },
    {
        "label": "Não qualificada",
        "value": "UNQUALIFIED",
        "checked": false
    },
    {
        "label": "Fila de execução",
        "value": "EXECUTION_QUEUE",
        "checked": false
    },
    {
        "label": "Em execução",
        "value": "EXECUTING",
        "checked": false
    },
    {
        "label": "Cancelada",
        "value": "CANCELED",
        "checked": false
    },
    {
        "label": "Revisão Solicitada",
        "value": "PENDING_REVIEW",
        "checked": false
    },
    {
        "label": "Concluída",
        "value": "CONCLUDED",
        "checked": false
    }],
    challenges: [],
    ...classificationInitialState
  }
};

export function curation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case curationConstants.GET_ALL_IDEAS_PORTFOLIO_REQUEST:
      return { 
        ...state,
        loading: true
      }

    case curationConstants.GET_ALL_IDEAS_PORTFOLIO_SUCCESS:
      return {
        ...state,
        ideas: action.payload.data
      }
      
    case curationConstants.GET_ALL_IDEAS_PORTFOLIO_FAILURE:
      return {
        ...state,
        loading: false
      }

    case curationConstants.SET_STATUS_FIXED:
      return {
        ...state,
        filters: {
          ...state.filters,
          statusCode: [action.payload]
        }
      }

    case curationConstants.SET_CURATION_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: state.filters[action.payload.name].map(item => {
            if (item.value === action.payload.value) {
              return {
                ...item,
                checked: !item.checked
              }
            }

            return item;
          })
        }
      }
    }

    case curationConstants.CHANGE_CURATION_CLASSIFICATION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...classificationInitialState,
          [action.payload.name]: action.payload.value
        }
      }
    }

    case curationConstants.GET_IDEAS_CURATION_REQUEST: {
      return update(state, {
        loading: { $set: true }
      });
    }

    case curationConstants.GET_IDEAS_CURATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        paging: {
          ...state.paging,
          ...action.payload.paging
        },
        ideas: action.payload.data
      }
    }

    case curationConstants.GET_IDEAS_CURATION_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case curationConstants.GET_IDEAS_CURATION_MOBILE_REQUEST: {
      return update(state, {
        loading: { $set: true }
      });
    }

    case curationConstants.GET_IDEAS_CURATION_MOBILE_SUCCESS: {
      return update(state, {
        ideas: { $push: action.payload.ideas.data },

        paging: {
          total: { $set: action.payload.ideas.paging.total },
          page: { $set: parseInt(action.payload.ideas.paging.page) + 1 },
          limit: { $set: action.payload.ideas.paging.limit },
        },
        loading: { $set: false },
      });
    }

    case curationConstants.TOGGLE_FILTER: {
      return update(state, {
        paging: {
          statusCode: { $set: action.payload.filter },
        },
      });
    }

    case curationConstants.SET_STATUS_CODE_TO_FILTER: {
      return update(state, {
        filters: {
          statusCode: { $set: [action.status] }
        }
      });
    }

    case curationConstants.CLEAR_CURATION_FILTERS: {
      return update(state, {
          filters: {$set: INITIAL_STATE.filters }
      });
    }

    case curationConstants.CLEAR_FILTERS_NOT_STATUS: {
      return update(state, {
          filters: {
            occupationAreas: { $set: [] },
            challenges: { $set: [] },
            limit: { $set: 10 },
            page: { $set: 1 },
            dateSort: {$set: ""},
            occupationAreaSort: {$set: ""}
         }
      });
    }

    case curationConstants.LOAD_CURATION_CHALLENGES_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: action.payload.map((item) => ({
            label: item.name,
            value: item._id,
            checked: false
          }))
        }
      }
    }

    case curationConstants.LOAD_CURATION_STATUS_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          statusCode: action.payload
        }
      }
    }

    case curationConstants.LOAD_CURATION_CHALLENGE_FILTER_REQUEST: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true
        }
      }
    }

    case curationConstants.LOAD_CURATION_CHALLENGE_FILTER_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          challenges: action.payload
        }
      }
    }

    case curationConstants.LOAD_CURATION_CHALLENGE_FILTER_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false
        }
      }
    }

    case curationConstants.CLEAR_CURATION_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: INITIAL_STATE.filters[action.payload] 
        }
      }
    }

    case curationConstants.CHANGE_CURATION_PROP: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }

    default: {
      return state;
    }
  }
}

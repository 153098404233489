import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Button, Typography, IconButton, Divider, TextField, makeStyles } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { DialogPortifolio, DialogTitleWithIcon } from './';
import { portfolioActions } from '../../actions';
import { portfolioDialogFinnacialActions } from '../../actions/portfolio/portfolio-dialog-finnacial.actions';
import { MoneyInput } from "./../../components/Shared/PersonalizedInputs/MoneyInput"
import { formatRealToNumber, formatBRLFromFloat } from '../../helpers';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  btnFloat: {
    float: 'right',
  },
  totalTypography: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
  },
}));

export function DialogCreateFinancialPlanning({ open, onClose, projectId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [ totalCost, setTotalCost ] = useState(0);
  const { costs, success, loading, estimatedFinancialReturn } = useSelector((state) => state.portfolioDialogFinnancial);
  const { financialPlanning } = useSelector((state) => state.portfolioDetails);

  useEffect(() => {
    if (costs.length > 0) {
      setTotalCost(formatBRLFromFloat(costs
        .map(item => item.estimatedValue)
        .reduce((previousValue, currentValue) => previousValue + parseFloat(formatRealToNumber(currentValue)), 0))) 
    }
  }, [costs]) 

  useEffect(() => {
    if (success) {
      handleCloseDialog();      
    }
  }, [success])
  
  function handleSetExpense(name, value, index) {
    dispatch(portfolioDialogFinnacialActions.changeFieldExpense(name, value, index));
  }

  function handleAddExpense() {
    dispatch(portfolioDialogFinnacialActions.addNewExpense());
  }

  function handleRemoveExpense(index) {
    return () => {
      dispatch(portfolioDialogFinnacialActions.removeExpense(index));
    }
  }

  function handleSetEstimatedValueReturn(e) {
    dispatch(portfolioDialogFinnacialActions.setEstimatedValueReturn('estimatedFinancialReturn', e.target.value));
  }

  function handleCloseDialog() {
    onClose();
    dispatch(portfolioDialogFinnacialActions.clearDialogFinancialPlanning());
  }  
  
  function handleSubmit() {
    const data = {
      projectId: projectId || id,
      estimatedFinancialReturn: formatRealToNumber(estimatedFinancialReturn),
      costs: costs.map(item => ({
        description: item.description,
        estimatedValue: formatRealToNumber(item.estimatedValue),
      }))
    }

    if (financialPlanning.details.costs.length > 0) {
      dispatch(portfolioDialogFinnacialActions.updateFinancialPlanning(data, t('planejamento_editado')));
    } else {
      dispatch(portfolioDialogFinnacialActions.postCreateFinancialPlanning(data, t('planejamento_financeiro_criado')));
    }
  }

  function handleChangeCost(name, value, index) {
    dispatch(portfolioDialogFinnacialActions.changeFieldExpense(name, value, index));
  }

  return (
    <DialogPortifolio 
      open={open} 
      onClose={handleCloseDialog}
      title={financialPlanning.details !== null ? (t('editar_planejamento_financeiro')) : (t('novo_planejamento_financeiro'))}
      content={
        <>
          <Grid item xs={12}>
            <DialogTitleWithIcon 
              text={t('estrutura_de_custos')}
              icon={<MoneyOffIcon color="primary" />}
            />
          </Grid>         
          {costs.map((item, index) => (
            <>
              <Grid item xs={7} key={item._id}>
                <TextField 
                  fullWidth
                  variant="filled"
                  label={t('despesa')}
                  onBlur={(e) => handleSetExpense('description', e.target.value, index)}
                  defaultValue={item.description}
                />
              </Grid>
              <Grid item xs={4} key={item._id}>
                <MoneyInput 
                  fullWidth
                  variant="filled"
                  label={t('valor_estimado')}
                  onBlur={(e) => handleChangeCost('estimatedValue', e.target.value, index)}
                  value={item.estimatedValue}
                />
              </Grid>
              <Grid item xs={1} key={item._id}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <IconButton onClick={handleRemoveExpense(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </>
          ))}

          <Grid item xs={7}>
            <Typography className={classes.totalTypography}>{t('total')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.totalTypography}>
              R$ {totalCost}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              color="primary"
              startIcon={<AddCircleIcon  />}
              onClick={handleAddExpense}
            >
              {t('adicionar_nova_despesa')}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          
          <Grid item xs={12}>
            <DialogTitleWithIcon 
              text={t('retorno_financeiro')}
              icon={<AttachMoneyIcon color="primary" />}
            />
          </Grid>

          <Grid item xs={12}>
            <MoneyInput 
              fullWidth
              variant="filled"
              id="valor-estimado"
              name="valor estimado"
              label={t('valor_estimado')}
              onBlur={handleSetEstimatedValueReturn}
              value={estimatedFinancialReturn}
            />
          </Grid>
        </>
      }
      action={
        <>
          {financialPlanning.details !== null ? (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.btnFloat}
              disabled={loading}
              onClick={handleSubmit}
            >
              {t('salvar_planejamento')}
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnFloat}
              disabled={loading}
              onClick={handleSubmit}
            >
              {t('criar_planejamento')}
            </Button>
          )}
        </>
      }
    />
  );
}
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from './assets/locales/es/translation.json';
import translationEN from './assets/locales/en/translation.json';
import translationPt_BR from './assets/locales/pt_BR/translation.json';

const fallbackLng = ['pt_BR'];
const availableLanguages = ['pt_BR'];
// const availableLanguages = ['pt_BR', 'en','es'];

const resources = {
  pt_BR: {
    translation: translationPt_BR,
  },
   en: {
     translation: translationEN,
   },
   es: {
     translation: translationES,
   },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: true,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ImagineGrid } from '../../components';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  },
  title: {
    width: '70%',
    height: theme.spacing(4),
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  lineChart: {
    width: '100%',
    height: theme.spacing(29),
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
}));

export function SkeletonLineChart() {
  const classes = useStyles();

  return(
    <ImagineGrid container className={classes.skeletonAnimaton}>
      <ImagineGrid container spacing={1} cols={1} className={classes.wrapper}>
        <Skeleton variant="text" className={classes.title}/>
        <Skeleton variant="text" className={classes.lineChart}/>
      </ImagineGrid>
    </ImagineGrid>
  );
} 
import { timelineConstanst } from '../constants';
import { timelineService } from '../services';
import { userActions } from './user.actions';

export const timelineActions = {
  getTimeline,
  upIdea,
  getCommentsByIdea,
  upCommentIdea,
  removeCommentIdea,
  newComment,
  favoriteIdea,
  sortTimeline,
  getBannersChallenge,
  setSearch,
  clearAll
};

function getTimeline(page, sort, search) {
  return (dispatch) => {
    dispatch(request());
    timelineService.getTimeline(page, sort, search).then(
      (ideas) => {
        dispatch(success(ideas, search));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: timelineConstanst.TIMELINE_REQUEST };
  }

  function success(ideas, search) {
    const payload = {
      ideas: ideas.data,
      paging: {
        ...ideas.paging,
        search,
        hasNextPage: ideas.paging.hasNextPage
      }
    };
    return { type: timelineConstanst.TIMELINE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.TIMELINE_FAILURE, error };
  }
}

function setSearch(search) {
  return dispatch => {
    dispatch({
      type: timelineConstanst.SET_TIMELINE_SEARCH,
      payload: search
    })
  }
}

function sortTimeline(changeSort) {
  const sort = {
    [changeSort]: -1,
  };

  return (dispatch) => {
    timelineService.getTimeline(1, sort, '').then(
      (ideas) => {
        dispatch(success(ideas, sort));
      },
      (error) => {
        dispatch(failure(error, sort));
      }
    );
  };

  function success(ideas, sort) {
    const payload = {
      ideas: ideas.data,
      paging: ideas.paging,
      sort,
    };

    return { type: timelineConstanst.SORT_TIMELINE_CHANGE, payload };
  }

  function failure(error, sort) {
    const payload = {
      error: error,
      sort,
    };
    return { type: timelineConstanst.SORT_TIMELINE_CHANGE_FAILURE, payload };
  }
}

function upIdea(id, index) {
  return (dispatch) => {
    dispatch(request());
    timelineService.upIdea(id).then(
      (idea) => {
        dispatch(success(idea, index));
        dispatch(userActions.getUserDetailsRaking());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: timelineConstanst.UP_IDEA_REQUEST };
  }

  function success(idea, index) {
    const payload = {
      idea,
      index,
    };
    return { type: timelineConstanst.UP_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.UP_IDEA_FAILURE, error };
  }
}

function getCommentsByIdea(id, page, sort, indexIdea) {
  return (dispatch) => {
    timelineService.getCommentsByIdea(id, page, sort).then(
      (response) => {
        dispatch(success(id, response.data, indexIdea, response.paging));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(id, comments, indexIdea, paging) {
    const payload = {
      id,
      comments,
      indexIdea,
      paging,
    };
    return { type: timelineConstanst.COMMENTS_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.COMMENTS_IDEA_FAILURE, error };
  }
}

function upCommentIdea(id, postIndex, commentIndex) {
  return (dispatch) => {
    dispatch(request());
    timelineService.upComment(id).then(
      (comment) => {
        dispatch(success(postIndex, comment, commentIndex));
        dispatch(userActions.getUserDetailsRaking());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: timelineConstanst.UP_COMMENTS_IDEA_REQUEST };
  }

  function success(postIndex, comment, commentIndex) {
    const payload = {
      comment,
      commentIndex,
      postIndex,
    };
    return { type: timelineConstanst.UP_COMMENTS_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.UP_COMMENTS_IDEA_FAILURE, error };
  }
}

function removeCommentIdea(id, postIndex, commentIndex) {
  return (dispatch) => {
    timelineService.removeCommentIdea(id).then(
      () => {
        dispatch(success(postIndex, commentIndex, id));
        dispatch(userActions.getUserDetailsRaking());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(postIndex, commentIndex, id) {
    const payload = {
      postIndex,
      commentIndex,
      id,
    };

    return { type: timelineConstanst.REMOVE_COMMENTS_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.REMOVE_COMMENTS_IDEA_FAILURE, error };
  }
}

function favoriteIdea(id, index) {
  return (dispatch) => {
    timelineService
      .favoriteIdea(id)
      .then((postFavorited) => {
        dispatch(success(postFavorited, index));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(postFavorited, index) {
    const payload = {
      postFavorited,
      index,
    };

    return { type: timelineConstanst.FAVORITE_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.FAVORITE_IDEA_FAILURE, error };
  }
}

function newComment(comment, postIndex) {
  return (dispatch) => {
    timelineService.newComment(comment).then(
      (comment) => {
        dispatch(success(comment, postIndex));
        dispatch(userActions.getUserDetailsRaking());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(comment, postIndex) {
    const payload = {
      comment,
      postIndex,
    };
    return { type: timelineConstanst.CREATE_COMMENT_IDEA_SUCCESS, payload };
  }

  function failure(error) {
    return { type: timelineConstanst.CREATE_COMMENT_IDEA_FAILURE, error };
  }
}

function getBannersChallenge() {
  return (dispatch) => {
    timelineService
      .getBannersChallenge()
      .then((challenges) => {
        dispatch(success(challenges.data));
      })
      .catch((error) => {
        dispatch(failure());
      });
  };

  function success(challenges) {
    const payload = {
      challenges,
    };

    return { type: timelineConstanst.GET_BANNERS_CHALLENGE_SUCCESS, payload };
  }

  function failure() {
    return { type: timelineConstanst.GET_BANNERS_CHALLENGE_FAILURE };
  }
}
function clearAll() {
  return dispatch => {
    dispatch({ type: timelineConstanst.CLEAR_TIMELINE_ALL })
  }
}
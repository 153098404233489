import React from 'react';
import clsx from 'clsx';
import { makeStyles, Card, Box, Divider } from '@material-ui/core';
import { ImagineGrid } from '../../components';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  skeletonAnimaton: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  },
  label: {
    width: '70%',
    height: 20,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  content: {
    width: '70%',
    height: 58,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  card: {
    height: 302,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  box: {
    width: '100%',
    height: '50%',
  },
}));

export function SkeletonInfoUniqueDashboard() {
  const classes = useStyles();

  return(
    <Card className={clsx(classes.card, classes.skeletonAnimaton)} elevation={0}>
      <Box
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        flexDirection="column"
        className={classes.box}
      >
        <Skeleton variant="text" className={classes.label}/>
        <Skeleton variant="text" className={classes.content}/>
      </Box>
      <Divider />
      <Box
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        flexDirection="column"
        className={classes.box}
      >
        <Skeleton variant="text" className={classes.label}/>
        <Skeleton variant="text" className={classes.content}/>
      </Box>
    </Card>
  );
} 
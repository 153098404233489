import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

const defaultTheme = createMuiTheme();

export const theme = createMuiTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1128,
        xl: 1920,
      },
    },
    overrides: {
      MuiInputAdornment: {
        positionStart: {
          marginTop: '0px !important',
        },
      },
      MuiFilledInput: {
        root: {
          fontSize: 14,
          backgroundColor: '#EEF0F2',
          '&:hover': {
            backgroundColor: '#E7EAED'
          }
        }
      },
      MuiInputBase: {
        root: {
          fontSize: 14
        }
      },
      MuiInputLabel: {
        filled: {
          fontSize: 14,
          fontWeight: 600
        }
      },
      MuiButtonBase: {
        root: {
          color: '#aaa',
          padding: '12px 16px',
          borderRadius: 8,
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'lowercase',
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 15,
          },
        }
      },
      MuiListItem: {
        root: {
          color: '#000',
          borderRadius: 0,
        }
      },
      MuiButton: {
        text: {
          padding: '14px 16px',
        },
        root: {
          color: '#aaa',
          fontSize: 16,
          fontWeight: 600,
          padding: '12px 16px',
          borderRadius: 8,
          textTransform: 'lowercase',
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 15,
          },
        },
        outlined: {
          padding: '12px 16px',
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: '#6D6D6D',
          '&$checked': {
            color: '#7E0386',
          },
        }
      },
      MuiTab: {
        root: {
        },
        wrapper: {
          fontSize: 16,
          fontWeight: 'bold',
          textTransform: "unset",
        }
      }
    },
    typography: {
      fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      fontSize: 12,
      h1: {
        fontWeight: 700,
        fontSize: 28,
        textTransform: 'lowercase',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 24,
        }
      },
      h2: {
        fontWeight: 700,
        fontSize: 24,
        textTransform: 'lowercase',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 20,
        }
      },
      h3: {
        fontWeight: 600,
        fontSize: 20,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        }
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: 16,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 14,
        }
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: 14,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        }
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 14,
        }
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        }
      },
      caption: {
        fontWeight: 400,
        fontSize: 12,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10 ,
        }
      },
      label: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: 'uppercase',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10,
        }
      },
      overline: {
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'uppercase',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        }
      },
      helpertext: {
        fontWeight: 500,
        fontSize: 12,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10,
        }
      },
    },
    palette: {
      primary: {
        main: '#0D1756',
        dark: '#002BAC',
        medium: '#0D1756',
        light: '#c3c3e4',
        lightest: '#F3F3F3',
      },
      secondary: {
        main: '#22B8CF',
        dark: '#129FB5',
        medium: '#3BC9DB',
        light: '#CAF5F9',
        lightest: '#E3FAFC',
      },
      neutrals: {
        low: {
          main: '#201B2C',
          dark: '#3E3A4A',
          medium: '#525060',
          light: '#8C8E9B',
          lightest: '#B1B5C0',
        },
        high: {
          dark: '#DFE2E7',
          medium: '#EAECEF',
          light: '#F1F3F5',
          lightest: '#F8F9FA',
          main: '#FFFFFF',
        },
      },
      grey: {
        main: '#E7EAED',
        dark: "#EEF0F2",
        darkest: '#868E96',
      },
      auxiliary: {
        pink: {
          main: '#FF006E',
          light: '#FF9CCE'
        },
        grape: {
          main: '#97359E',
          light: '#C163C7'
        },
        indigo: {
          main: '#4263EB',
          light: '#90A5FC'
        },
        blue: {
          main: '#448AFF',
          light: '#85B3FF'
        },
        cyan: {
          main: '#0097BA',
          light: '#76D3E9'
        },
        green: {
          main: '#0CA678',
          light: '#00CC8D'
        },
        yellow: {
          main: '#FE9900',
          light: '#FFC56C'
        },
        orange: {
          main: '#FF5722',
          light: '#FF8B66'
        },
        red: {
          main: '#FF1744',
          light: '#FF6381'
        },
        gray: {
          main: '#868E96',
          light: '#CED4DA'
        }
      },
      /* TEXT IS DEPRECATED */
      text: {
        primary: '#2F2F2F',
        secondary: '#6D6D6D',
        dark: '#495057'
      },
      /* TEXT IS DEPRECATED */
    },
  },
  ptBR
);

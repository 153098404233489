import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { 
  Card,
  Grid,
  makeStyles, 
  Avatar, 
  Typography,  
} from '@material-ui/core';

import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';
import { history } from '../../helpers';

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  position: {
    color: theme.palette.grey.darkest,
  },
  perfil: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  cursor: {
    cursor: 'pointer'
  }
}));

function FeedIdentity() {
  const classes = useStyle();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  function goToProfile() {
    history.push(`/profile/${user._id}`);
  }

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.gridFlex}>
          {user.attachment?.url ? (
            <Avatar 
              src={user.attachment.url} 
              className={clsx(classes.large, classes.cursor)} 
              onClick={goToProfile}
            />
          ) : (
            <AvatarWithoutPhoto 
              superLarge iconStyle={{ fontSize: 80 }} 
              onClick={goToProfile} 
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" onClick={goToProfile}>
            {user.name}
          </Typography>      
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center" className={classes.position}>
            {user.position}
            {user.businessUnitLocal?.occupationArea?.name && (
              <>{t('em')} {user.businessUnitLocal?.occupationArea?.name}</>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center" className={classes.perfil}>
            {(user.typeProfile.type === 'manager') || 
              (user.typeProfile.type === 'admin') ? (
              <div>{user.typeProfile.description}</div>
            ): null
            }
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export { FeedIdentity };

import React from 'react';
import { Box, Grid, styled, Typography } from '@material-ui/core';
import { ReactComponent as EmptyStatePortfolio } from '../../../assets/imgs/imagine-illustration_empty-state-portfolio.svg';
import { useTranslation } from 'react-i18next';

const Card = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: 200
}));

export default function EmptyCard() {
    const {t} = useTranslation();
    return (
        <Grid item xs={12}>
            <Card>
                <Box
                    height='100%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                >
                    <EmptyStatePortfolio />
                    <Typography>{t('ainda_nao_ha_projetos_nessa_etapa')}</Typography>
                </Box>
            </Card>
        </Grid>
    );
};
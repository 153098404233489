import React, { useEffect, Fragment, useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Container,
  makeStyles,
  Grid,
  Hidden,
  Typography,
  Box,
  TextField
} from "@material-ui/core"
import { history, searchToObject } from "./../../helpers"
import { 
  BackNavAdmin,
  SidebarManageAccess,
  UserEdit,
  SelectedUser
} from "../../components"
import { useSelector, useDispatch } from "react-redux"
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { userFunctionActions, alertActions } from "./../../actions"
import { useParams } from "react-router-dom";

const filterOptions = createFilterOptions({
  limit: 100,
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  select: {
    width: "100%",
    fontSize: 14,
  },
  label: {
    fontSize: 12,
    fontWeight: 600
  },
  container: {
    marginTop: 32
  },
  containerTitleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  titleIcon: {
    width: 40,
    marginRight: 16,
    height: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerTitle: {
    fontWeight: 600
  },
}));

export const AdminUserEditPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const params = useParams();
  const {
    loadingUser,
    selectedUser,
    userType,
    sendEmailPasswordSuccess,
    sendEmailPasswordError
  } = useSelector(state => state.userFunction)
  const { t } = useTranslation();
  const { imagineLocals } = useSelector(state => state.domains)

  useEffect(() => {
    if (!selectedUser) {
      if (params.id && !loadingUser) {
        dispatch(userFunctionActions.loadPage(params.id, imagineLocals));
      } else {
        if (!userType) {
          dispatch(userFunctionActions.getUserType())
        } else {
          dispatch(userFunctionActions.getAllUsers(userType))
        }
      }
    } else {
      dispatch(userFunctionActions.loadPage(selectedUser.id, imagineLocals));
    }
  }, [params.id])


  useEffect(() => {
    if (selectedUser && userType) {
      dispatch(userFunctionActions.getAdminUserEdit(selectedUser.id));
      dispatch(userFunctionActions.getAllUsers(userType))
    }
  }, [selectedUser.id, dispatch]);
  
  useEffect(() => {
    if (sendEmailPasswordSuccess) {
      dispatch(alertActions.success("Link de recuperação enviado!"));
    }

    if (sendEmailPasswordError) {
      dispatch(alertActions.error("Erro ao enviar o link de recuperação"));
    }
  }, [sendEmailPasswordSuccess, sendEmailPasswordError])


  return (
    <>
      <BackNavAdmin title={t('editar_dados_do_usuario')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
          <Hidden smDown>
            <Grid item xs={3}>
              <SidebarManageAccess />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={9}>
            { selectedUser && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SelectedUser />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box className={classes.containerTitleWrapper}>
                      <Box className={classes.titleIcon}>
                        <SettingsIcon color={"primary"} />
                      </Box>
                      <Typography variant="h5" component="h1" className={classes.containerTitle}>{t('editar_dados_do_usuario')}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <UserEdit loadAdminUserEdit={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
};

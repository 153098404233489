import React, { useEffect } from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { PortfolioCollapsibleRow, StatusMenu } from '../Portfolio';
import { tableCss } from './TableCss';
import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { portfolioActions, alertActions } from '../../actions';
import { SkeletonTable } from '../Skeletons';

function PortifolioTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tasks, successUpdateTask, taskTabChosed } = useSelector(state => state.portfolioDetails)

  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);

  const columns = [
    { id: 'actions', label: '' },
    { id: 'task', label: t('tarefa') },
    { id: 'responsable', label: t('responsavel') },
    { id: 'date', label: t('prazo') },
    { id: 'status', label: t('status') },

  ];
  function handleChangePage(_event, page) {
    let newPage = page + 1;
    dispatch(portfolioActions.setTaskTableFilter("page", newPage));
  }

  async function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(portfolioActions.setTaskTableFilter("limit", limit));
  }
  
  useEffect(() => {
    if (successUpdateTask) {
      dispatch(alertActions.success('Atualização de status realizado com sucesso!'))
      dispatch(portfolioActions.clearUpdateTaskFlags())
    }
  }, [successUpdateTask, dispatch])

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="tabelas de tarefas"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            { tasks?.items[taskTabChosed]?.map((item, key) => (
              <PortfolioCollapsibleRow key={item._id} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={tasks.items?.paging?.total || 0}
        rowsPerPage={tasks.items?.paging?.limit || 0}
        page={tasks.items?.paging?.page - 1 || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
      />
    </Paper>
  );
}

export { PortifolioTable };

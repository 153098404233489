import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';

import { infraCss } from './infraCss';
import { DialogResponsive } from './DialogResponsive';
import { TagInfrastructure } from './TagInfrastructure';
import { SkeletonInfraDetails } from '../Skeletons/SkeletonInfraDetails';
import { infraStructureActions } from '../../actions/infraStructure.actions';

export const DialogInfraDetails = ({ open, onClose, row }) => {
  const classes = infraCss();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { associations, loadingAssociations } = useSelector((state) => state.infraStructure);

  function handleCloseDialog() {
    onClose();
    dispatch(infraStructureActions.clearAssociations());
  }

  return (
    <DialogResponsive open={open} onClose={handleCloseDialog} title={t('detalhes_da_infraestrutura')}>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.info}>{t('dados')}</Typography>
            <Box paddingTop="1rem">
              <TagInfrastructure type={row.type} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.title}>{t('data_de_cadastro')}</Typography>
            <Typography className={classes.text}>{moment(row.date).format('DD/MM/YYYY')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.title}>{t('nome_da_infraestrutura')}</Typography>
            <Typography className={classes.text}>{row.name}</Typography>
          </Grid>    
          <Grid item xs={12}>
            {loadingAssociations ? (
              <SkeletonInfraDetails />
            ) : (
              <>
                {associations?.length > 0 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography className={classes.info}>{t('vinculos')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.title}>{t('unidade_de_negocio')}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.title}>{t('local')}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.title}>{t('area_de_atuacao')}</Typography>
                    </Grid> 
                    <Grid item xs={12}>
                      {associations?.map((item, index) => (
                        <Grid container spacing={4} key={index} display="flex" alignItems="center">
                          <Grid item xs={4}>
                            <Typography className={classes.text}>{item?.businessUnit?.name}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography className={classes.text}>{item?.local?.name}</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography className={classes.text}>{item?.occupationArea?.name}</Typography>
                          </Grid>
                          {index !== associations?.length -1 &&
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                              <Divider />
                            </Grid>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </DialogResponsive>
  )
}

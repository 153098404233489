import { challengeConstants } from '../constants';
import update from 'react-addons-update';
import filesize from 'filesize';

const initialState = {
  challenges: [],
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    filter: [
      {
        field: 'name',
        value: '',
      },
      {
        field: 'statusChallenge.code',
        value: 0,
      },
    ],
  },
  challengeDetail: {},
  loading: false,
  progress: {
    challenge: 'active',
    imagem: 'disabled',
    criteria: 'disabled',
    dates: 'disabled',
    award: 'disabled',
    progress: 0,
  },
  newChallenge: {
    imagesToRemove: [],
    requirement: '',
    name: '',
    goal: '',
    description: '',
    startDate: null,
    dueDate: null,
    resultDate: null,
    thumbnail: {},
    banner: {},
    rewards: [
      {
        score: null,
        description: '',
        id: '',
      },
    ],
  },
  challengeTableIdeas: {
    ideas: [],
    paging: {
      total: 1,
      limit: 10,
      page: 1,
      sort: { timelineDate: -1 },
      statusCode: [
        'CONCLUDED',
        'CANCELED',
        'AWAITING_QUALIFICATION',
        'UNQUALIFIED',
        'PATENT_PENDING',
        'EXECUTION_QUEUE',
        'EXECUTING',
        'QUALIFIED',
        'DRAFT',
      ],
    },
  },
  winners: [],
  winnerCard: []
};

function handleProgress(progress, step) {
  if (progress === 100) {
    return 100;
  }

  if (progress < 100 && step !== 'concluded') {
    return progress + 20;
  }

  return progress;
}

export function challenge(state = initialState, action) {
  switch (action.type) {
      case challengeConstants.POST_CHALLENGE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case challengeConstants.POST_CHALLENGE_FAILURE:
      return update(state, {
        loading: { $set: false },
      });
    case challengeConstants.GET_CHALLENGES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case challengeConstants.GET_CHALLENGES_SUCCESS: {
      const filter = [
        { field: 'name', value: action.payload.query || '' },
        { field: 'statusChallenge.code', value: action.payload.status },
      ];
      return update(state, {
        paging: {
          page: { $set: action.payload.page + 1 },
          filter: { $set: filter },
        },
        challenges: {
          $set: action.payload.challenges.data,
        },
        loading: { $set: false },
      });
    }

    case challengeConstants.GET_CHALLENGES_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_MORE_CHALLENGES_SUCCESS: {
      const filter = [
        { field: 'name', value: action.payload.query || '' },
        { field: 'statusChallenge.code', value: action.payload.status },
      ];
      return update(state, {
        paging: {
          page: { $set: action.payload.page + 1 },
          filter: { $set: filter },
        },
        challenges: {
          $push: action.payload.challenges.data,
        },
        loading: { $set: false },
      });
    }

    case challengeConstants.GET_MORE_CHALLENGES_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_CHALLENGE_BY_ID_SUCCESS:
      return update(state, {
        challengeDetail: { $set: action.payload.challenge },
        paging: {
          id: { $set: action.payload.challenge._id },
        },
      });

    case challengeConstants.GET_CHALLENGE_BY_ID_FAILURE:
      return state;

    case challengeConstants.GET_IDEAS_CHALLENGE_SUCCESS:
      return update(state, {
        challengeTableIdeas: {
          ideas: { $set: action.payload.ideas.data },
          paging: {
            total: {
              $set: action.payload.ideas.paging.total,
            },
            limit: {
              $set: action.payload.ideas.paging.limit,
            },
            page: {
              $set: parseInt(action.payload.ideas.paging.page),
            },
          },
        },
      });

    case challengeConstants.GET_IDEAS_CHALLENGE_FAILURE:
      return state;


    case challengeConstants.GET_CHALLENGE_EDIT_SUCCESS: {
      return update(state, {
        newChallenge: {
          requirement: { $set: action.payload.challenge.requirement },
          name: { $set: action.payload.challenge.name },
          goal: { $set: action.payload.challenge.goal },
          description: { $set: action.payload.challenge.description },
          startDate: { $set: action.payload.challenge.startDate },
          dueDate: { $set: action.payload.challenge.dueDate },
          resultDate: { $set: action.payload.challenge.resultDate },
          thumbnail: {
            name: {
              $set: action.payload.challenge?.thumbnail?.fileName
                ? action.payload.challenge.thumbnail.fileName
                : '',
            },
            readableSize: {
              $set: action.payload.challenge?.thumbnail?.size
                ? filesize(action.payload.challenge.thumbnail.size)
                : '',
            },
            preview: {
              $set: action.payload.challenge?.thumbnail?.url
                ? action.payload.challenge.thumbnail.url
                : '',
            },
          },
          banner: {
            name: {
              $set: action.payload.challenge?.banner?.fileName
                ? action.payload.challenge.banner.fileName
                : '',
            },
            readableSize: {
              $set: action.payload.challenge?.banner?.size
                ? filesize(action.payload.challenge.banner.size)
                : '',
            },
            preview: {
              $set: action.payload.challenge?.banner?.url
                ? action.payload.challenge.banner.url
                : '',
            },
          },
          rewards: { $set: action.payload.challenge.rewards },
          _id: { $set: action.payload.challenge._id },
        },
      });
    }

    case challengeConstants.GET_CHALLENGE_EDIT_FAILURE:
      return state;






    case challengeConstants.SET_PROP_CHALLENGE: {
      return update(state, {
        newChallenge: {
          [action.payload.input]: { $set: action.payload.value },
        },
      });
    }

    case challengeConstants.SET_CHALLENGE: {
      const progress = handleProgress(
        state.progress.progress,
        state.progress.challenge
      );

      return update(state, {
        newChallenge: {
          name: { $set: action.payload.challenge.name },
          description: { $set: action.payload.challenge.description },
          goal: { $set: action.payload.challenge.goal },
        },
        progress: {
          challenge: { $set: 'concluded' },
          imagem: {
            $set:
              state.progress.imagem !== 'concluded' ? 'active' : 'concluded',
          },
          progress: { $set: progress },
        },
      });
    }

    case challengeConstants.SET_CRITERIA: {
      const { criteria } = action.payload;
      const progress = handleProgress(
        state.progress.progress,
        state.progress.criteria
      );

      return update(state, {
        newChallenge: {
          requirement: { $set: criteria },
        },
        progress: {
          criteria: { $set: 'concluded' },
          dates: {
            $set: state.progress.dates !== 'concluded' ? 'active' : 'concluded',
          },
          progress: { $set: progress },
        },
      });
    }

    case challengeConstants.SET_IMAGEM:
      return update(state, {
        newChallenge: {
          [action.payload.type]: { $set: action.payload.imagem },
        },
      });

    case challengeConstants.REMOVE_IMAGEM:
      return update(state, {
        newChallenge: {
          [action.payload.type]: { $set: {} },
        },
      });

    case challengeConstants.NEXT_CARD_IMAGE: {
      const progress = handleProgress(
        state.progress.progress,
        state.progress.imagem
      );

      return update(state, {
        progress: {
          imagem: { $set: 'concluded' },
          criteria: {
            $set:
              state.progress.criteria !== 'concluded' ? 'active' : 'concluded',
          },
          progress: { $set: progress },
        },
      });
    }

    case challengeConstants.SET_DATES: {
      const progress = handleProgress(
        state.progress.progress,
        state.progress.dates
      );

      return update(state, {
        progress: {
          dates: { $set: 'concluded' },
          award: {
            $set: state.progress.award !== 'concluded' ? 'active' : 'concluded',
          },
          progress: { $set: progress },
        },
      });
    }

    case challengeConstants.SET_DATE: {
      return update(state, {
        newChallenge: {
          [action.payload.input]: { $set: action.payload.date },
        },
      });
    }

    case challengeConstants.SET_AWARD: {
      const progress = handleProgress(
        state.progress.progress,
        state.progress.award
      );

      const rewards = action.payload.rewards.map((award, index) => ({
        index: index,
        description: award.description,
        score: parseInt(award.score),
      }));

      return update(state, {
        newChallenge: {
          rewards: { $set: rewards },
        },
        progress: {
          award: { $set: 'concluded' },
          progress: { $set: progress },
        },
      });
    }

    case challengeConstants.ADD_AWARD:
      return update(state, {
        newChallenge: {
          rewards: { $push: [{ score: null, description: '' }] },
        },
      });

    case challengeConstants.REMOVE_AWARD:
      const rewards = state.newChallenge.rewards.filter(
        (award) => parseInt(award.index) !== action.payload.index
      );

      return update(state, {
        newChallenge: {
          rewards: {
            $set: rewards,
          },
        },
      });

    case challengeConstants.POST_CHALLENGE_SUCCESS:
      return update(state, {
        newChallenge: {
          requirement: { $set: '' },
          name: { $set: '' },
          goal: { $set: '' },
          description: { $set: '' },
          startDate: { $set: null },
          dueDate: { $set: null },
          resultDate: { $set: null },
          thumbnail: { $set: {} },
          banner: { $set: {} },
          rewards: {
            $set: [
              {
                score: null,
                description: '',
              },
            ],
          },
        },
        progress: {
          challenge: { $set: 'active' },
          imagem: { $set: 'disabled' },
          criteria: { $set: 'disabled' },
          dates: { $set: 'disabled' },
          award: { $set: 'disabled' },
          progress: { $set: 0 },
        },
      });

    case challengeConstants.CLEAR_FORM: {
      return update(state, {
        newChallenge: {
          requirement: { $set: '' },
          name: { $set: '' },
          goal: { $set: '' },
          description: { $set: '' },
          startDate: { $set: null },
          dueDate: { $set: null },
          resultDate: { $set: null },
          thumbnail: { $set: {} },
          banner: { $set: {} },
          rewards: {
            $set: [
              {
                score: 0,
                description: '',
              },
            ],
          },
        },
      });
    }
    case challengeConstants.CLEAR_PROGRESS: {
      return update(state, {
          progress: {
            challenge: {$set: 'active'},
            imagem: {$set: 'disabled'},
            criteria: {$set: 'disabled'},
            dates: {$set: 'disabled'},
            award: {$set: 'disabled'},
            progress: {$set: 0},
        },
      });
    };

    case challengeConstants.SET_FILTER_COLABORATOR:
      const status = state.challengeTableIdeas.paging.statusCode.filter(
        (s) => s !== ('AWAITING_QUALIFICATION' || 'CANCELED' || 'DRAFT')
      );

      return update(state, {
        challengeTableIdeas: {
          paging: {
            statusCode: { $set: status },
          },
        },
      });

    case challengeConstants.SET_WINNERS:
      return update(state, {
        winners: {
          [action.payload.index]: {$set: {
            id: action.payload.idea?._id,
            position: action.payload?.index,
            idea: action.payload?.idea,
          }}
        }
      });
    case challengeConstants.CLEAR_SELECT_WINNER:
      const winnersFiltered = state.winners.filter(winner => winner.position !== action.index);
      return update(state, {
        winners: { $set: winnersFiltered }
        });
    case challengeConstants.SET_WINNER_CARD:
      return update(state, {
        winnerCard: {
           [action.payload.position]: {$set: action.payload}
        }
      });
    case challengeConstants.SET_IMAGE_TO_REMOVE:
      return update(state, {
        newChallenge: {
          imagesToRemove: { $push: [action.payload.type] }
        }
      });
    case challengeConstants.CLEAR:
      return update(state, { $set: initialState });
    default:
      return state;
  }
}

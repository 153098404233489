import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Avatar, Box, Grid, styled, Typography, LinearProgress, withStyles, makeStyles } from '@material-ui/core';
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup';
import { InsertInvitation } from '@material-ui/icons';

import { truncate } from '../../../helpers';
import CardMenu from '../CardMenu/CardMenu';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 30,
        height: 30,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    },
    textDate: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.neutrals.low.medium
    }
}));

const Card = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 200
}));

const Title = styled(Typography)(({ theme }) => ({
    wordBreak: 'break-all',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark
}));

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      width: '85%',
      height: 7,
      borderRadius: 8,
    },
    colorPrimary: {
      backgroundColor: theme.palette.neutrals.high.medium,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  }))(LinearProgress);

  const AvatarGroup = withStyles((theme) => ({
    avatar: {
        width: 30,
        height: 30,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        fontSize: 12,
        fontWeight: 600
    },
  }))(MuiAvatarGroup);

export default function DataCard({ project }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [count, setCount] = useState([0,0]);

    return (
        <Grid item xs={12}>
            <Card>
                <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                        <Title>{truncate(project.name, 56)}</Title>
                        <CardMenu project={project}/>
                    </Box>
                    <Box>
                        <Typography variant="body2" title={project.description}>
                            {truncate(project.description, 46)}
                        </Typography>
                    </Box>

                    {
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <BorderLinearProgress variant='determinate' value={count[0] === 0 && count[1] === 0 ? 0 : count[0] * 100 / count[1]} />
                            <span>{`${count[0]}/${count[1]}`}</span>
                        </Box>
                    }

                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box width='70%' display='flex' justifyContent='space-between' alignItems='center'>
                            <InsertInvitation />
                            <Box>
                                <Typography className={classes.textDate}>{t('inicio')}</Typography>
                                <span>{moment(project.dateInit).format("DD/MM/YYYY")}</span>
                            </Box>
                            <Box>
                                <Typography className={classes.textDate}>{t('termino')}</Typography>
                                <span>{moment(project.dateEnd).format("DD/MM/YYYY")}</span>
                            </Box>
                        </Box>
                        <Box display='flex' justifyContent='flex-end'>
                            <AvatarGroup max={2}>
                                <Avatar src={project.user.attachment?.url} className={classes.avatar} />
                            </AvatarGroup>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};
import React, { useState } from 'react';
import {
	makeStyles,
	withStyles,
	Button,
	Typography,
	Box,
	Divider,
	ButtonBase
} from '@material-ui/core';
import MuiMenu from '@material-ui/core/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ArrowDropDown } from '@material-ui/icons';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	filterButton: {
		maxHeight: 48,
		borderRadius: 4,
		color: theme.palette.neutrals.low.dark,
		backgroundColor: theme.palette.neutrals.high.light,
		'&:hover': {
			backgroundColor: theme.palette.neutrals.high.dark
		},
	},
	filterText: {
		fontSize: 14,
		fontWeight: 600,
		paddingLeft: 8
	},
	count: {
		margin: 8,
		padding: '4px 8px',
		backgroundColor: theme.palette.neutrals.low.lightest,
		width: 17,
		height: 18,
		fontSize: 11,
		color: theme.palette.neutrals.low.dark,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		fontSize: 14,
		color: theme.palette.neutrals.low.light,
		padding: theme.spacing(2)
	},
}));

const Menu = withStyles({
  list: {
    padding: 0,
    maxWidth: 750,
		minWidth: 290
  },
})(MuiMenu);


function TemplateFilter({ children, handleClear, handleSendFilters, countFilters = 0 }) {
	const [width] = useWindowSize();
	const classes = useStyles();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = ({ currentTarget }) => {
		setAnchorEl(currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (<>
		<ButtonBase onClick={handleOpen} className={classes.filterButton}>
			<Box width="100%" display='flex' justifyContent="space-evenly" alignItems="center">
				{width > config.RESPONSIVE_MOBILE ? (
					<>
						<FilterListIcon />
						<Typography className={classes.filterText}>{t('filtro')}</Typography>
					</>
				) : (
					<FilterListIcon />
				)}
				{countFilters !== 0 && <span count={countFilters} className={classes.count}>{countFilters}</span>}
				<ArrowDropDown />
			</Box>
		</ButtonBase>
		<Menu
			elevation={2}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<Typography className={classes.title}>{t('filtrar_por')}</Typography>
			<Divider />
			<Box display='flex' flexDirection='column' padding='1rem'>
				{children}
			</Box>
			<Box display='flex' alignItems='center' justifyContent='space-between' padding='1rem'>
				<Button
					color="primary"
					onClick={handleClear}
					variant="outlined"
				>
					{t('limpar_filtro')}
				</Button>
				<Button
					color="primary"
					onClick={handleSendFilters}
					variant="contained"
					startIcon={<FilterListIcon />}
				>
					{t('filtrar')}
				</Button>
			</Box>
		</Menu>
	</>);
};

export { TemplateFilter };
import { portfolioConstants } from '../constants';
import { portfolioService } from '../services';
import { alertActions } from './alert.actions';
import { history, formatBRLFromFloat } from '../helpers';

export const portfolioActions = {
  postCreateTeam,
  getTask,
  getAllSubTasks,
  postCreateSubTask,
  postCreateMultipleSubTasks,
  deleteSubtask,
  clear,
  getAllStatus,
  setTaskTableFilter,
  clearUpdateTaskFlags,
  clearFinancialPlanningFlags,
};






function postCreateTeam(data, successMessage) {
  return dispatch => {
    portfolioService.postCreateTeam(data)
      .then(({data}) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_SUCCESS,
          payload: data
        });
        dispatch(alertActions.success(successMessage));
      },
        (error) => {
          dispatch({
            type: portfolioConstants.PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_FAILURE,
            error
          });
        })
  }
}


function getAllStatus(data) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_GET_ALL_STATUS_REQUEST });
    portfolioService.getAllStatus(data)
      .then((response) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_GET_ALL_STATUS_SUCCESS,
          payload: response.data.data
        });
      },
        (error) => {
          dispatch({
            type: portfolioConstants.PORTFOLIO_GET_ALL_STATUS_FAILURE,
            error
          });
        })
  }
}


function clear() {
  return (dispatch) => {
    dispatch({ type: portfolioConstants.CLEAR });
  };
};














function getTask(id) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_TASK_REQUEST});
    portfolioService.getTask(id).then(data => {
      dispatch({ type: portfolioConstants.GET_TASK_SUCCESS, payload: data });
    }).catch(error => {
    dispatch({ type: portfolioConstants.GET_TASK_FAILURE});
    console.error(error);
    })}
};








function getAllSubTasks(page=1, sort={ createdAt: 1 }) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_SUBTASK_REQUEST});
    portfolioService.getAllSubTasks(page, sort).then(data => {
      dispatch({ type: portfolioConstants.GET_SUBTASK_SUCCESS, payload: data });
    }).catch(error => {
    dispatch({ type: portfolioConstants.GET_SUBTASK_FAILURE});
    console.error(error);
    })}
};



function postCreateSubTask(data) {
 return dispatch => {
    portfolioService.postCreateSubTask(data).then(data => {
      dispatch({ type: portfolioConstants.POST_SUBTASK_SUCCESS, payload: data });
    }).catch(error => {
      dispatch({ type: portfolioConstants.POST_SUBTASK_FAILURE});
      console.error(error);
    })}
};

function postCreateMultipleSubTasks(data) {
 return dispatch => {
    portfolioService.postCreateMultipleSubTasks(data).then(data => {
      dispatch({ type: portfolioConstants.POST_MULTISUBTASK_SUCCESS, payload: data });
    }).catch(error => {
      dispatch({ type: portfolioConstants.POST_MULTISUBTASK_FAILURE});
      console.error(error);
    })}
};



function deleteSubtask(id) {
 return dispatch => {
    portfolioService.deleteSubtask(id).then(() => {
      dispatch({ type: portfolioConstants.DELETE_SUBTASK_SUCCESS });
    }).catch(error => {
      dispatch({ type: portfolioConstants.DELETE_SUBTASK_FAILURE});
      console.error(error);
    })}
};



function setTaskTableFilter(field, value) {
  return dispatch => dispatch({
    type: portfolioConstants.CHANGE_TASK_TABLE_FILTERS,
    payload: {
      field,
      value
    }
  })
}



function clearUpdateTaskFlags() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CLEAR_UPDATE_TASK_FLAGS
    })
  }
}


function clearFinancialPlanningFlags() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CLEAR_FINANCIAL_PLANNING_FLAGS
    })
  }
}
import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';
import { history, formatBRLFromFloat } from '../../helpers';

export const portfolioHomeActions = {
  getAllProjects
}

function getAllProjects(portfolioStatus) {
  return dispatch => {
    dispatch({ 
      type: portfolioConstants.GET_ALL_PROJECTS_REQUEST 
    });
    portfolioService.getAllProjects().then(({ data }) => {
      const orderOneCode = portfolioStatus?.filter(item => item.order === 1)[0]?.code;
      const orderSecondCode = portfolioStatus?.filter(item => item.order === 2)[0]?.code;
      const orderThirdCode = portfolioStatus?.filter(item => item.order === 3)[0]?.code;

      const first_status_items = data.filter(item => item.status.code === orderOneCode);
      const second_status_items = data.filter(item => item.status.code === orderSecondCode);
      const third_status_items = data.filter(item => item.status.code === orderThirdCode);

      dispatch({ 
        type: portfolioConstants.GET_ALL_PROJECTS_SUCCESS, 
        payload: { 
          first_status_items,
          second_status_items,
          third_status_items
        }
      });
    }).catch(error => {
      console.error(error);
      dispatch({ 
        type: portfolioConstants.GET_ALL_PROJECTS_FAILURE 
      });
    });
  };
};
import React, { useEffect } from 'react';
import {
  DialogContent,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Hidden,
  Box,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { 
  ideaCreateActions, 
  ideaCreateDialogsActions, 
  multiAreaCascadeActions 
} from '../../actions';
import config from '../../config';
import { dialogCss } from './dialogCss';
import { useWindowSize } from '../../hooks';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogChallenge({ styles }) {
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  let { Challenge } = useSelector((state) => state.domains);
  const { dialogChallenge, fromHistory } = useSelector(
    (state) => state.ideaCreateDialogs
  );
  const idea = useSelector((state) => state.ideaCreate);
  const { handleSubmit, register } = useForm();
  const [width] = useWindowSize();
  const notChallenge = {
    _id: uniqueId(),
    name: 'Ideia Aberta',
    notChallenge: true,
  };

  const { t } = useTranslation();
  function onSubmit() {
    if (fromHistory === 'sendIdea') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }
    if (config.PERSONALIZED_CLASSIFICATION_COLABORATOR) {
      dispatch(ideaCreateDialogsActions.openDialogClassification('DIALOG_CHALLENGE'));
    } else {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
    };
  }

  function createDraft() {
    dispatch(ideaCreateActions.createDraft(idea, t('ideia_salva_em_rascunhos')));
  }

  function selectedChallenge(challenge) {
    dispatch(ideaCreateActions.setChallenge(challenge));
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }

  useEffect(() => {
    selectedChallenge(notChallenge)
  }, []);
  
  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }
  return (
    <DialogCreateIdea open={dialogChallenge}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('para_qual_desafio_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('se_sua_ideia_responde_a_um_desafio_selecione_a')}, {' '}
            {t('se_nao_selecione_uma_ideia_aberta')}.
          </FormLabel>
          <RadioGroup aria-label="challenge" name="challenge">
            {Challenge &&
              Challenge?.filter(item => item.statusChallenge?.code === 4 ).map((challenge) => (
                <FormControlLabel
                  key={challenge._id}
                  value={challenge._id}
                  className={clsx(classes.label, dialogClasses.label)}
                  control={<Radio color="primary" />}
                  onChange={() => selectedChallenge(challenge)}
                  label={challenge.name}
                  inputRef={register}
                  checked={idea?.challenge?.name === challenge?.name}
                />
              ))}
            <FormControlLabel
              key={notChallenge._id}
              value={notChallenge._id}
              className={clsx(classes.label, dialogClasses.label)}
              control={<Radio color="primary" />}
              label={ t("ideia_aberta") }
              onChange={() => selectedChallenge(notChallenge)}
              inputRef={register}
              checked={idea?.challenge?.name === notChallenge?.name}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            {fromHistory.length > 0 && (
              <Button
                color="primary"
                onClick={previous}
                startIcon={<ArrowBackIcon />}
                className={dialogClasses.button}
              >
                {t('voltar')}
              </Button>
            )}
          </Box>
          <Box>
            <Hidden smDown>
              <Button
                className={dialogClasses.button}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={() => {
                  createDraft();
                }}
              >
                {t('salvar_rascunho')}
              </Button>
            </Hidden>
            <Button
              className={dialogClasses.button}
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<ArrowForwardIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('avancar')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogChallenge };

import React from 'react';
import { 
  makeStyles, 
  Container,
  Box
} from '@material-ui/core';

import config from '../../config';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#585858',
  },
  logo:{
    maxWidth: 120,
    padding: 20,
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E5F2F3',
    fontSize: 25,
    fontWeight: '700',
  },
  declaration: {
    fontSize: 15,
    fontWeight: '500',
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: '600',
    paddingBottom: 10,
  },
  info: {
    fontSize: 13,
    paddingBottom: 30,
    lineHeight: 1.8
  },
  footer: {
    background: '#E5F2F3'
  },
}));
// componentWillMount: function(){
//     for(i in styles.body){
//         document.body.style[i] = styles.body[i];
//     }
// }
// componentWillUnmount: function(){
//     for(i in styles.body){
//         document.body.style[i] = null;
//     }
// }
// useEffect(()  => {
//     document.body.classList.add('bg-black');

//     return () => {
//         document.body.classList.remove('bg-black');
//     };
// }); 
function InviteLeadershipPage(props) {
  const classes = useStyles();
  
  return (

    <div className={classes.root}>
 
      <iframe src="{config.REACT_APP_INVITE_LEADERSHIP_SITE_URL}" style={{ boxSizing: "border-box",width:"calc(100vw)",height:"calc(100vh)", border:0, frameBorder:"0", scrolling:"no" , overflow: "hidden"}} />
    </div>
  );
}

export { InviteLeadershipPage };

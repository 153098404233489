import React, { useEffect, useState, Fragment } from 'react';
import { UploadImageUser } from './UploadImageUser';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import SaveIcon from '@material-ui/icons/Save';
import { useLocation, useHistory} from "react-router-dom";
import {
  TextField,
  FormControl,
  Button,
  makeStyles,
  Typography,
  InputLabel,
  FormHelperText,
  FilledInput,
  Grid,
  Box,
  Card,
} from '@material-ui/core';
import { validation, PhoneMaskCustom, CPFMaskCustom, CPFMaskCustomEs } from '../../helpers';
import { userActions, userFunctionActions, alertActions } from '../../actions';
import { MultiAreaCascade } from "./../../components/Shared/MultiAreaCascade"
import clsx from 'clsx';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { CustomProfile } from '../Shared';
import { DialogConfirmEmailRecoverPassword } from "./DialogConfirmEmailRecoverPassword"
import { SkeletonUserEdit } from "./SkeletonUserEdit"
import { DialogSensitiveData } from './DialogSensitiveData';
import { LoadingButton } from '../../components/Shared';

const useStyles = makeStyles((theme) => ({
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  labelFocused: {
    fontWeight: 600,
    lineHeight: 0.5,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  textPhotoOptional: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.medium
  },
  textCaption: {
    color: theme.palette.neutrals.low.medium
  }
}));

function UserEdit({ loadAdminUserEdit = false }) {
  const user = useSelector((state) => state.user);
  const { 
    adminUserEdit, 
    adminUserEditLoading,
    adminUserEditSuccess,
    adminUserEditError,
    dialogConfirmRecoverPassword,
    loading,
  } = useSelector(state => state.userFunction)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const classes = useStyles();
  const { userCustomProfile } = useSelector((state) => state.domains);
  const [about, setAbout] = useState(user.about ? user.about : '');
  const [selectedCustomerProfile, setSelectedCustomerProfile] = useState(user.userCustomProfile)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const location = useLocation();
  const [openDialogSensitiveData, setOpenDialogSensitiveData] = useState(false);
  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: loadAdminUserEdit ? adminUserEdit?.name : user.name,
      email: loadAdminUserEdit ? adminUserEdit?.email : user.email,
      gender: loadAdminUserEdit ? adminUserEdit?.gender : user?.gender,
      businessUnitLocal: "",
      about: loadAdminUserEdit ? adminUserEdit?.about : user?.about,
      dateOfBirth: loadAdminUserEdit ? adminUserEdit?.dateOfBirth : user?.dateOfBirth,
      document: loadAdminUserEdit ? adminUserEdit?.document : user?.document,
      phone: loadAdminUserEdit ? adminUserEdit?.phone : user.phone,
      position: loadAdminUserEdit ? adminUserEdit?.position : user.position,
    },
  });

  useEffect(() =>{
    if (!loadAdminUserEdit) {
      dispatch(userActions.getUser());
    }
  }, [dispatch, loadAdminUserEdit]);

  useEffect(() => {
    if (adminUserEditSuccess) {
      dispatch(alertActions.success("Usuário atualizado com sucesso!"));
    }
    if (adminUserEditError) {
      dispatch(alertActions.error("E-mail já cadastrado no sistema!"));
  }
  }, [adminUserEditSuccess, adminUserEditError, dispatch])

  useEffect(() => {
    return () => dispatch(userFunctionActions.clearUserEditErrors())
  }, [dispatch])
  function handleChangeCustomerProfile(e) {
    console.log(e.target.value)
    setSelectedCustomerProfile(e.target.value)
  }
  function onSubmit(data) {
    if (data.dateOfBirth?.toString() === 'Invalid Date') {
      return;
    }

    if(!config.USER_CUSTOM_PROFILE) {
    if (!fields[0].selectedBusinessUnit) {
      return;
    }

    const newUser = { 
      ...(loadAdminUserEdit ? adminUserEdit : user), 
      ...data,
      businessUnitLocal: fields[0].selectedBusinessUnit
    };
    if (loadAdminUserEdit) {
      dispatch(userFunctionActions.updateUser(newUser));
    } else {
      const newUser = { 
        ...user, 
        ...data,
        userCustomProfile: selectedCustomerProfile
      };
     
    dispatch(userActions.updateUser(newUser, t('alterações_no_perfil_salvas')));
    }    
    }
  }

  function handleAbout() {
    const about = getValues().about;
    setAbout(about);
  }

  function toggleConfirmEmailRecoverPassword() {
    dispatch(userFunctionActions.toggleDialogConfirmEmail(!dialogConfirmRecoverPassword))
  }
  function handleOpenDialogSensitiveData() {
    setOpenDialogSensitiveData(!openDialogSensitiveData);
  };
  return (
    <Fragment>
      <DialogSensitiveData open={openDialogSensitiveData} onClose={handleOpenDialogSensitiveData} />
      <DialogConfirmEmailRecoverPassword />
      { (!loadAdminUserEdit || (loadAdminUserEdit && !adminUserEditLoading)) ? (
    <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" marginTop={loadAdminUserEdit ? "1rem" : 0} >
              {!loadAdminUserEdit && (
                <Typography variant="subtitle1" className={clsx(classes.title)}>
                  {t('dados_pessoais').toUpperCase()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
          { (location.pathname.split("/")[2] !== "user-edit" || loadAdminUserEdit) && (
      <Grid item xs={12}>
                  <Box marginBottom='2rem'>
                    <Typography variant="subtitle2" className={classes.textPhotoOptional}>
                      {t('foto_de_perfil_opcional')}
                    </Typography>
          <UploadImageUser
            deleteButton
                  user={loadAdminUserEdit ? adminUserEdit : user}
                    />
        </Box>
      </Grid>
          )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
              <TextField
                name="name"
                id="name"
                label={t('nome')}
                autoComplete="name"
                type="text"
                variant="filled"
                margin="none"
                fullWidth
                required
                inputRef={register({ required: t('o_campo_nome_e_obrigatorio') })}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                    defaultValue={loadAdminUserEdit ? adminUserEdit?.name : user.name}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} md={7}>
              <Controller
                as={<KeyboardDatePicker />}
                variant="inline"
                inputVariant="filled"
                format="dd/MM/yyyy"
                fullWidth
                margin="none"
                id="date-picker-inline"
                label="data de nascimento"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDateMessage="Date should not be in the past"
                invalidDateMessage="Data de nascimento incorreta"
                control={control}
                name="dateOfBirth"
                defaultValue={null}
                required
                inputRef={register}
                rules={{ required: true }}
                error={!!errors.dateOfBirth}
              />
              <FormHelperText className={classes.error}>
                {errors.dateOfBirth &&
                  errors.dateOfBirth.type === 'required' &&
                  'A data de nascimento é obrigatório'}
              </FormHelperText>
            </Grid> */}
                    <Grid item xs={12} md={6}>
              <TextField
                name="email"
                id="email"
                label="e-mail"
                autoComplete="email"
                type="email"
                variant="filled"
                margin="none"
                fullWidth
                readOnly
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                required
                inputRef={register({
                  required: t('o_email_e_obrigatorio'),
                  pattern: {
                    value: validation.email,
                    message: t('o_email_digitado_nao_e_um_email_valido'),
                  },
                })}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                    defaultValue={loadAdminUserEdit ? (adminUserEdit?.email?.includes('@') ? adminUserEdit?.email : '') : (user.email.includes('@') ? user.email : '')}
              />
            </Grid>
            {/* <Grid item xs={12} md={7}>
              <FormControl
                variant="filled"
                error={!!errors.document}
                fullWidth
              >
                <InputLabel htmlFor="document">CPF *</InputLabel>
                <FilledInput
                  name="document"
                  id="document"
                  type="text"
                  required
                  inputRef={register({
                    required: 'O campo CPF é obrigatório.',
                  })}
                  inputComponent={CPFMaskCustom}
                />
                <FormHelperText id="helper-document">
                  {errors.document && errors.document.message}
                </FormHelperText>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <FormControl
                variant="filled"
                error={!!errors.phone}
                fullWidth
              >
                <InputLabel
                  htmlFor="phone"
                  classes={{ root: classes.labelRoot, filled: classes.labelFocused }}
                >
                  {t('telefone')} ({t('opcional')})
                </InputLabel>
                <FilledInput
                  name="phone"
                  id="phone"
                  type="tel"
                  inputRef={register({
                    // pattern: {
                    //   value: validation.phone,
                    //   message: 'O telefone digitado não é um telefone valido',
                    // },
                  })}
                  defaultValue={user.phone}
                  inputComponent={PhoneMaskCustom}
                />
                <FormHelperText id="helper-phone">
                  {errors.phone && errors.phone.message}
                </FormHelperText>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                name="position"
                id="position"
                label={t('funcao')}
                autoComplete="position"
                type="text"
                variant="filled"
                margin="none"
                fullWidth
                    defaultValue={loadAdminUserEdit ? adminUserEdit?.position : user.position}
                inputRef={register({ required: '' })}
                error={!!errors.position}
                helperText={errors.position && errors.position.message}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>
            {config.USER_CUSTOM_PROFILE ? (
              <Grid item xs={12} md={7}>
                <CustomProfile handleChange={handleChangeCustomerProfile} value={selectedCustomerProfile} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                      <MultiAreaCascade loadBusinessUnitLocals={[loadAdminUserEdit ? adminUserEdit?.businessUnitLocal : user.businessUnitLocal]} />

              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Box position="relative">
                <div className={classes.characters}>
                  <span>
                    {about.length}/1024 {t('caracteres')}
                  </span>
                </div>
                <TextField
                  error={!!errors.about}
                  id="outlined-multiline-static"
                  name="about"
                  label={t('biografia_opcional')}
                  multiline
                  fullWidth
                  rows={8}
                  variant="filled"
                  onChange={handleAbout}
                  inputRef={register({
                    maxLength: 1024,
                  })}
                      defaultValue={loadAdminUserEdit ? adminUserEdit?.about : user.about}
                  helperText={errors.about && t('voce_passou_o_limite_de_caracteres')}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                />
              </Box>
            </Grid>
                { loadAdminUserEdit && (
                  <Grid item xs={4} md={loadAdminUserEdit ? 6 : 12}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={toggleConfirmEmailRecoverPassword}
                      >
                        redefinir senha
                      </Button>
                    </Box>
                  </Grid>
                )} 
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.textCaption}>
                        {t('nao_compartilhe_nesse_campo_dados_pessoais_sensiveis')}
                        {' '} 
                        <Typography 
                          variant="caption" 
                          color="primary" 
                          style={{ cursor: 'pointer', fontWeight: 600 }}
                          onClick={handleOpenDialogSensitiveData}
                        >
                          {t('saiba_mais')}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={loadAdminUserEdit ? 6 : 12} className={classes.button}>
                      {loading || user.loading ? (
                        <LoadingButton />
                      ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
                className={classes.btnSave}
              >
                {t('salvar_perfil')}
              </Button>
                      )}
            </Grid>
          </Grid>
        </form>
      </Grid>
            </Card>
          </Grid>
    </Grid>
        ) : (
        <SkeletonUserEdit />
      )}
    </Fragment>
  );
}

export { UserEdit };
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import clsx from "clsx";

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { tableCss } from './../Tables/TableCss';
import { StatusMenu } from "./../Portfolio/StatusMenu";
import { DialogCreateTask } from './DialogCreateTask';
import { DialogDetailsTask } from './DialogDetailsTask';
import { portfolioActions } from '../../actions/portfolio.actions';
import { DialogDeleteTask } from "./Dialogs/DialogDeleteTask"

const useRowStyles = makeStyles((theme) => ({
  hidden: {
    backgroundColor: "unset",
    border: "unset",
  },
  menuText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  avatar: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
}));

const TableRowStyled = withStyles({
  root: {
    borderRight: `1px solid #E7EaED`,
    borderLeft: `1px solid #E7EaED`,
  },
})(TableRow);

export const PortfolioCollapsibleRow = ({ row }) => {
  const classes = useRowStyles();
  const tableClasses = tableCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUpdateTaskDialog, setOpenUpdateTaskDialog] = useState(false);
  const [openDialogTaskDetails, setOpenDialogTaskDetails] = useState(false);
  const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);
  const { projectTasksStatus } = useSelector(state => state.domains);
  
  function handleOpenMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleOpenUpdateTaskDialog() {
    setOpenUpdateTaskDialog(!openUpdateTaskDialog);
    handleCloseMenu();
  }

  function handleOpenDialogTaskDetails() {
    setOpenDialogTaskDetails(!openDialogTaskDetails);
    handleCloseMenu();
  }

  function handleOpenDialogDeleteTask() {
    setOpenDialogDeleteTask(!openDialogDeleteTask);
    handleCloseMenu();
  }

  return (
    <>
      <TableRow className={clsx(classes.root, tableClasses.tableRow)}>
        <TableCell style={{ width: 100 }} classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" style={{ width: 380 }} classes={{ root: tableClasses.tableCell }}>{row.name}</TableCell>
        <TableCell align="left" style={{ width: 190 }} classes={{ root: tableClasses.tableCell }}>
          <Box display="flex" alignItems="center">
            <Avatar alt={row?.responsible?.name} src={row?.responsible?.attachment?.url} className={classes.avatar} />
            {row.responsible.name}
          </Box>
        </TableCell>
        <TableCell align="left" style={{ width: 130 }} classes={{ root: tableClasses.tableCell }}>{moment(row.conclusionDate).format('DD/MM/YYYY')}</TableCell>
        <TableCell align="left" style={{ width: 'auto' }} classes={{ root: tableClasses.tableCell }}>
          <StatusMenu selectedStatus={row.taskStatus} allStatus={projectTasksStatus} type={"task"} id={row._id} />
        </TableCell>
        <TableCell style={{ borderLeft: '1px solid #E7EaED' }} classes={{ root: tableClasses.tableCellRadiusRight }} align="center">
          <IconButton aria-label="expand row" size="small" onClick={handleOpenMenu}>
            <MoreHorizIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={tableClasses.tableRow}>
        <TableCell style={{ padding: 0 }} className={open ? tableClasses.tableRow : classes.hidden} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="medium" classes={{ root: tableClasses.table }}>
              <TableBody>
                {row.subTasks.map((subTask) => (
                  <TableRowStyled className={tableClasses.tableRow} key={subTask._id}>
                    <TableCell style={{ width: 100 }} align="right"> <SubdirectoryArrowRightIcon fontSize="small" /> </TableCell>
                    <TableCell style={{ width: 380 }} align="left">{subTask.description}</TableCell>
                    <TableCell style={{ width: 190 }} align="left">
                      <Box display="flex" alignItems="center">
                        <Avatar alt={subTask?.responsible?.name} src={subTask?.responsible?.attachment?.url} className={classes.avatar} />
                        {subTask?.responsible?.name}
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: 130 }} align="left">{ moment(subTask.conclusionDate).format('DD/MM/YYYY') }</TableCell>
                    { subTask.taskStatus && (
                      <TableCell align="left">
                        <StatusMenu selectedStatus={subTask.taskStatus} allStatus={projectTasksStatus} type={"subtask"} id={subTask._id} />
                      </TableCell>
                    )}
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      { openUpdateTaskDialog && <DialogCreateTask 
          open={openUpdateTaskDialog}
          onClose={handleOpenUpdateTaskDialog}
          task={row}
        />
      }
      { openDialogTaskDetails && <DialogDetailsTask 
          open={openDialogTaskDetails} 
          onClose={handleOpenDialogTaskDetails}
          status={projectTasksStatus}
          task={row}
        />
      }
      { openDialogDeleteTask && <DialogDeleteTask
          open={openDialogDeleteTask}
          onClose={handleOpenDialogDeleteTask}
          task={row}
        />
      }
      <Menu
        keepMounted
        elevation={2}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList style={{ padding: 0 }}>
          <MenuItem onClick={handleOpenDialogTaskDetails}>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Typography className={classes.menuText}>{t('visualizar')}</Typography>
            }/>
          </MenuItem>
          <MenuItem onClick={handleOpenUpdateTaskDialog}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Typography className={classes.menuText}>{t('editar')}</Typography>
            }/>
          </MenuItem>
          <MenuItem onClick={handleOpenDialogDeleteTask}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Typography className={classes.menuText}>{t('excluir')}</Typography>
            }/>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

export const portfolioConstants = {
  PORTFOLIO_POST_CREATE_PROJECT_REQUEST: 'PORTFOLIO_POST_CREATE_PROJECT_REQUEST',
  PORTFOLIO_POST_CREATE_PROJECT_SUCCESS: 'PORTFOLIO_POST_CREATE_PROJECT_SUCCESS',
  PORTFOLIO_POST_CREATE_PROJECT_FAILURE: 'PORTFOLIO_POST_CREATE_PROJECT_FAILURE',
  PORTFOLIO_PUT_UPDATE_PROJECT_REQUEST: 'PORTFOLIO_PUT_UPDATE_PROJECT_REQUEST',
  PORTFOLIO_PUT_UPDATE_PROJECT_SUCCESS: 'PORTFOLIO_PUT_UPDATE_PROJECT_SUCCESS',
  PORTFOLIO_PUT_UPDATE_PROJECT_FAILURE: 'PORTFOLIO_PUT_UPDATE_PROJECT_FAILURE',
  PORTFOLIO_LOAD_LINKED_IDEAS: 'PORTFOLIO_LOAD_LINKED_IDEAS',
  PORTFOLIO_CHANGE_FIELD_PROJECT: 'PORTFOLIO_CHANGE_FIELD_PROJECT',
  CHANGE_PORTFOLIO_DIALOG_TASK: 'CHANGE_PORTFOLIO_DIALOG_TASK',
  CHANGE_PORTFOLIO_DIALOG_SUBTASK: "CHANGE_PORTFOLIO_DIALOG_SUBTASK",
  REMOVE_PORTFOLIO_DIALOG_SUBTASK: "REMOVE_PORTFOLIO_DIALOG_SUBTASK",
  CLEAR_PORTFOLIO_DIALOG_TASK: "CLEAR_PORTFOLIO_DIALOG_TASK",
  ADD_PORTFOLIO_DIALOG_SUBTASK: 'ADD_PORTFOLIO_DIALOG_SUBTASK',
  CLEAR_CREATE_PROJECT: 'CLEAR_CREATE_PROJECT',
  LOAD_CREATE_PROJECT_DIALOG: 'LOAD_CREATE_PROJECT_DIALOG',

  PORTFOLIO_GET_ALL_USERS_NAME_REQUEST: "PORTFOLIO_GET_ALL_USERS_NAME_REQUEST",
  PORTFOLIO_GET_ALL_USERS_NAME_SUCCESS: "PORTFOLIO_GET_ALL_USERS_NAME_SUCCESS",
  PORTFOLIO_GET_ALL_USERS_NAME_FAILURE: "PORTFOLIO_GET_ALL_USERS_NAME_FAILURE",

  PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_SUCCESS: 'PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_SUCCESS',
  PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_FAILURE: 'PORTFOLIO_POST_ADD_TEAM_IN_PROJECT_FAILURE',
  PORTFOLIO_UPDATE_TEAMS_REQUEST: "PORTFOLIO_UPDATE_TEAMS_REQUEST",
  PORTFOLIO_UPDATE_TEAMS_SUCCESS: 'PORTFOLIO_UPDATE_TEAMS_SUCCESS',
  PORTFOLIO_UPDATE_TEAMS_FAILURE: 'PORTFOLIO_UPDATE_TEAMS_FAILURE',
  PORTFOLIO_CHANGE_FIELD_TEAM: 'PORTFOLIO_CHANGE_FIELD_TEAM',
  CLEAR_PORTFOLIO_FIELD_TEAM: 'CLEAR_PORTFOLIO_FIELD_TEAM',
  PORTFOLIO_ADD_NEW_PARTICIPANT: 'PORTFOLIO_ADD_NEW_PARTICIPANT',
  PORTFOLIO_REMOVE_PARTICIPANT: 'PORTFOLIO_REMOVE_PARTICIPANT',
  CLEAR_PORTFOLIO_DIALOG_TEAM: 'CLEAR_PORTFOLIO_DIALOG_TEAM',
  LOAD_CREATE_TEAM_DIALOG: 'LOAD_CREATE_TEAM_DIALOG',

  PORTFOLIO_GET_ALL_STATUS_REQUEST: 'PORTFOLIO_GET_ALL_STATUS_REQUEST',
  PORTFOLIO_GET_ALL_STATUS_SUCCESS: 'PORTFOLIO_GET_ALL_STATUS_SUCCESS',
  PORTFOLIO_GET_ALL_STATUS_FAILURE: 'PORTFOLIO_GET_ALL_STATUS_FAILURE',

  PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_REQUEST: 'PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_REQUEST',
  PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_SUCCESS: 'PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_SUCCESS',
  PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_FAILURE: 'PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_FAILURE',
  PORTFOLIO_UPDATE_FINANCIAL_PLANNING_REQUEST: 'PORTFOLIO_UPDATE_FINANCIAL_PLANNING_REQUEST',
  PORTFOLIO_UPDATE_FINANCIAL_PLANNING_SUCCESS: 'PORTFOLIO_UPDATE_FINANCIAL_PLANNING_SUCCESS',
  PORTFOLIO_UPDATE_FINANCIAL_PLANNING_FAILURE: 'PORTFOLIO_UPDATE_FINANCIAL_PLANNING_FAILURE',
  PORTFOLIO_CHANGE_FIELD_EXPENSE: 'PORTFOLIO_CHANGE_FIELD_EXPENSE',
  PORTFOLIO_ADD_NEW_EXPENSE: 'PORTFOLIO_ADD_NEW_EXPENSE',
  PORTFOLIO_REMOVE_EXPENSE: 'PORTFOLIO_REMOVE_EXPENSE',
  PORTFOLIO_SET_ESTIMATED_VALUE_RETURN: 'PORTFOLIO_SET_ESTIMATED_VALUE_RETURN',
  CLEAR_PORTFOLIO_DIALOG_FINANCIAL_PLANNING: 'CLEAR_PORTFOLIO_DIALOG_FINANCIAL_PLANNING',
  LOAD_CREATE_FINANCIAL_PLANNING_DIALOG: 'LOAD_CREATE_FINANCIAL_PLANNING_DIALOG',

  CLEAR_PORTFOLIO_ERRORS: "CLEAR_PORTFOLIO_ERRORS",

  GET_ALL_PROJECTS_REQUEST: 'GET_ALL_PROJECTS_REQUEST',
  GET_ALL_PROJECTS_SUCCESS: 'GET_ALL_PROJECTS_SUCCESS',
  GET_ALL_PROJECTS_FAILURE: 'GET_ALL_PROJECTS_FAILURE',

  GET_ALL_PROJECTS_EXEUTION_FLOW_REQUEST: 'GET_ALL_PROJECTS_EXEUTION_FLOW_REQUEST',
  GET_ALL_PROJECTS_EXEUTION_FLOW_SUCCESS: 'GET_ALL_PROJECTS_EXEUTION_FLOW_SUCCESS',
  GET_ALL_PROJECTS_EXEUTION_FLOW_FAILURE: 'GET_ALL_PROJECTS_EXEUTION_FLOW_FAILURE',

  GET_ONE_PROJECT_REQUEST: 'GET_ONE_PROJECT_REQUEST',
  GET_ONE_PROJECT_SUCCESS: 'GET_ONE_PROJECT_SUCCESS',
  GET_ONE_PROJECT_FAILURE: 'GET_ONE_PROJECT_FAILURE',
  CLEAR_ALL_PORTFOLIO: 'CLEAR_ALL_PORTFOLIO',

  GET_FINANCIAL_PLANNING_REQUEST: 'GET_FINANCIAL_PLANNING_REQUEST',
  GET_FINANCIAL_PLANNING_SUCCESS: 'GET_FINANCIAL_PLANNING_SUCCESS',
  GET_FINANCIAL_PLANNING_FAILURE: 'GET_FINANCIAL_PLANNING_FAILURE',

  GET_PORTFOLIO_TEAMS_REQUEST: 'GET_PORTFOLIO_TEAMS_REQUEST',
  GET_PORTFOLIO_TEAMS_SUCCESS: 'GET_PORTFOLIO_TEAMS_SUCCESS',
  GET_PORTFOLIO_TEAMS_FAILURE: 'GET_PORTFOLIO_TEAMS_FAILURE',

  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  GET_TASK_REQUEST: 'GET_TASK_REQUEST',
  GET_TASK_FAILURE: 'GET_TASK_FAILURE',

  GET_ALL_TASKS_SUCCESS: 'GET_ALL_TASKS_SUCCESS',
  GET_ALL_TASKS_REQUEST: 'GET_ALL_TASKS_REQUEST',
  GET_ALL_TASKS_FAILURE: 'GET_ALL_TASKS_FAILURE',

  PORTFOLIO_POST_CREATE_TASK_REQUEST: "PORTFOLIO_POST_CREATE_TASK_REQUEST",
  PORTFOLIO_POST_CREATE_TASK_SUCCESS: 'PORTFOLIO_POST_CREATE_TASK_SUCCESS',
  PORTFOLIO_POST_CREATE_TASK_FAILURE: 'PORTFOLIO_POST_CREATE_TASK_FAILURE',

  PORTFOLIO_UPDATE_SUBTASK_REQUEST: "PORTFOLIO_UPDATE_SUBTASK_REQUEST",
  PORTFOLIO_UPDATE_SUBTASK_SUCCESS: 'PORTFOLIO_UPDATE_SUBTASK_SUCCESS',
  PORTFOLIO_UPDATE_SUBTASK_FAILURE: 'PORTFOLIO_UPDATE_SUBTASK_FAILURE',

  PORTFOLIO_DELETE_TASK_REQUEST: "PORTFOLIO_DELETE_TASK_REQUEST",
  PORTFOLIO_DELETE_TASK_SUCCESS: 'PORTFOLIO_DELETE_TASK_SUCCESS',
  PORTFOLIO_DELETE_TASK_FAILURE: 'PORTFOLIO_DELETE_TASK_FAILURE',
  CLEAR_PORTFOLIO_DELETE_FLAGS: "CLEAR_PORTFOLIO_DELETE_FLAGS",

  GET_SUBTASK_SUCCESS: 'GET_SUBTASK_SUCCESS',
  GET_SUBTASK_REQUEST: 'GET_SUBTASK_REQUEST',
  GET_SUBTASK_FAILURE: 'GET_SUBTASK_FAILURE',

  POST_SUBTASK_SUCCESS: 'POST_SUBTASK_SUCCESS',
  POST_SUBTASK_FAILURE: 'POST_SUBTASK_FAILURE',

  POST_MULTISUBTASK_SUCCESS: 'POST_MULTISUBTASK_SUCCESS',
  POST_MULTISUBTASK_FAILURE: 'POST_MULTISUBTASK_FAILURE',

  DELETE_SUBTASK_SUCCESS: 'DELETE_SUBTASK_SUCCESS',
  DELETE_SUBTASK_FAILURE: 'DELETE_SUBTASK_FAILURE',

  PORTFOLIO_UPDATE_TASK_REQUEST: "PORTFOLIO_UPDATE_TASK_REQUEST",
  PORTFOLIO_UPDATE_TASK_SUCCESS: 'PORTFOLIO_UPDATE_TASK_SUCCESS',
  PORTFOLIO_UPDATE_TASK_FAILURE: 'PORTFOLIO_UPDATE_TASK_FAILURE',
  LOAD_CREATE_TASK_DIALOG: 'LOAD_CREATE_TASK_DIALOG',

  CHANGE_TASK_TABLE_FILTERS: "CHANGE_TASK_TABLE_FILTERS",

  CHANGE_TASKS_PAGING: "CHANGE_TASKS_PAGING",
  CHANGE_PORTFOLIO_TASK_STATUS_REQUEST: "CHANGE_PORTFOLIO_TASK_STATUS_REQUEST",
  CHANGE_PORTFOLIO_TASK_STATUS_SUCCESS: "CHANGE_PORTFOLIO_TASK_STATUS_SUCCESS",
  CHANGE_PORTFOLIO_TASK_STATUS_FAILURE: "CHANGE_PORTFOLIO_TASK_STATUS_FAILURE",
  CLEAR_UPDATE_TASK_FLAGS: "CLEAR_UPDATE_TASK_FLAGS",

  CHANGE_PORTFOLIO_FIELD: "CHANGE_PORTFOLIO_FIELD",
  DELETE_PORTFOLIO_PROJECT_REQUEST: "DELETE_PORTFOLIO_PROJECT_REQUEST",
  DELETE_PORTFOLIO_PROJECT_SUCCESS: "DELETE_PORTFOLIO_PROJECT_SUCCESS",
  DELETE_PORTFOLIO_PROJECT_FAILURE: "DELETE_PORTFOLIO_PROJECT_FAILURE",
};

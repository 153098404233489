import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const portfolioService = {
  getAllProjects,
  postCreateProject,
  updateProject,
  getAllUsers,
  deleteProject,
  getProject,
  getOneFinancialPlanning,
  postCreateFinancialPlanning,
  updateFinancialPlanning,
  getTeam,
  getAllStatus,
  postCreateTeam,
  updateTeam,
  getTask,
  getAllTask,
  postCreateTask,
  updateTask,
  deleteTask,
  getAllSubTasks,
  postCreateSubTask,
  postCreateMultipleSubTasks,
  updateSubTask,
  deleteSubtask,
  changeStatus,
}

function postCreateProject(data) {
  return axios.post(`${config.URL_BASE}/project`, data, requestHeaders());
}

function updateProject(data) {
  return axios.put(`${config.URL_BASE}/project`, data, requestHeaders());
}

function getAllProjects() {
  return axios.get(`${config.URL_BASE}/project/all`, requestHeaders())
    .then(handleResponse);
};

function getProject(id) {
  return axios.get(`${config.URL_BASE}/project/${id}`, requestHeaders())
    .then(handleResponse);
};

function deleteProject(id) {
  return axios.delete(`${config.URL_BASE}/project/${id}`, requestHeaders())
    .then(handleResponse);
}

function getAllUsers(query) {
  return axios.get(`${config.URL_BASE}/user/all-users-name`, requestHeaders());
}

function getAllStatus() {
  return axios.get(`${config.URL_BASE}/project-status`, requestHeaders());
};

function getOneFinancialPlanning(id) {
  return axios.get(`${config.URL_BASE}/project-financial-planning/project/${id}`, requestHeaders())
    .then(handleResponse);
};

function postCreateFinancialPlanning(data) {
  return axios.post(`${config.URL_BASE}/project-financial-planning`, data, requestHeaders());
}

function updateFinancialPlanning(data) {
  return axios.put(`${config.URL_BASE}/project-financial-planning/${data.projectId}`, data, requestHeaders());
}

function getTeam(id) {
  return axios.get(`${config.URL_BASE}/project-team/${id}`, requestHeaders())
  .then(handleResponse);
};

function postCreateTeam(data) {
  return axios.post(`${config.URL_BASE}/project-team`, data, requestHeaders())
    .then(handleResponse);
};

function updateTeam(id, data) {
  return axios.put(`${config.URL_BASE}/project-team/${id}`, data, requestHeaders());
}

function getTask(id) {
  return axios.get(`${config.URL_BASE}/project-task/${id}`, requestHeaders()).then(handleResponse);
}

async function getAllTask(data, taskStatus) {
  let retorno = {};
  let paging = {};

  const promises = taskStatus.map(async (status) => {
    const response = await axios.post(`${config.URL_BASE}/project-task/project/status`, { 
      ...data,
      taskStatusCode: status.code
    }, requestHeaders());

    paging = response.data.paging

    return {
      code: status.code,
      items: response.data.data,
    }

  });

  const allResponses = await Promise.all(promises);

  allResponses.forEach((response) => {
    retorno[response.code] = response.items
  })

  retorno.paging = paging;

  return retorno;
}

function postCreateTask(data) {
  return axios.post(`${config.URL_BASE}/project-task`, data, requestHeaders());
}

function updateTask(id, data) {
  return axios.put(`${config.URL_BASE}/project-task/${id}`, data, requestHeaders());
}

function deleteTask(id) {
  return axios.delete(`${config.URL_BASE}/project-task/${id}`, requestHeaders());
}

function getAllSubTasks(page, sort) {
  return axios.get(`${config.URL_BASE}/project-task?$page=${page}&sort=${JSON.stringify(sort)}`, requestHeaders()).then(handleResponse);
}

function postCreateSubTask(data) {
  return axios.post(`${config.URL_BASE}/project-subtask`, data, requestHeaders());
}

function postCreateMultipleSubTasks(data) {
  return axios.post(`${config.URL_BASE}/project-subtask/multiple`, data, requestHeaders());
}

async function updateSubTask(arrTaskId, data) {
  const promises = arrTaskId.map(async (task) => {
    const response = await axios.put(`${config.URL_BASE}/project-subtask/${task._id}`, data, requestHeaders())
    return response;
  });

  const allResponses = await Promise.all(promises);
  return allResponses;
}

function deleteSubtask(id) {
  return axios.delete(`${config.URL_BASE}/project-subtask/${id}`, requestHeaders());
}

function changeStatus(type, data, id) {
  if (type === "task") {
    return axios.put(`${config.URL_BASE}/project-task/${id}`, data, requestHeaders())
  }
  
  return axios.put(`${config.URL_BASE}/project-subtask/${id}`, data, requestHeaders())
}
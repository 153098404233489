import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { infraCss } from './infraCss';
import { DialogResponsive } from './DialogResponsive';
import { infraStructureActions } from './../../actions';
import { SkeletonInfraDialogAssociation } from '../Skeletons/SkeletonInfraDialogAssociation';

const filterOptions = createFilterOptions({ limit: 100 });

export const DialogAssociationInfra = ({ open, onClose, row }) => {
  const classes = infraCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { newAssociations, loadingAssociations } = useSelector(state => state.infraStructure);
  const { locals, occupationAreas } = useSelector(state => state.domains);

  function handleChangeAssociation(data, index) {
    const { name, _id } = data.option;
    dispatch(infraStructureActions.changeFieldAssociation('local', { name: name, _id: _id }, index)); 
  }

  function handleChangeAreaAssociation(data, index) {
    const { name, _id } = data.option;
    dispatch(infraStructureActions.changeFieldAssociation('occupationArea', { name: name, _id: _id }, index)); 
  }

  function handleAddAssociation() {
    dispatch(infraStructureActions.addNewAssociation());
  }

  function handleRemoveAssociation(index) {
    return () => {
      dispatch(infraStructureActions.removeAssociation(index));
    }
  }

  function handleCloseDialog() {
    onClose();
    dispatch(infraStructureActions.clearAssociations());
  }

  function handleSubmit() {
    let data = newAssociations.map(item => ({
      local: {
        name: item.local.name,
        _id: item.local._id
      },
      businessUnit: {
        name: row.name,
        _id: row._id
      },
      occupationArea: {
        name: item.occupationArea.name,
        _id: item.occupationArea._id
      }
    }));

    dispatch(infraStructureActions.postNewAssociation(data, t('os_vinculos_foram_salvos')));
    handleCloseDialog();
  }

  return (
    <DialogResponsive open={open} onClose={handleCloseDialog} title={t('vincular_infraestrutura')}>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {t('selecione_o_local_e_respectivas_areas_de_atuacao_a_serem_vinculadas_a_unidade')}{' '}<b>{`${row.name}.`}</b>
            </Typography>
          </Grid>
          {loadingAssociations ? <SkeletonInfraDialogAssociation /> :
            <Grid item xs={12}>
              {newAssociations?.map((item, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={5}>
                    <Autocomplete
                      loadingText={t('carregando...')}
                      noOptionsText={t('sem_opcoes')}
                      filterOptions={filterOptions}
                      options={locals}
                      loading={locals?.length === 0}
                      getOptionLabel={(input) => input?.name}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      defaultValue={{ name: item?.local?.name }}
                      onChange={(_e, _items, _options, details) => handleChangeAssociation(details, index)}
                      renderInput={(params) => <TextField {...params} label={t('local')} variant="filled" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      loadingText={t('carregando...')}
                      noOptionsText={t('sem_opcoes')}
                      filterOptions={filterOptions}
                      options={occupationAreas}
                      loading={occupationAreas?.length === 0}
                      getOptionLabel={(input) => input?.name}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      defaultValue={{ name: item?.occupationArea?.name }}
                      onChange={(_e, _items, _options, details) => handleChangeAreaAssociation(details, index)}
                      renderInput={(params) => <TextField {...params} label={t('area_de_atuacao')} variant="filled" />}
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.deleteAssociation}>
                    <IconButton onClick={handleRemoveAssociation(index)}>
                      <Tooltip title={t('excluir')} aria-label="excluir">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        </Grid>
      </DialogContent> 
      <DialogActions className={classes.actions}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddAssociation}
            >
              {t('adicionar_novo_vinculo')}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              {t('salvar_vinculos')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  );
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  makeStyles,
} from '@material-ui/core';

import { PieChartImagine } from '../PieChartImagine';
import { SkeletonPieChart } from '../Skeletons';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    color: theme.palette.neutrals.low.light,
    fontWeight: 'bold',
  },
}));

export function IdeaForChallengeDashboard({ ideasStatus, loadingIdeasStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card elevation={0} className={classes.card}>
      {loadingIdeasStatus ? (
          <SkeletonPieChart />
      ) : (
        < >
          <div className={classes.title}>{t('ideias_por_status')}</div>
          <PieChartImagine data={ideasStatus} />
        </>
      )}
    </Card>
  );
}


import React, { useState } from 'react';

import {
  Box,
  Button,
} from '@material-ui/core';

import {
  TemplateSelect,
  TemplateTextArea,
  TemplateDeadLine
} from './';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { crudTemplateActions } from '../../actions/crudTemplate.actions';
import { useLocation } from 'react-router-dom';
import { DialogImagine } from '../Shared';
import { selectEndPoint } from '../../helpers/selectEndPointToTemplate';

export function TemplateNewItem({ children }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { body, typePage, idToUpdate, type } = useSelector(state => state.crudTemplate);
  const [openDialog, setOpenDialog] = useState(false);
  const isEdit = typePage === 'crud-edit';

  function handleSend() {
    if (!isEdit) {
      dispatch(crudTemplateActions.create({
        body,
        message: 'body enviado',
        endPoint: selectEndPoint(type),
        errorMessage: 'erro ao enviar body',
      }));
      dispatch(crudTemplateActions.setTypePage('table'));
    } else {
      dispatch(crudTemplateActions.update({
        body,
        endPoint: selectEndPoint(type),
        id: idToUpdate,
        message: 'body enviado',
        errorMessage: 'erro ao enviar body',
      }));
      setOpenDialog(false);
      dispatch(crudTemplateActions.setTypePage('table'));
    }
  };

  return (
    <Box>
      {
        isEdit && (
      <DialogImagine
        open={openDialog}
        onClose={setOpenDialog}
        title={`Atenção, dados alterados!`}
        description={'Os dados foram alterados. Tem certeza de que deseja salvar as alterações?'}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={handleSend}
          >
            {t('sim')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenDialog(false)}
          >
            {t('nao')}
          </Button>
        </Box>
      </DialogImagine>
        )
      }
        { children }
        <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
          <Button
            type='button'
            variant="outlined"
            color="primary"
            style={{ marginRight: 16 }}
            onClick={() => { }}
          >
            cancelar
          </Button>
          <Button
            type='submit'
            variant="contained"
            color="primary"
            onClick={() => isEdit ? setOpenDialog(true) : handleSend()}
          >
            {isEdit ? 'salvar edição' : 'criar item'}
          </Button>
        </Box>
    </Box>
  );
}
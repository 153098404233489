import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { DialogCustomTitle } from './DialogCustomTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.main,
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: 14.22,
    color: theme.palette.neutrals.low.medium,
  },
  containerContent: {
    textAlign: 'center',
    padding: theme.spacing(4, 0),
  },
  actions: {
    flexDirection: 'column',
    padding: theme.spacing(0, 6, 2),
  },
  button: {
    textTransform: 'lowercase',
    width: '100%',
    marginBottom: 16,
    marginLeft: 0,
  },
}));

function DialogClose({ onClose }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const idea = useSelector((state) => state.ideaCreate);
  const { dialogClose } = useSelector((state) => state.ideaCreateDialogs);
  const { t } = useTranslation();

  function createDraft() {
    dispatch(ideaCreateActions.createDraft(idea, t('ideia_salva_em_rascunhos')));
    dispatch(ideaCreateActions.clear());
    dispatch(ideaCreateDialogsActions.close());
  };
  function handleClose() {
    dispatch(ideaCreateActions.clear());
    dispatch(ideaCreateDialogsActions.close());
  };

  return (
    <Dialog open={dialogClose}>
      <DialogCustomTitle
        id="customized-dialog-title"
        onClose={onClose}
      ></DialogCustomTitle>
      <DialogContent>
        <div className={classes.containerContent}>
          <div className={classes.title}>{t('descartar_ideia')}</div>
          <div className={classes.content}>
            {t('sua_ideia_nao_foi_publicada')}.{' '}
            {t('tem_certeza_que_deseja_cancelar_o_envio')}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={createDraft}
          className={classes.button}
        >
          {t('salvar_em_rascunhos')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          className={classes.button}
          style={{ marginLeft: 0 }}
        >
          {t('cancelar_publicacao')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DialogClose };

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        padding: theme.spacing(3, 2, 2, 2),
        borderRadius: 8,
        border: '1px solid #F4F4F4',
        minWidth: '100%',
        maxHeight: 80,
        minHeight: 80,
        backgroundColor: '#F4F4F4',
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.ultraLight,
            '& $icon': {
                fill: theme.palette.primary.main
            },
            '& $title': {
                color: theme.palette.primary.main
            }
        }
    },
    icon: {
        paddingTop: 2,
        fontSize: 24,
        fill: '#969696',
    },
    content: {
        paddingLeft: theme.spacing(1)
    },
    title: {
        display: '-webkit-box',
        fontSize: 14.22,
        fontWeight: 500,
        textTransform: 'lowercase',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        color: '#434343',
    },
    subitle: {
        fontSize: 11.44,
        color: '#969696'
    }
}))
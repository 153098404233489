import * as randomcolor from 'randomcolor';

import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CustomLabel = ({ viewBox, value, text, type }) => {
  const theme = useTheme();
  const { cx, cy } = viewBox;
  const { t } = useTranslation();
  return (
    <>
      <text
        x={cx}
        y={cy - 8}
        textAnchor="middle"
        dominantBaseline="central"
        fontWeight="bold"
        fontSize={34}
        fill={theme.palette.neutrals.low.light}
      >
        {
          window.location.href.split('report')[1] === '/ideas-financial-return' ?
            `${t('dinheiro')} ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : value
        }
      </text>
      <text
        x={cx}
        y={cy + 20}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="14"
        fill={theme.palette.neutrals.low.light}
      >
        {
          window.location.href.split('report')[1] === '/ideas-financial-return' ?
            `` : ``
        }
        {
          window.location.href.split('report')[1] === '/colaborator' ?
            `colaboradores` : ``
        }
        {
          window.location.href.split('report')[1] === '/ideas-status' ?
            text : ``
        }
      </text>
    </>
  );
};

function PieChartImagine({ data, type }) {
  const theme = useTheme();
  const colors = [
    theme.palette.auxiliary.green.light,
    theme.palette.auxiliary.blue.light,
    theme.palette.auxiliary.red.light,
    theme.palette.auxiliary.yellow.light,
    theme.palette.auxiliary.orange.light,
    theme.palette.auxiliary.pink.light,
    theme.palette.auxiliary.cyan.light,
    theme.palette.auxiliary.indigo.light,
    theme.palette.auxiliary.blue.main,
    theme.palette.primary.medium,
    theme.palette.auxiliary.green.main,
    theme.palette.auxiliary.blue.main,
    theme.palette.auxiliary.red.main,
    theme.palette.auxiliary.yellow.main,
    theme.palette.auxiliary.orange.main,
    theme.palette.auxiliary.pink.main,
    theme.palette.auxiliary.cyan.main,
    theme.palette.auxiliary.indigo.main,
  ];

  const sum = data.reduce((acc, cur) => {
    return acc + cur.count;
  }, 0);

  return (
    <ResponsiveContainer height={400} width="100%">
      <PieChart width="100%" height={400} style={{ margin: '0 auto' }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0.8} />
            <stop offset="95%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Pie
          cx="50%"
          cy="40%"
          data={data}
          dataKey="count"
          legendType="circle"
          innerRadius={100}
          outerRadius={120}
          paddingAngle={3}
          cornerRadius={20}
        >
          <Label
            width={30}
            position="center"
            content={<CustomLabel value={sum} text="ideas" type={type} />}
          ></Label>

          <>
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index] || randomcolor()}
              />
            ))}
          </>
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}

export { PieChartImagine };

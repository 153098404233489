import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { BusinessCenter } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { DialogCreateProject } from "../Portfolio";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { portfolioDialogProjectActions } from "../../actions/portfolio/portfolio-dialog-project.actions";

const useStyles  = makeStyles(theme => ({

  projectCard: {
    height: 250,
    backgroundColor: theme.palette.primary.lightest,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginBottom: 24,
    padding: 32
  },

  cardIcon: {
    borderRadius: '6rem',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 18
  },

  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32
  },

}));

function CardIdeaToProject() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ideaDetails } = useSelector((state) => state.idea);
  const [openDialogCreateProject, setOpenDialogCreateProject] = useState(false);
  const dispatch = useDispatch();

  function handleOpenDialog() {
    setOpenDialogCreateProject(!openDialogCreateProject);
    dispatch(portfolioDialogProjectActions.changeField('idea', { label: ideaDetails.name, id: ideaDetails._id }));
  }

  return(
    <Box className={classes.projectCard}>
            <DialogCreateProject executionFlow={true} open={openDialogCreateProject} onClose={handleOpenDialog} title={ideaDetails.name} />
            <Box className={classes.cardTitle}>
              <Box className={classes.cardIcon}>
                <BusinessCenter />
              </Box>
              <Typography style={{ fontWeight: 600, fontSize: 20 }}>
                {t('deseja_transformar_a_ideia_em_projeto')}
              </Typography>
            </Box>
            <Typography style={{ fontSize: 16, fontWeight: 400, lineHeight: '160%', marginBottom: 24 }}>{t('os_projetos_no_imagine_permitem_a_gestao_de_equipes_tarefas_e_planejamento_financeiro_da_execucao_das_ideias')}</Typography>
            <Box display='flex' justifyContent='flex-end' width='100%'>
              <Button variant='outlined' color='primary' style={{ marginRight: 24 }}>{t('continuar_execucao')}</Button>
              <Button variant='contained' color='primary' onClick={handleOpenDialog}>{t('criar_projeto')}</Button>
            </Box>
          </Box>
);
};

export { CardIdeaToProject };
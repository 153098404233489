import React from 'react';

import {
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  dialogContent: {
    padding: theme.spacing(3, 4),
  },
  dialogActions: {
    padding: "0.5rem 1rem",
  },
}));

const DialogStyled = withStyles({
   paperWidthSm: {
    maxWidth: 700
   }
})(Dialog);

export function DialogPortifolio({ title, open, onClose, content, action, maxWidth }) {
  const classes = useStyles();

  return (
    <DialogStyled open={open} onClose={onClose} className={classes.dialog} maxWidth={maxWidth}>
      <Box className={classes.dialogTitle}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          {content}
        </Grid>
      </DialogContent>
          
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {action}
          </Grid>
        </Grid>
      </DialogActions>
    </DialogStyled>
  );
}
import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { history, formatBRLFromFloat } from '../../helpers';
import { alertActions } from './../alert.actions';

export const portfolioDetailsActions = {
  getProject,
  getTeam,
  getOneFinancialPlanning,
  getAllTask,
  getAllResponsibles,
  changeField,
  changeStatus
}

function getProject(id) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_ONE_PROJECT_REQUEST });
    portfolioService.getProject(id).then(({ data }) => {
      dispatch({ 
        type: portfolioConstants.GET_ONE_PROJECT_SUCCESS, 
        payload: data[0] 
      });

    }).catch(error => {
      dispatch({ type: portfolioConstants.GET_ONE_PROJECT_FAILURE });
      console.error(error);
    });
  };
};

function getTeam(id) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_PORTFOLIO_TEAMS_REQUEST });
    portfolioService.getTeam(id).then(({ data }) => {
      dispatch({ 
        type: portfolioConstants.GET_PORTFOLIO_TEAMS_SUCCESS, 
        payload: data 
      });
    }).catch(error => {
      dispatch({ type: portfolioConstants.GET_PORTFOLIO_TEAMS_FAILURE });
      console.error(error);
    });
  };
};

function getOneFinancialPlanning(id) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_FINANCIAL_PLANNING_REQUEST });
    portfolioService.getOneFinancialPlanning(id).then(({ data }) => {
      let formattedCurrencyData = {
        costs: [],
        estimatedFinancialReturn: ""
      }

      if (data) {
        formattedCurrencyData = {
          ...data,
          costs: data.costs.map(item => ({
            ...item,
            estimatedValue: formatBRLFromFloat(item.estimatedValue)
          })),
          estimatedFinancialReturn: formatBRLFromFloat(data.estimatedFinancialReturn),
        }
      }

      dispatch({ type: portfolioConstants.GET_FINANCIAL_PLANNING_SUCCESS, payload: formattedCurrencyData });
    }).catch(error => {
      dispatch({ type: portfolioConstants.GET_FINANCIAL_PLANNING_FAILURE });
      console.log(error);
    });
  };
};

function getAllTask(data, taskStatus) {
  return dispatch => {
    dispatch({ type: portfolioConstants.GET_ALL_TASKS_REQUEST});

    portfolioService.getAllTask(data, taskStatus)
      .then(data => {
        dispatch({ 
          type: portfolioConstants.GET_ALL_TASKS_SUCCESS, 
          payload: data 
        });
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: portfolioConstants.GET_ALL_TASKS_FAILURE});
      })}
};


function getAllResponsibles() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_REQUEST,
    });
    portfolioService.getAllUsers()
      .then((response) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_SUCCESS,
          payload: response.data.data
        })
      })
      .catch((error) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_FAILURE,
        })
      })
  }
}

function changeField(name, value) {
  return dispatch => { 
    dispatch({
      type: portfolioConstants.CHANGE_PORTFOLIO_FIELD,
      payload: { name, value }
    })
  }
}

function changeStatus(taskType, data, id, getTasksBody, projectTasksStatus) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_REQUEST,
    })

    portfolioService.changeStatus(taskType, data, id)
      .then(response => {
        dispatch({
          type: portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_SUCCESS,
        })
        dispatch(getAllTask(getTasksBody, projectTasksStatus))
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_FAILURE,
        })
      })
  }
}
import React, { useEffect, useState, Fragment } from 'react';
import { 
  Grid,
  Button, 
  Box, 
  CircularProgress, 
} from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
} from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ideaConclusionActions, executionActions } from '../../actions';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../Shared';
import { DialogExecutionFlow } from '../Shared';

function IdeaConclusion() {
  const { ideaDetails } = useSelector((state) => state.idea);
  const { 
    startDate,
    conclusionDate,
    financialReturn,
    conclusionDetails,
    costs
  } = useSelector(state => state.ideaConclusion);
  const { loading } = useSelector(state => state.execution);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogIsOpen, setDialogConclusion] = useState(false);
  const { t } = useTranslation();
  const regexPattern = new RegExp(',', 'g');
  const regexPointPattern = new RegExp('[.]', 'g');
  const [loadingButton, setLoading] = useState(false);
  useEffect(() => {
    if (ideaDetails._id) {
      dispatch(executionActions.getIdea(id));
    }
  }, [dispatch, id, ideaDetails._id]);

  useEffect(() => {
    return () => {
      dispatch(executionActions.clear());
      dispatch(ideaConclusionActions.clear());
    };
  }, []);

  function handleLoadingButton() {
    setLoading(!loadingButton);
  }
  function sendConclusion() {
    dispatch(ideaConclusionActions.sendConclusionIdea(id, {
      startDate,
      conclusionDate,
      financialReturn: Number(financialReturn.replace(regexPointPattern, '').replace(regexPattern, '.')),
      conclusionDetails,
      costs: costs.map(item => {
        let formattedEstimatedCost = item.estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');
        let formattedCost = item.cost.replace(regexPointPattern, '').replace(regexPattern, '.');
        return {
          ...item,
          estimatedCost: Number(formattedEstimatedCost),
          cost: Number(formattedCost)
        }
      }),
    },t('a_ideia_foi_concluida_com_sucesso')));
  }

  function openModelJustification() {
    setOpenDialog(true);
  }

  function onCloseDialogJustification() {
    setOpenDialog(false);
  }

  function closeDialogConclusion() {
    setDialogConclusion(false);
  }

  function openDialogConclusion() {
    setDialogConclusion(true);
  }

  return (
    <Fragment>
     {
        loading ? <Box display='flex' justifyContent='center' alignItems='center' height='80vh' width='100%'><CircularProgress /></Box> :  <><DialogExecutionFlow
        open={dialogIsOpen}
        onClose={closeDialogConclusion}
        title={t('concluir_execucao')}
        subtitle={t('deseja_concluir_a_execucao_dessa_ideia')}
        description={t('as_informacoes_dos_resultados_da_execucao_nao_poderao_ser_editadas_posteriormente')}
        >
        {loadingButton ?
          <LoadingButton />
        :
        <Box>
          <Button variant="outlined" color="primary" onClick={closeDialogConclusion}>
            {t('voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 23 }}
            onClick={() => { handleLoadingButton();sendConclusion(); }}
          >
            {t('concluir_execucao')}
          </Button>
        </Box>
        }
      </DialogExecutionFlow>
      <DialogJustification
        open={openDialog}
        onClose={onCloseDialogJustification}
        id={id}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DeadlineCollapse isConclusion />
        </Grid>
        <Grid item xs={12}> 
          <CostStructureCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' width='100%'>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CancelPresentationIcon />}
              onClick={openModelJustification}
              style={{ marginRight: 10 }}
            >
              {t('execucao_nao_concluida')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AssignmentTurnedInIcon />}
              onClick={openDialogConclusion}
            >
              {t('concluir_execucao')}
            </Button>
          </Box>
        </Grid>
      </Grid></>
      }
    </Fragment>
  );
}

export { IdeaConclusion };

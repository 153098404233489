import { reportConstants } from './../constants'
import { reportService } from "./../services"

export const reportActions = {
  loadLocal,
  changeLocal,
  changeStatus,
  changeProperties,
  changeArea,
  loadStatus,
  loadProperties,
  getReportData,
  getReportDataChart,
  changeStartDate,
  changeEndDate,
  changeBulEmptyChecked,
  clearFilters,
  clearArea,
  clearLocal,
  clearStatus,
  getReportCsv,
  clearAll,
  changeTab,
  preFilter,
  getByQuery,
}

function getReportCsv(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.GET_REPORT_CSV_REQUEST,
    })

    reportService.getReportCsv(data)
      .then(response => {
        dispatch({
          type: reportConstants.GET_REPORT_CSV_SUCCESS,
          payload: response.data.data.csv
        })
      })
      .catch((err) => {
        dispatch({
          type: reportConstants.GET_REPORT_CSV_FAILURE,
        })
      })
  }
}

function loadLocal(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.LOAD_LOCAL,
      payload: data
    })
  }
}

function loadStatus(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.LOAD_STATUS,
      payload: data
    })
  }
}

function loadProperties(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.LOAD_PROPERTIES,
      payload: data
    })
  }
}

function changeArea(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_AREA,
      payload: data
    })
  }
}

function changeStatus(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_STATUS,
      payload: data
    })
  }
}

function changeProperties(index, checked) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_PROPERTIES,
      payload: {
        index, 
        checked
      }
    })
  }
}

function changeLocal(selectedLocal, imagineLocals) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_LOCAL,
      payload: { 
        selectedLocal,
        imagineLocals
      }
    })
  }
}

function changeStartDate(date) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_START_DATE,
      payload: date
    })
  }
}

function changeEndDate(date) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_END_DATE,
      payload: date
    })
  }
}

function changeBulEmptyChecked(checked) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CHANGE_BUL_EMPTY_CHECKED,
      payload: checked
    })
  }
}
function getReportData(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.REPORT_DATA_REQUEST,
    })

    reportService.getReportData(data)
      .then((response) => {
        dispatch({
          type: reportConstants.REPORT_DATA_SUCCESS,
          payload: {
            report: response.data.data.report,
            paging: response.data.paging,
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: reportConstants.REPORT_DATA_FAILURE,
        })    
      })
  }
}

function getReportDataChart(data) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.REPORT_DATA_REQUEST,
    })

    reportService.getReportDataChart(data)
      .then((response) => {
        dispatch({
          type: reportConstants.REPORT_DATA_CHART_SUCCESS,
          payload: response.data.data
        })
      })
      .catch((err) => {
        dispatch({
          type: reportConstants.REPORT_DATA_FAILURE,
        })    
      })
  }
}

function clearFilters() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CLEAR_FILTERS,
    })
  }
}

function clearArea() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CLEAR_AREA
    })
  }
}

function clearLocal() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CLEAR_LOCAL
    })
  }
}

function clearStatus() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CLEAR_STATUS
    })
  }
}

function preFilter() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.PRE_REPORT_DATA_REQUEST
    })
  }
}

function clearAll() {
  return (dispatch) => {
    dispatch({
      type: reportConstants.CLEAR_ALL_REPORT
    })
  }
}

function changeTab(tab) {
  return (dispatch) => {
    dispatch({
      type: reportConstants.SER_REPORT_TAB,
      payload: tab
    })
  }
}

function getByQuery(query) {
  return (dispatch) => {
    dispatch({ type: reportConstants.REPORT_DATA_REQUEST });
    reportService.getByQuery(query)
      .then(data => {
        dispatch({
          type: reportConstants.REPORT_DATA_SUCCESS,
          payload: {
            paging: data.paging,
          }
        })
      })
      .catch((err) => {
        dispatch({ type: reportConstants.REPORT_DATA_FAILURE });  
      })
  }
}
import { ideaUpdateConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  _id: '',
  name: '',
  description: '',
  nameLowerCase: '',
  links: [],
  statusIdea: {},
  attachments: [],
  user: {},
  images: [],
  files: [],
  video: {},
  saveLoading: false,
  canvas: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  canvasValueProposition: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  businessUnitLocal: [],
  filesToRemove: [],
  challengesList: [],
  ideaFailed: false,
};

export function ideaUpdate(state = initialState, action) {
  switch (action.type) {
    case ideaUpdateConstants.UPDATE_IDEA_SUCCESS: {
      return {
        ...state,
        saveLoading: false
      }
    }
    case ideaUpdateConstants.UPDATE_IDEA_FAILURE: {
      return {
        ...state,
        saveLoading: false
      }
    }
    case ideaUpdateConstants.UPDATE_IDEA_REQUEST: {
      return {
        ...state,
        saveLoading: true
      }
    }
    case ideaUpdateConstants.DELETE_DRAFT_REQUEST: {
      return {
        ...state,
        deleteLoading: true
      }
    }
    case ideaUpdateConstants.SET_PUT_SELECTED_BUSINESS: {
      return update(state, {
        businessUnitLocal: { $set: action.payload }
      });
    }

    case ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS: {
      const contributors = action.payload.contributors.map(({_id}) => _id);

      return update(state, {
        _id: { $set: action.payload._id },
        name: { $set: action.payload.name },
        description: { $set: action.payload.description },
        nameLowerCase: { $set: action.payload.nameLowerCase },
        links: { $set: action.payload.links },
        statusIdea: { $set: action.payload.statusIdea },
        user: { $set: action.payload.user },
        attachments: { $set: action.payload.attachments },
        canvas: { $set: action.payload.canvas },
        canvasValueProposition: { $set: action.payload.canvasValueProposition },
        createdAt: { $set: action.payload.createdAt },
        contributors: { $set: contributors},
        challenge: { $set: action.payload.challenge },
        businessUnitLocal: { $set: action.payload.businessUnitLocal },
        images: [],
        files: [],
        video: {},
      });
    };
    case ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE: {
      return {
        ...state,
        ideaFailed: true,
      }
    }

    case ideaUpdateConstants.PUT_TITLE_DESCRIPTION: {
      return update(state, {
          name: { $set: action.payload.name },
          description: { $set: action.payload.description },
          nameLowerCase: { $set: action.payload.name },
      });
    };

    case ideaUpdateConstants.PUT_CANVAS: {
      const canvas = state.canvas.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvas[c.key],
          _id: c._id
        };
      });

      return update(state, { canvas: { $set: canvas } });
    };

    case ideaUpdateConstants.PUT_CANVAS_VALUE: {
      const canvasValueProposition = state.canvasValueProposition.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvas[c.key],
          _id: c._id
        };
      });

      return update(state, { canvasValueProposition: { $set: canvasValueProposition } });
    };

    case ideaUpdateConstants.PUT_LINKS: {
      return update(state, { links: { $set: action.links } });
    };

   case ideaUpdateConstants.PUT_SET_IMAGES: {
      return update(state, {
        images: { $push: action.payload.images },
      });
    };

    case ideaUpdateConstants.PUT_SET_VIDEO: {
      return update(state, {
        video: { $set: action.payload.video },
      });
    };
    case ideaUpdateConstants.PUT_SET_FILE: {
      return update(state, {
        files: { $push: action.payload.file },
      });
    };
    case ideaUpdateConstants.CLEAR_UPTADE_IDEA_DETAILS: {
      return update(state, { $set: initialState });
    };

    case ideaUpdateConstants.REMOVE_IMAGE: {
       const images = state.images.filter(
        (image) => image.id !== action.payload.id
      );

      return update(state, {
        images: { $set: images },
        filesToRemove: { $push: [action.payload.id] }
      });
    };
        case ideaUpdateConstants.REMOVE_FILE: {
       const files = state.files.filter(
        (file) => file.id !== action.payload.id
      );
      return update(state, {
        files: { $set: files },
        filesToRemove: { $push: [action.payload.id] }
      });
    };

    case ideaUpdateConstants.PUT_CONTRIBUTORS: {
      const contributors = action.payload.map(({id}) => id);
      return update(state, {
        contributors: { $set: contributors }
      });
    };

    case ideaUpdateConstants.REMOVE_VIDEO: {
      return update(state, {
        video: { $set: {} },
        filesToRemove: { $push: [action.payload.id] }
      });
    };
    case ideaUpdateConstants.SET_EDIT_IDEA_CHALLENGES: {
      return {
        ...state,
        challengesList: action.payload
      }
    }
    case ideaUpdateConstants.SELECT_EDIT_IDEIA_CHALLENGE: {
      return { 
        ...state,
        challengesList: state.challengesList.map(item => {
          if (action.payload) {
            if (item.value === action.payload) {
              return {
                ...item,
                checked: !item.checked
              }
            }
            return item;
          } else {
            return {
              ...item,
              checked: false
            }
          }
        })
      }
    }
    case ideaUpdateConstants.CHANGE_IDEIA_EDIT_FIELD: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }
    case ideaUpdateConstants.ADD_IDEIA_EDIT_LINK: {
      const auxState = state;
      auxState.links.push("");
      return {
        ...auxState
      };
    }
    case ideaUpdateConstants.REMOVE_IDEIA_EDIT_LINK: {
      return {
        ...state,
        links: state.links.filter((item, index) => index !== action.payload)
      }
    }
    case ideaUpdateConstants.CHANGE_IDEA_EDIT_LINK: {
      const auxState = state;
      auxState.links[action.payload.index] = action.payload.value
      return {
        ...auxState
      }
    }
    default:
      return state;
  };
};
export const infraStructureConstants = {
	CHANGE_INFRASTRUCTURE_FIELD_NAME: 'CHANGE_INFRASTRUCTURE_FIELD_NAME',
	CLEAR_INFRASTRUCTURE_DIALOG: 'CLEAR_INFRASTRUCTURE_DIALOG',
	LOAD_INFRASTRUCTURE_DIALOG: 'LOAD_INFRASTRUCTURE_DIALOG',
	LOAD_INFRASTRUCTURE_ASSOCIATIONS: 'LOAD_INFRASTRUCTURE_ASSOCIATIONS',

	CREATE_INFRASTRUCTURE_REQUEST: 'CREATE_INFRASTRUCTURE_REQUEST',
	CREATE_INFRASTRUCTURE_SUCCESS: 'CREATE_INFRASTRUCTURE_SUCCESS',
	CREATE_INFRASTRUCTURE_FAILURE: 'CREATE_INFRASTRUCTURE_FAILURE',

	UPDATE_INFRASTRUCTURE_REQUEST: 'UPDATE_INFRASTRUCTURE_REQUEST',
	UPDATE_INFRASTRUCTURE_SUCCESS: 'UPDATE_INFRASTRUCTURE_SUCCESS',
	UPDATE_INFRASTRUCTURE_FAILURE: 'UPDATE_INFRASTRUCTURE_FAILURE',

	DELETE_INFRASTRUCTURE_REQUEST: 'DELETE_INFRASTRUCTURE_REQUEST',
	DELETE_INFRASTRUCTURE_SUCCESS: 'DELETE_INFRASTRUCTURE_SUCCESS',
	DELETE_INFRASTRUCTURE_FAILURE: 'DELETE_INFRASTRUCTURE_FAILURE',

	GET_INFRASTRUCTURE_REQUEST: 'GET_INFRASTRUCTURE_REQUEST',
	GET_INFRASTRUCTURE_SUCCESS: 'GET_INFRASTRUCTURE_SUCCESS',
	GET_INFRASTRUCTURE_FAILURE: 'GET_INFRASTRUCTURE_FAILURE',

	GET_INFRASTRUCTURE_ASSOCIATION_REQUEST: 'GET_INFRASTRUCTURE_ASSOCIATION_REQUEST',
	GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS: 'GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS',
	GET_INFRASTRUCTURE_ASSOCIATION_FAILURE: 'GET_INFRASTRUCTURE_ASSOCIATION_FAILURE',

	POST_INFRASTRUCTURE_ASSOCIATION_REQUEST: 'POST_INFRASTRUCTURE_ASSOCIATION_REQUEST',
	POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS: 'POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS',
	POST_INFRASTRUCTURE_ASSOCIATION_FAILURE: 'POST_INFRASTRUCTURE_ASSOCIATION_FAILURE',

	CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION: 'CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION',
	ADD_NEW_INFRASTRUCTURE_ASSOCIATION: 'ADD_NEW_INFRASTRUCTURE_ASSOCIATION',
	REMOVE_INFRASTRUCTURE_ASSOCIATION: 'REMOVE_INFRASTRUCTURE_ASSOCIATION',
	CLEAR_INFRASTRUCTURE_ASSOCIATIONS: 'CLEAR_INFRASTRUCTURE_ASSOCIATIONS',

	SET_INFRASTRUCTURE_FILTERS: 'SET_INFRASTRUCTURE_FILTERS',
	CLEAR_INFRASTRUCTURE_FILTER: 'CLEAR_INFRASTRUCTURE_FILTER',
	SET_PAGING: 'SET_PAGING',
};
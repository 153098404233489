import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  makeStyles,
} from '@material-ui/core';

import { LineChartImagine } from '../LineChartImagine';
import { LineChartMock } from '../LineChartImagine/mock';
import { SkeletonLineChart } from '../Skeletons';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.neutrals.low.light,
  },
}));

export function MonthlyEvolutionDashboard({ ideasMonth, loadingIdeasMonth }) {
  const classes = useStyles();
  const { t } = useTranslation();
  let _ideasMonth = ideasMonth.data?ideasMonth.data:LineChartMock;

  return (
    <Card elevation={0} className={classes.card}>
      {loadingIdeasMonth ? (
        <SkeletonLineChart />
      ) : (
        <>
          <div className={classes.title}>{t('evolucao_mensal')}</div>
          <LineChartImagine data={_ideasMonth} />
        </>
      )}
    </Card>
  );
} 

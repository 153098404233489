import React, { Fragment } from 'react'
import {
  makeStyles,
  Box,
  Card, 
  Grid,
  CardActions
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  cardActions: {
    padding: theme.spacing(2, 2, 2, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  iconCardAction: {
    marginRight: "0.5rem"
  }
}))

export const SkeletonCardsHome = () => {
  const classes = useStyle();

  return (
    <Fragment>
      <Grid container spacing={4}>
        { [1,2,3,4,5].map((item) => (
          <Grid item xs={12}>
            <Card>
              <Box display="flex" alignItems="center" padding="1rem">
                <Skeleton width="56px" height="56px" variant="circle" animation="wave" />
                <Box display="flex" alignItems="center" justifyContent="space-between" width="calc(100% - 56px)" marginLeft="0.75rem">
                  <Box display="flex" flexDirection="column">
                    <Skeleton width="40%" variant="text" animation="wave" />
                    <Skeleton width="30%" variant="text" animation="wave" />
                    <Skeleton width="20%" variant="text" animation="wave" />
                  </Box>
                    <Skeleton width="1.5rem" height="1.5rem" variant="circle" animation="wave" />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" padding="1rem">
                <Skeleton width="30%" variant="text" animation="wave" />
                <Skeleton width="100%" variant="text" animation="wave" />
                <Skeleton width="100%" variant="text" animation="wave"  />
                <Skeleton width="100%" variant="text" animation="wave"  />
                <Skeleton width="50%" variant="text" animation="wave"  />
              </Box>
              <CardActions className={classes.cardActions}>
                <Grid container spacing={2}>
                  <Grid item xs={3} display="flex">
                    <Box display="flex" alignItems="center" width="100%">
                      <Skeleton width="1.5rem" height="1.5rem" variant="circle" animation="wave" className={classes.iconCardAction} />
                      <Skeleton width="40%" variant="text" animation="wave" />
                    </Box>
                  </Grid>
                  <Grid item xs={3} display="flex">
                    <Box display="flex" alignItems="center" width="100%">
                      <Skeleton width="1.5rem" height="1.5rem" variant="circle" animation="wave" className={classes.iconCardAction} />
                      <Skeleton width="calc(80% - 1.5rem)" variant="text" animation="wave" />
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Fragment>
    
  )
}

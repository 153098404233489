import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const ideaConclusionService = {
  sendConclusionIdea,
  cancelConclusionIdea,
};


function sendConclusionIdea(id, conclusion) {
  return axios
    .put(
      `${config.URL_BASE}/ideas/${id}/finishexecution`,
      conclusion,
      requestHeaders()
    )
    .then(handleResponse);
}

function cancelConclusionIdea(id, cancel) {
  return axios
    .put(
      `${config.URL_BASE}/ideas/${id}/cancelexecution`,
      cancel,
      requestHeaders()
    )
    .then(handleResponse);
}

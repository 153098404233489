import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import GroupIcon from '@material-ui/icons/Group';
import { MentionsUsers, MultiAreaCascade } from '../Shared';
import { useDispatch, useSelector } from 'react-redux';
import { ideaUpdateActions } from "./../../actions"

export const IdeaEditGeneralParticipants = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleMentions(mentioned) {
    dispatch(ideaUpdateActions.setContributors(mentioned));
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <GroupIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('participantes')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MentionsUsers onAddMentions={handleMentions} isEdition />
        </Grid>
      </Grid>
    </Grid>
  )
}

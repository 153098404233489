import React, { useEffect, useState, Fragment } from 'react';
import { 
  Grid,
  Button, 
  Box, 
  Typography, 
  makeStyles 
} from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
  CardIdeaToProject
} from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { executionActions } from '../../actions';
import ForwardIcon from '@material-ui/icons/Forward';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../Shared';
import { DialogExecutionFlow } from '../Shared';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(2, 2, 1),
    color: theme.palette.neutrals.low.dark
  }
}));
function IdeaExecution() {
  const classes = useStyles();
  const {
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    estimatedFinancialReturn,
    costsFields,
  } = useSelector((state) => state.execution);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openDialogJustification, setOpenDialogJustification] = useState(false);

  useEffect(() => {
    dispatch(executionActions.loadArrayCosts())
  }, [dispatch])

  useEffect(() => {
    return function cleanup() {
      dispatch(executionActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openModelJustification() {
    setOpenDialogJustification(true);
  }
  function onCloseDialogJustification() {
    setOpenDialogJustification(false);
  }
  function handleLoadingButton() {
    setLoading(!loading);
  }
  function sendExecution() {
    const regexPattern = new RegExp(',', 'g');
    const regexPointPattern = new RegExp('[.]', 'g');

    dispatch(
      executionActions.executeIdea({
        estimatedStartDate,
        estimatedConclusionDate,
        executionDetails,
        estimatedFinancialReturn: estimatedFinancialReturn.replace(regexPointPattern, '').replace(regexPattern, '.'),
        costs: costsFields.map(item => {
          let estimatedCost = item.estimatedCost;
          estimatedCost = estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');

          return {
            ...item,
            estimatedCost: estimatedCost
          }
        })
      }, id, t('ideia_em_execucao'))
    );
  }

  function openDialog() {
    setDialogIsOpen(true);
  }

  function closeDialog() {
    setDialogIsOpen(false);
  }

  return (
    <Fragment>
      <DialogExecutionFlow
        open={dialogIsOpen}
        onClose={closeDialog}
        title={t('executar_ideia')}
        subtitle={t('deseja_executar_essa_ideia')}
        description={t('as_informacoes_do_planejamento_da_execu��o_nao_poderao_ser_editadas_posteriormente')}
      >
        {loading ?
          <LoadingButton />
        :
        <Box>
            <Button variant="outlined" color="primary" onClick={closeDialog}>
              {t('voltar')}
            </Button>
          <Button
            variant="contained"
            color="primary"
              style={{ marginLeft: 23 }}
              onClick={() => { handleLoadingButton(); sendExecution(); }}
          >
              {t('executar')}
          </Button>
        </Box>
        }
      </DialogExecutionFlow>
      <Grid container spacing={4}>
        <Typography component="h1" className={classes.title}>
          {t('execucao')}
        </Typography>
        <Grid item xs={12}>
        { config.SHOW_PORTFOLIO_IDEA_EXECUTION && <CardIdeaToProject /> }
        </Grid>
        <Grid item xs={12}>
          <DeadlineCollapse />
        </Grid>
        <Grid item xs={12}> 
          <CostStructureCollapse />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' alignItems='center'>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CancelPresentationIcon />}
              onClick={openModelJustification}
              style={{ marginRight: 10 }}
            >
              {t('execucao_nao_concluida')}
            </Button>
            <DialogJustification
              open={openDialogJustification}
              onClose={onCloseDialogJustification}
              id={id}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<ForwardIcon />}
              onClick={openDialog}
              disabled={(
                executionDetails === "" ||
                estimatedStartDate === null ||
                estimatedConclusionDate === null
              )}
            >
              {t('executar_ideia')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export { IdeaExecution };

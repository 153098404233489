import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import i18next from 'i18next';
import { languageActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const languageMap = {
   es: { label: 'espanhol', dir: 'ltr', active: false },
   en: { label: 'inglês', dir: 'ltr', active: false },
  pt_BR: { label: 'português', dir: 'ltr', active: true },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem('i18nextLng') || 'pt_BR';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const minLanguage = 1;
  const languageCount = Object.keys(languageMap).length;
  const shouldRender = languageCount <= minLanguage ? false : true;

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [language, setLanguage] = React.useState(selected);

  React.useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [menuAnchor, selected]);

  React.useEffect(() => {
    dispatch(languageActions.changeLanguage(language));
  }, [language, dispatch]);

  return (
  shouldRender ?
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {t(languageMap[selected]?.label)}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <List>
            <ListSubheader>{t('select_language')}</ListSubheader>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                  setLanguage(item);
                }}
              >
                {t(languageMap[item].label)}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div> : <></>
  );
};

export default LanguageSelect;

import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../Shared';
import { useStyles } from './attachments';
import {ReactComponent as IconBusinessCanvas} from '../../assets/imgs/icon-businessmodelcanvas.svg';
import {ReactComponent as IconPropositionCanvas} from '../../assets/imgs/icon-valuepropositioncanvas.svg';
import { CanvasRead, ValueCanvasRead } from '../CreateIdeaDialogs';


function CanvasAttachment({ canvas, type }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const title = type === 'canvas' ? 'business model canvas' : 'value proposition canvas';
    const Icon = type === 'canvas' ? IconBusinessCanvas : IconPropositionCanvas;

    function handleOpenDialog() {
        setOpenDialog(!openDialog)
    };

    return (
        <div className={classes.card} onClick={handleOpenDialog}>
           {type === 'canvas' ? <CanvasRead idea={{canvas}} isOpen={openDialog} onClose={handleOpenDialog} /> :
            <ValueCanvasRead idea={{canvasValueProposition: canvas}} isOpen={openDialog} onClose={handleOpenDialog} />}
            <section className={classes.content} style={{display: 'flex', alignItems: 'center'}}>
                   <Icon className={classes.icon} style={{marginRight: '5px'}} /> 
                <CustomTooltip
                    title={t('visualizar_canvas')}
                    aria-label='canvas'
                >
                    <Typography className={classes.title}>{title}</Typography>
                </CustomTooltip>
            </section>
        </div>
    );
};

export { CanvasAttachment };
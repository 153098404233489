import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Button,
  Typography,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, createFilterOptions  } from "@material-ui/lab";
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';

import { useStyles } from './styles';
import { formatDate } from '../../utils/date';
import { BusinessUnitPropsCheckboxList } from "./../../components/Shared";
import { reportActions, businessUnitPropsAction } from "./../../actions";

const filterOptions = createFilterOptions({
  limit: 100,
});

export const ReportFilters = ({ closeFilter }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [splittedPathname, checked] = useState(history.location.pathname.split("/"), true);
  const { filters, paging, fieldValidation, loading, tab } = useSelector(state => state.report);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps);
  
  function handleChangeStatus(data) {
    if (data) {
      dispatch(reportActions.changeStatus(data.option))
    } else {
      dispatch(reportActions.clearStatus())
    }
  }

  const handleStartDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      dispatch(reportActions.changeStartDate(date))
    }
  };

  const handleEndDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      dispatch(reportActions.changeEndDate(date))
    }
  };

  const handleClearFilters = () => {
    dispatch(reportActions.clearFilters())
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('occupationArea'))
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('local'))
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('businessUnit'))
    dispatch(reportActions.changeBulEmptyChecked(false))
  }

  function handleFilter() {
    let params = {
      statusCode: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && filters.status.filter(item => item.checked).map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && formatDate(filters.startDate),
      endDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.endDate && formatDate(filters.endDate),
      bulWithoutUsers: filters.bulWithoutUsers,
      reportType: splittedPathname[splittedPathname.length - 1],
    }

    dispatch(reportActions.preFilter())

    if (tab === "table") {
      params = {
        ...params,
        page: 1,
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(params))

    } else {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(params))
    }
    closeFilter();
  }
  function handleChangeCheckbox (event) {
      dispatch(reportActions.changeBulEmptyChecked(event.target.checked));
  };

  return (
    <Box padding="1rem">
      <Grid container>
        <Grid item xs={12}>
          {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && (
            <Box>
              <Typography className={classes.titleInputs}>{t('status')}</Typography>        
              <Autocomplete 
                multiple
                disableCloseOnSelect
                className={classes.autoCompleteStatus}
                filterOptions={filterOptions}
                id="checkboxes-status"
                options={filters.status}
                loading={filters.status.length === 0}
                value={filters.status.filter(item => item.checked)}
                onChange={ (e, items, options, details) => handleChangeStatus(details) }
                getOptionLabel={(input) => input.label}
                renderInput={(params) => (
                  <TextField {...params} label={t('status')} variant="filled" />
                )}
                renderTags={(option, state) => {
                  return (<span>{option.length > 1 ? `${option[0].label} +${option.length-1}` : option[0].label}</span>)
                }}
                renderOption={(value) => {
                  return (
                    <li>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckboxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={value.checked}
                        />
                      {value.label}
                    </li>
                )}}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && (
            <Box>
              <Typography className={classes.titleInputs}>{t('data')}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className={classes.containerDate}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    disableFuture
                    value={filters.startDate}
                    fullWidth
                    id="date-picker-inline"
                    className={classes.datePicker}
                    label={t('inicio')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={
                      filters.startDate && <span>Data de inicio inválida</span>
                    }
                    name="startDate"
                    onChange={handleStartDate}
                    autoOk
                    maxDate={filters.endDate}
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data não pode ser futura ou maior que a data de termino
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.containerDate}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    value={filters.endDate}
                    className={classes.datePicker}
                    fullWidth
                    disableFuture
                    margin="normal"
                    id="date-picker-inline-endDate"
                    label={t('termino')}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        Data de termino invalida
                      </span>
                    }
                    name="endDate"
                    minDate={filters.startDate}
                    autoOk
                    minDateMessage={
                      <span className={classes.errorSpan}>
                        A data de termino não pode ser inferior a data de inicio
                      </span>
                    }
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data de termino não pode ser uma data futura.
                      </span>
                    }
                  />
                </Grid> 
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.titleInputs}>{t('infraestrutura')}</Typography>
            </Grid>
            <Grid item xs={12} md={fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status ? 12 : 12}>
              <BusinessUnitPropsCheckboxList businessUnitField = {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.businessUnit} />
            </Grid>
          </Grid> 
        </Grid>
        
        
        <Grid item xs={12}>
          <Box display="flex" alignItems="end" justifyContent="space-between" marginTop="2rem">
          <Box>
            {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.showCheckBoxEmptyBul && (<FormControlLabel
                label={t('exibir_areas_sem_gestores')}
                control={
                  <Checkbox
                    color="primary"
                    checked={checked[0] && checked[1]}
                    indeterminate={checked[0] !== checked[1]}
                    onChange={handleChangeCheckbox}
                  />
                }
                className={classes.alignCheckBoxBulEmpty}
              />
              )}
            </Box>
            <Box display="flex" alignItems="end" justifyContent="end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.filterButtons}
              onClick={handleClearFilters}
            >
              {t('limpar_filtro')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.filterButtons}
              disabled={loading}
              onClick={handleFilter}
              startIcon={<FilterListIcon />}
            >
              {t('filtrar')}
            </Button>
          </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

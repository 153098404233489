import React, { Fragment } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import filesize from 'filesize';
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import { CustomTooltip } from '../Shared/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: 8,
    border:`1px solid ${theme.palette.neutrals.high.light}`,
    minWidth: '100%',
    maxHeight: 80,
    backgroundColor: theme.palette.neutrals.high.light,
    textDecoration: 'none',
  },
  icon: {
    paddingTop: 2,
    fontSize: 24,
    color: theme.palette.neutrals.low.light
  },
  title: {
    display: '-webkit-box',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'lowercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    color: theme.palette.neutrals.low.dark,
  },
  subitle: {
    fontSize: 11,
    color: theme.palette.neutrals.low.light
  }
}))

export const AttachmentFiles = ({ attachments }) => {
  const classes = useStyles()
  const files = attachments.filter((file) => file.mimeType.container === 'dynamic-files');

  function getFileName(rawName, rawExt){
    if(rawName[rawName.length - 1] === '.'){
      return rawName.slice(0, -1).concat(rawExt)
    } 

    return rawName.concat(rawExt)
  }

  function getFileSize(rawSize) {
    const size = parseFloat(rawSize);
    return filesize(size)
  }

  return (
    <Fragment>
      { files.length > 0 && (
        files.map((file, index) => {
          return (
            <Grid item xs={12} sm={6} key={index}>
              <a className={classes.card} href={file.url} target="_blank"rel="noopener noreferrer">
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <FileIcon className={classes.icon}/>
                  </Grid>
                  <Grid item xs={9}>
                    <CustomTooltip title={getFileName(file.fileName, file.mimeType.extension)} aria-label={getFileName(file.fileName, file.mimeType.extension)}>
                      <Typography className={classes.title}>&nbsp;{getFileName(file.fileName, file.mimeType.extension)}</Typography>
                    </CustomTooltip>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.subitle}>{getFileSize(file.size)}</Typography>
                  </Grid>
                </Grid>
              </a>
            </Grid>
          );
        })
      )}
    </Fragment>
  )
}

import React, { Fragment, useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import MuiMenu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles,withStyles } from "@material-ui/core" 
import { useDispatch, useSelector } from "react-redux"
import { rankingActions } from "../../../actions"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.neutrals.high.light,
    borderRadius: "4px",
    padding: "1rem",
  },
  filterButtonIcon: {
    color: theme.palette.neutrals.low.dark,
  },
  filterButtonText: {
    padding: theme.spacing(0, 1),
    color: theme.palette.neutrals.low.dark,
  },
  filterButtonArrow: {
    color: theme.palette.neutrals.low.dark,
  },
  filterMenu: {
    padding: theme.spacing(3),
  },
  filterMenuHeader: {
    padding: theme.spacing(2, 3),
  },
  filterMenuHeaderTitle: {
    color: theme.palette.neutrals.low.light,
  },
  button: {
    display: 'flex',
    justifyContent: 'end'
  },
}));

const Menu = withStyles({
  list: {
    padding: 0,
    maxWidth: 328
  },
})(MuiMenu);

export const RankingFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { paging, loading } = useSelector((state) => state.ranking);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeStatus(event) {
    dispatch(rankingActions.setFilterPeriod(event.target.value));
  }

  function handleTypeRanking(event) {
    dispatch(rankingActions.setTypeRanking(event.target.value));
  }

  function handleFilter() {
    const newFilter = {
      period: paging.filter.period,
      userName: paging.filter.userName,
      column: paging.filter.column
    };
    dispatch(rankingActions.getRanking(newFilter, 1, paging.sort, paging.limit));
  }

  return (
    <Fragment>
      <ButtonBase className={classes.filterButton} onClick={handleClick}>
        <FilterListIcon className={classes.filterButtonIcon} />
        <Typography variant="subtitle2" className={classes.filterButtonText}>{t('filtro')}</Typography>
        <ArrowDropDownIcon className={classes.filterButtonArrow} />
      </ButtonBase>
      <Menu
        keepMounted
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="column">
          <Box className={classes.filterMenuHeader}>
            <Typography variant="body2" className={classes.filterMenuHeaderTitle}>{t('filtrar_por')}</Typography>
          </Box>
          <Divider />
          <Box className={classes.filterMenu}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="select-status-label" className={classes.label}>{t('periodo')}</InputLabel>
                  <Select
                    labelId="select-status-label"
                    id="select-status"
                    fullWidth
                    value={paging.filter.period}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={9000}>{t('ranking_geral')}</MenuItem>
                    <MenuItem value={30}>{t('ranking_mensal')}</MenuItem>
                    <MenuItem value={7}>{t('ranking_semanal')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="select-type-ranking" className={classes.label}>{t('tipo_de_ranking')}</InputLabel>
                  <Select
                    labelId="select-type-ranking"
                    id="select-type-ranking"
                    fullWidth
                    value={paging.filter.column}
                    onChange={handleTypeRanking}
                  >
                    <MenuItem value={'points'}>{t('ranking_por_pontos')}</MenuItem>
                    <MenuItem value={'ideas'}>{t('ranking_por_ideias_enviadas')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.button}>
                <Button 
                  variant="contained" 
                  color="primary"
                  startIcon={<FilterListIcon />}
                  onClick={handleFilter}
                  disabled={loading}
                >
                  {t('filtrar')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Menu>
    </Fragment>
  );
}